import React, { Fragment, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import situationsStore from "../../store/situationsStore";
import { useFetching } from "../../../../hooks/useFetching";
import SituationsService, { URL } from "../../../../api/SituationsService";
import { PrimaryButton, RefreshButton, ToggleButton } from "../../../../UI";
import { GENDERS } from "../../data/constants";
import MyAdjustableTextarea from "../../../../components/UI/MyAdjustableTextarea/MyAdjustableTextarea";
import MyModal from "../../../../components/UI/MyModal/MyModal";
import { EmptyAlert } from "../";
import { ReactComponent as IconSound } from "../../../../assets/img/icon-sound_alt.svg";
import { ReactComponent as IconArrow } from "../../../../assets/img/icon-arrow.svg";
import cl from "./Statements.module.css";

const PLACEHOLDERS_COUNT = 20;
const PLACEHOLDERS = Array(PLACEHOLDERS_COUNT).fill("");
const PLACEHOLDER_TEXT = "Lorem ipsum dolor sit amet";

const Statements = ({ isLoading, isEmpty }) => {
  const { t } = useTranslation();
  const buttonsRef = useRef();

  const { currentSituation, playbackRate } = situationsStore;
  const [updatedStatements, setUpdatedStatements] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const [active, setActive] = useState(null);
  const [text, setText] = useState("");
  const [audio] = useState(new Audio());
  const [respondentGender, setRespondentGender] = useState();

  const addNewStatement = (statement, newPhrase) => {
    const { id, phrase } = statement;
    if (phrase === newPhrase) {
      setActive(null);
      return;
    }
    if (updatedStatements.some((s) => s.id === id)) {
      setUpdatedStatements((prev) =>
        prev.map((s) => (s.id !== id ? s : { ...s, newPhrase }))
      );
    } else {
      setUpdatedStatements((prev) => [...prev, { ...statement, newPhrase }]);
    }
  };

  const [updateStatement, updateLoading] = useFetching(async (statement) => {
    const { id, phrase, newPhrase, forceUpdate } = statement;
    if (phrase === newPhrase && !forceUpdate) {
      return;
    }

    const res = await SituationsService.updateStatement({
      id,
      phrase: newPhrase,
      forceUpdate,
      voiceId: respondentGender ? getVoiceId() : null,
    });
    if (res.status === 200 && phrase !== newPhrase) {
      situationsStore.setCurrentSituation({
        ...currentSituation,
        statements: currentSituation.statements.map((s) =>
          s.id === statement.id ? { ...s, phrase: newPhrase } : s
        ),
      });
    }
  });

  const isActive = (statement) => statement.id === active?.id;

  const getVoiceId = () => {
    // these are default respondent voice ids for male and female genders
    if (respondentGender === GENDERS[0]) return "TxGEqnHWrfWFTfGW9XjX";
    if (respondentGender === GENDERS[1]) return "EXAVITQu4vr4xnSDxMaL";
  };

  const handleStatementClick = async (statement, event) => {
    event.stopPropagation();
    if (active) {
      addNewStatement(active, text);
    }
    setActive(statement);
  };

  const handleInputChange = (value) => {
    setText(value);
  };

  const handleAudioClick = () => {
    setTimeout(() => {
      audio.playbackRate = playbackRate / 100 + 1;
      audio.play();
    });
  };

  const handleForceRegenerate = async (statement) => {
    setUpdatedStatements((prev) => [
      ...prev.filter((s) => s.id !== statement.id),
      { ...statement, newPhrase: statement.phrase, forceUpdate: true },
    ]);
    setActive(null);
  };

  const getStatementText = (statement) => {
    const updated = updatedStatements.find((s) => s.id === statement.id);
    if (updated) return updated.newPhrase;
    return statement.phrase;
  };

  const handleStatementsUpdate = async () => {
    setIsUpdateLoading(true);
    for (const statement of updatedStatements) {
      await updateStatement(statement);
    }
    setIsUpdateLoading(false);
    setIsConfirm(false);
    setUpdatedStatements([]);
  };

  const handleStatementReset = (statement) => {
    setUpdatedStatements(
      updatedStatements.filter((s) => s.id !== statement.id)
    );
  };

  const handleStatementsReset = () => {
    setUpdatedStatements([]);
  };

  const getChangesCount = () => {
    if (!updatedStatements.length) return "";
    return ` (${updatedStatements.length})`;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonsRef.current && !buttonsRef.current.contains(event.target)) {
        addNewStatement(active, text);
        setActive(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [buttonsRef, active, text, updatedStatements]);

  useEffect(() => {
    if (active) {
      setText(getStatementText(active));
      audio.pause();
      audio.src = `${URL}${active.phraseVoice}`;
    } else {
      setText("");
      audio.src = "";
    }
  }, [active]);

  useEffect(() => {
    if (isConfirm && !updatedStatements.length) {
      setIsConfirm(false);
    }
    situationsStore.setBackBtnDisabled(updatedStatements.length !== 0);
  }, [updatedStatements]);

  useEffect(() => {
    setActive(null);
    setUpdatedStatements([]);
    setRespondentGender(null);
  }, [currentSituation]);

  useEffect(
    () => () => {
      audio.pause();
      audio.src = "";
    },
    []
  );

  if (isEmpty) return <EmptyAlert text={"situations.empty_situation"} />;

  return (
    <div className={cl.statementsCont}>
      {updatedStatements.length !== 0 && (
        <PrimaryButton
          variant={"green"}
          text={`${t("situations.show_changes_btn")}${getChangesCount()}`}
          style={{ position: "absolute", left: 0 }}
          onClick={() => setIsConfirm(true)}
        />
      )}
      <div className={cl.statements}>
        {isLoading
          ? PLACEHOLDERS.map((_t, i) => (
              <div
                key={i}
                className={`${cl.placeholder} ${
                  i % 2 === 0 ? cl.fromMe : cl.fromThem
                }`}
              >
                {PLACEHOLDER_TEXT.repeat(Math.floor(Math.random() * 4 + 1))}
              </div>
            ))
          : currentSituation.statements.map((s) => (
              <div
                className={`${s.actor === "A" ? cl.fromMe : cl.fromThem} ${
                  isActive(s) ? cl.active : ""
                }`}
                key={s.id}
                onClick={(e) => handleStatementClick(s, e)}
              >
                {getStatementText(s)}
                <div
                  className={`${cl.buttons} ${isActive(s) ? cl.active : ""}`}
                  ref={isActive(s) ? buttonsRef : null}
                >
                  {active?.phraseVoice && (
                    <PrimaryButton
                      onClick={handleAudioClick}
                      style={{ padding: "9px 5px" }}
                      variant={"dark"}
                      icon={<IconSound className={cl.buttonIcon} />}
                    />
                  )}
                  <RefreshButton
                    isLoading={updateLoading}
                    onClick={() => handleForceRegenerate(active)}
                    style={{ height: 30, width: 30, minWidth: 30 }}
                  />
                </div>
                {isActive(s) && (
                  <MyAdjustableTextarea
                    onClick={(e) => e.stopPropagation()}
                    className={`${s.actor === "A" ? cl.editMe : cl.editThem}`}
                    value={text}
                    onChange={handleInputChange}
                    mode={"regular"}
                    isFocus
                  />
                )}
              </div>
            ))}
      </div>
      <MyModal
        visible={isConfirm}
        setVisible={setIsConfirm}
        style={{ padding: 0, maxHeight: "maxContent" }}
      >
        <div className={cl.modalInner}>
          <p className={cl.title}>{t("situations.confrim_changes")}</p>
          <p className={cl.topDesc}>{t("situations.change_statements_desc")}</p>
          <div className={cl.statementsInfoCont}>
            <div className={cl.statementsInfoContInner}>
              {updatedStatements.map((s) => (
                <Fragment key={s.id}>
                  <div className={cl.horizontalContainer}>
                    <p className={cl.statementDesc}>
                      {t(
                        s.forceUpdate
                          ? "situations.no_changes"
                          : "situations.changes"
                      )}
                    </p>
                    <PrimaryButton
                      text={t("buttons.cancel")}
                      variant={"red"}
                      style={{
                        fontSize: 12,
                        padding: "3px 6px",
                        marginLeft: "auto",
                        marginRight: 30,
                      }}
                      onClick={() => handleStatementReset(s)}
                    />
                  </div>
                  <div className={cl.statementInfoObj}>
                    {s.forceUpdate ? (
                      <p
                        className={` ${s.actor === "A" ? cl.me : cl.them} ${
                          cl.fullWidth
                        }`}
                      >
                        {s.phrase}
                      </p>
                    ) : (
                      <>
                        <p className={` ${s.actor === "A" ? cl.me : cl.them}`}>
                          {s.phrase}
                        </p>
                        <IconArrow className={cl.arrow} />
                        <p
                          className={`${s.actor === "A" ? cl.me : cl.them} ${
                            s.voiceId ? "" : cl.disabled
                          }`}
                        >
                          {s.newPhrase}
                        </p>
                      </>
                    )}
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
          {updatedStatements.some((s) => !s.voiceId && s.actor === "B") && (
            <>
              <p className={cl.bottomDesc}>
                {t("situations.respondent_gender_desc")}
              </p>
              <ToggleButton
                options={GENDERS}
                selectedOption={respondentGender}
                setOption={setRespondentGender}
                withTranslations={"situations"}
              />
            </>
          )}
          <div className={cl.horizontalContainer}>
            <PrimaryButton
              variant={"red"}
              text={t("buttons.reset")}
              onClick={handleStatementsReset}
            />
            <PrimaryButton
              variant={"dark"}
              text={t("landing.confirm")}
              isLoading={isUpdateLoading}
              onClick={handleStatementsUpdate}
              isDisabled={
                updatedStatements.some((s) => !s.voiceId && s.actor === "B") &&
                !respondentGender
              }
            />
          </div>
        </div>
      </MyModal>
    </div>
  );
};

export default observer(Statements);
