import { t } from "i18next";
import player from "../store/player";
const TICKS_IN_SECOND = 10000000;

export async function parseTrackData(json) {
  player.setContentTitle(json.title);
  player.setContentId(json.id);
  player.setImage(json.image?.md);
  player.setDifficult(json.difficulty);
  player.setShowPlayer(true);
  player.setDuration(json.duration);
  player.setTrackLength(json.duration * TICKS_IN_SECOND);
  player.setYtVideoId(json.externalId);
  player.setSourceLang(json.lang);
  if (json.recognized && !json.hasErrors) {
    player.setResetHeights();
    player.setIsLoading(false);
    player.setIsRecognizing(false);
    player.setMaxTime(null);
    player.setPercentOfRecognition(null);
  } else {
    player.setIsRecognizing(true);
    player.setIsLoading(true);
    player.setPercentOfRecognition(json.percentOfRecognition);
  }
  player.setDisplayText(json.sentences || t("text.no_sentences"));
}
