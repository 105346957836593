import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { InterfaceContext } from "../../../../App";
import TrackService from "../../../../api/TrackService";
import { LanguageLevels } from "../../../../data/common";
import { TrackCardModal, TrackCategories } from "..";
import { useFetching } from "../../../../hooks/useFetching";
import toHoursAndMinutes from "../../../../utils/toHoursAndMinutes";
import { Checkbox, PrimaryButton } from "../../../../UI";
import DemoPageLoader from "../../../../components/UI/DemoPageLoader/DemoPageLoader";
import cl from "./TrackCard.module.css";

const TrackCard = ({ trackData, onDelete, onChange }) => {
  const { t } = useTranslation();
  const { appInterface } = useContext(InterfaceContext);
  const { languages } = appInterface;

  const {
    description,
    difficulty,
    duration,
    externalId,
    hasErrors,
    image,
    lang,
    podcastTitle,
    processed,
    recognized,
    title,
    id,
    categories,
  } = trackData;

  const [isEdit, setIsEdit] = useState(false);

  const toggleEditMode = () => {
    setIsEdit(!isEdit);
  };

  const [updateDifficulty, updateDifficultyLoading] = useFetching(
    async ({ difficulty }) => {
      if (difficulty === trackData.difficulty) return;
      const res = await TrackService.updateTrack({
        id,
        difficulty,
        processed: true,
      });
      if (res && res.status === 200) {
        onChange(id, { difficulty, processed: true });
      }
    }
  );

  const [updateLanguage, updateLanguageLoading] = useFetching(async (lang) => {
    toggleEditMode();
    const res = await TrackService.updateTrack({
      id,
      lang,
    });
    if (res && res.status === 200) {
      onChange(id, { lang });
    }
  });

  const [updateProcessed, updateProcessedLoading] = useFetching(async () => {
    const res = await TrackService.updateTrack({
      id,
      processed: !processed,
    });
    if (res && res.status === 200) {
      onChange(id, { processed: !processed });
    }
  });

  const getCardClassName = () => {
    const classNames = [cl.trackCard];
    if (recognized !== "true") classNames.push(cl.light);
    if (hasErrors !== undefined && hasErrors !== 0) classNames.push(cl.error);

    return classNames.join(" ");
  };

  const handleTitleClick = () => {
    window.open(`https://www.youtube.com/watch?v=${externalId}`, "_blank");
  };

  return (
    <div className={getCardClassName()}>
      <div className={cl.leftColumn}>
        <div className={cl.imageContainer}>
          <img src={image?.md} alt={title} />
        </div>
        {isEdit ? (
          <div className={cl.languagesContainer}>
            <div className={cl.languagesList}>
              {languages.map((lObj) => (
                <span
                  className={`${cl.language} ${
                    lObj.value === lang ? cl.active : ""
                  }`}
                  key={lObj.value}
                  onClick={() => {
                    updateLanguage(lObj.value);
                  }}
                >
                  {lObj.value}
                </span>
              ))}
            </div>
            <PrimaryButton
              text={t("buttons.close")}
              onClick={toggleEditMode}
              variant={"red"}
            />
          </div>
        ) : (
          <div className={cl.infoCont}>
            <p
              className={`${cl.language} ${cl.active} ${
                updateLanguageLoading ? cl.loading : ""
              }`}
              onClick={toggleEditMode}
            >
              {lang}
            </p>
            <p className={cl.infoText}>{toHoursAndMinutes(duration)}</p>
          </div>
        )}
      </div>
      <div className={cl.rightColumn}>
        <div className={cl.titleContainer}>
          <p className={cl.infoText}>{podcastTitle}</p>
          <p className={cl.title} onClick={handleTitleClick}>
            {title}
          </p>
        </div>
        <p className={cl.description}>{description}</p>
        {recognized === "true" && (
          <>
            <TrackCategories id={id} categories={categories} />
            <div className={cl.levelsContainer}>
              {LanguageLevels.map((d, i) => (
                <PrimaryButton
                  key={d}
                  text={d}
                  variant={difficulty === i ? "dark" : "light"}
                  style={{ padding: 5, width: 28, height: 28 }}
                  onClick={() => updateDifficulty({ difficulty: i })}
                />
              ))}
              {(updateProcessedLoading ||
                updateDifficultyLoading ||
                updateLanguageLoading) && <DemoPageLoader />}
              <Checkbox
                value={processed}
                onChange={updateProcessed}
                label={t("podcasts.processed")}
                style={{ marginLeft: 20, marginBottom: 3 }}
              />
            </div>
          </>
        )}
      </div>
      <TrackCardModal
        className={cl.buttonsContainer}
        trackData={trackData}
        onDelete={onDelete}
      />
    </div>
  );
};

export default TrackCard;
