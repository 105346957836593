import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import exercisesStore from "../../../../store/exercisesStore";
import MyAddButton from "../../../../../../components/UI/MyAddButton/MyAddButton";
import MyStyledInput from "../../../../../../components/UI/MyStyledInput/MyStyledInput";
import { GrammarExerciseTypes } from "../../../../data/constants";
import cl from "./GapWord.module.css";

const MIN_OPTIONS_AMOUNT = 1;

const GapWord = ({ word, onChange }) => {
  const { t } = useTranslation();

  const { currentExercise } = exercisesStore;
  const { type } = currentExercise;

  const wordRef = useRef();
  const [isEdit, setIsEdit] = useState(false);
  const [options, setOptions] = useState(word.options || []);

  const getClassName = () => {
    const classnames = [cl.GapWord];
    if (type === GrammarExerciseTypes.Multichoice && options.length)
      classnames.push(cl.selected);
    if (type === GrammarExerciseTypes.Cloze && word.hidden)
      classnames.push(cl.selected);
    if (type === GrammarExerciseTypes.Substitution && word.hidden)
      classnames.push(cl.selected);
    if (isEdit) {
      classnames.push(cl.active);
    }
    return classnames.join(" ");
  };

  const renderTooltip = () => {
    if (type !== GrammarExerciseTypes.Multichoice || !isEdit) return <></>;

    const rest = options.filter((o) => o !== word.word);
    const isAddDisabled = rest.length && !rest[rest.length - 1];

    return (
      <span className={cl.tooltip}>
        <div className={cl.tooltipCont}>
          <span>{t("exercises.tooltip_dropdown")}</span>
          {rest.map((option, idx) => {
            const deleteFlag =
              options.length < MIN_OPTIONS_AMOUNT || option !== word.text;
            return (
              <div className={cl.optionLine} key={`${word.id}_cont_${idx}`}>
                <MyStyledInput
                  key={`${word.id}_option_${idx}`}
                  value={option}
                  onChange={(text) => handleOptionInput(idx, text)}
                  isDelete={deleteFlag}
                  onDelete={() => handleOptionDelete(idx)}
                  isFocus={!option.length}
                />
              </div>
            );
          })}
          <MyAddButton
            text={t("exercises.tooltip_new_option")}
            onClick={handleNewOption}
            isDisabled={isAddDisabled}
          />
        </div>
      </span>
    );
  };

  const handleOptionInput = (index, text) => {
    const newOptions = [...options];
    newOptions[index] = text.trim();
    setOptions(newOptions);
  };

  const handleNewOption = () => {
    setOptions([...options, ""]);
  };

  const handleOptionDelete = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const handleWordClick = () => {
    if (type === GrammarExerciseTypes.Multichoice) {
      setIsEdit(!isEdit);
      return;
    }
    onChange();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (!isEdit) return;
      if (wordRef.current && !wordRef.current.contains(event.target)) {
        onChange(options);
        setIsEdit(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wordRef, isEdit, options]);

  return (
    <div ref={wordRef}>
      <span className={getClassName()} onClick={handleWordClick}>
        {word.word}
        {renderTooltip()}
      </span>
      {word.endChar && (
        <span style={{ userSelect: "none" }}>{word.endChar}</span>
      )}
    </div>
  );
};

export default observer(GapWord);
