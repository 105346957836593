export const interfaceLangs = [
  { value: "af", name: "Afrikaans" },
  { value: "ak", name: "Twi (Akan)" },
  { value: "am", name: "አማርኛ" },
  { value: "ar", name: "العربية" },
  { value: "as", name: "অসমীয়া" },
  { value: "ay", name: "Aymar aru" },
  { value: "az", name: "Azərbaycanca" },
  { value: "be", name: "Беларуская" },
  { value: "bg", name: "Български" },
  { value: "bho", name: "भोजपुरी" },
  { value: "bm", name: "Bamanankan" },
  { value: "bn", name: "বাংলা" },
  { value: "bs", name: "Bosanski" },
  { value: "ca", name: "Català" },
  { value: "ceb", name: "Cebuano" },
  { value: "ckb", name: "کوردی (سورانی)" },
  { value: "co", name: "Corsu" },
  { value: "cs", name: "Česky" },
  { value: "cy", name: "Cymraeg" },
  { value: "da", name: "Dansk" },
  { value: "de", name: "Deutsch" },
  { value: "doi", name: "डोगरी" },
  { value: "dv", name: "ދިވެހިބަސް" },
  { value: "ee", name: "Eʋegbe" },
  { value: "el", name: "Ελληνικά" },
  { value: "en", name: "English" },
  { value: "eo", name: "Esperanto" },
  { value: "es", name: "Español" },
  { value: "et", name: "Eesti" },
  { value: "eu", name: "Euskara" },
  { value: "fa", name: "فارسی" },
  { value: "fi", name: "Suomi" },
  { value: "fil", name: "Filipino (Tagalog)" },
  { value: "fr", name: "Français" },
  { value: "fy", name: "Frysk" },
  { value: "ga", name: "Gaeilge" },
  { value: "gd", name: "Gàidhlig" },
  { value: "gl", name: "Galego" },
  { value: "gn", name: "Avañe'ẽ" },
  { value: "gom", name: "कोंकणी" },
  { value: "gu", name: "ગુજરાતી" },
  { value: "ha", name: "Hausa" },
  { value: "haw", name: "ʻŌlelo Hawaiʻi" },
  { value: "he", name: "עברית" },
  { value: "hi", name: "हिन्दी" },
  { value: "hmn", name: "Hmoob" },
  { value: "hr", name: "Hrvatski" },
  { value: "ht", name: "Kreyòl ayisyen" },
  { value: "hu", name: "Magyar" },
  { value: "hy", name: "Հայերեն" },
  { value: "id", name: "Bahasa Indonesia" },
  { value: "ig", name: "Igbo" },
  { value: "ilo", name: "Ilokano" },
  { value: "is", name: "Íslenska" },
  { value: "it", name: "Italiano" },
  { value: "ja", name: "日本語" },
  { value: "jv", name: "Basa Jawa" },
  { value: "ka", name: "ქართული" },
  { value: "kk", name: "Қазақша" },
  { value: "km", name: "ភាសាខ្មែរ" },
  { value: "kn", name: "ಕನ್ನಡ" },
  { value: "ko", name: "한국어" },
  { value: "kri", name: "Krio" },
  { value: "ku", name: "Kurdî" },
  { value: "ky", name: "Kyrgyzcha" },
  { value: "la", name: "Latīņu" },
  { value: "lb", name: "Lëtzebuergesch" },
  { value: "lg", name: "Luganda" },
  { value: "ln", name: "Lingála" },
  { value: "lo", name: "Lao" },
  { value: "lt", name: "Lietuvių" },
  { value: "lus", name: "Mizo" },
  { value: "lv", name: "Latviešu" },
  { value: "mai", name: "Maithili" },
  { value: "mg", name: "Malagasy" },
  { value: "mi", name: "Māori" },
  { value: "mk", name: "Македонски" },
  { value: "ml", name: "മലയാളം" },
  { value: "mn", name: "Монгол" },
  { value: "mni-Mtei", name: "Meiteilon (Manipuri)" },
  { value: "mr", name: "मराठी" },
  { value: "ms", name: "Bahasa Melayu" },
  { value: "mt", name: "Malti" },
  { value: "my", name: "ဗမာစာ" },
  { value: "ne", name: "नेपाली" },
  { value: "nl", name: "Nederlands" },
  { value: "no", name: "Norsk" },
  { value: "nso", name: "Sepedi" },
  { value: "ny", name: "Chichewa" },
  { value: "om", name: "Oromoo" },
  { value: "or", name: "ଓଡ଼ିଆ (Oriya)" },
  { value: "pa", name: "ਪੰਜਾਬੀ" },
  { value: "pl", name: "Polski" },
  { value: "ps", name: "پشتو" },
  { value: "pt", name: "Português (Portugal, Brasil)" },
  { value: "qu", name: "Quechua" },
  { value: "ro", name: "Română" },
  { value: "ru", name: "Русский" },
  { value: "rw", name: "Kinyarwanda" },
  { value: "sa", name: "संस्कृतम्" },
  { value: "sd", name: "سنڌي" },
  { value: "si", name: "සිංහල" },
  { value: "sk", name: "Slovenčina" },
  { value: "sl", name: "Slovenščina" },
  { value: "sm", name: "Gagana Samoa" },
  { value: "sn", name: "Shona" },
  { value: "so", name: "Soomaali" },
  { value: "sq", name: "Shqipe" },
  { value: "sr", name: "Српски" },
  { value: "st", name: "Sesotho" },
  { value: "su", name: "Basa Sunda" },
  { value: "sv", name: "Svenska" },
  { value: "sw", name: "Kiswahili" },
  { value: "ta", name: "தமிழ்" },
  { value: "te", name: "తెలుగు" },
  { value: "tg", name: "Тоҷикӣ" },
  { value: "th", name: "ภาษาไทย" },
  { value: "ti", name: "ትግርኛ" },
  { value: "tk", name: "Turkmen" },
  { value: "tl", name: "Tagalog" },
  { value: "tr", name: "Turkish" },
  { value: "ts", name: "Tsonga" },
  { value: "tt", name: "Tatar" },
  { value: "tw", name: "Twi" },
  { value: "ug", name: "Uighur" },
  { value: "uk", name: "Ukrainian" },
  { value: "ur", name: "Urdu" },
  { value: "uz", name: "Uzbek" },
  { value: "vi", name: "Vietnamese" },
  { value: "xh", name: "Xhosa" },
  { value: "yi", name: "Yiddish" },
  { value: "yo", name: "Yoruba" },
  { value: "zh", name: "Chinese" },
  { value: "zu", name: "Zulu" },
];
