import React, { useEffect, useRef, useState } from "react";
import MyCloseButton from "../MyCloseButton/MyCloseButton";
import cl from "./MyAdjustableTextarea.module.css";

const MyAdjustableTextarea = ({
  placeholder,
  value,
  onChange,
  onClick,
  onDelete,
  mode,
  isFocus,
  style,
  className,
}) => {
  const textAreaRef = useRef(null);
  const [isBlur, setIsBlur] = useState(true);

  useEffect(() => {
    if ((mode === "sentence" || mode === "regular") && isFocus) {
      textAreaRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const input = textAreaRef.current;
    if (input) {
      input.style.height = "0px";
      const scrollHeight = input.scrollHeight;
      input.style.height =
        mode === "sentence" ? scrollHeight + "px" : scrollHeight + 3 + "px";
    }
  }, [textAreaRef, value]);

  const getClassName = () => {
    const classNames = [className || cl.adjustableInput];
    if (mode === "sentence") {
      classNames.push(cl.sentence);
      if (isBlur) {
        classNames.push(cl.blur);
      }
    }
    return classNames.join(" ");
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    onChange(newValue);
  };

  const handleBlur = () => {
    if (mode === "sentence" && !value.length) {
      onDelete();
    }
    setIsBlur(true);
  };

  const handleKeyDown = (e) => {
    if (mode === "sentence" && e.key === "Enter") {
      textAreaRef.current.blur();
    }
  };

  const handleMouseDown = (e) => {
    if (mode === "sentence") {
      setTimeout(() => {
        textAreaRef.current.blur();
      }, 0);
    }
  };

  const handleMouseUp = (e) => {
    textAreaRef.current.focus();
  };

  return (
    <div
      className={cl.inputCont}
      style={{ position: mode === "sentence" ? "relative" : "static" }}
      onClick={onClick}
    >
      <textarea
        ref={textAreaRef}
        className={getClassName()}
        style={style}
        rows={1}
        placeholder={placeholder}
        value={value}
        onBlur={handleBlur}
        onChange={handleInputChange}
        onFocus={() => setIsBlur(false)}
        onKeyDown={handleKeyDown}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      ></textarea>
      {mode === "sentence" && (
        <div className={`${cl.deleteIcon} ${isBlur ? "" : cl.focus}`}>
          <MyCloseButton
            style={{
              top: 11,
              right: 11,
            }}
            onClick={onDelete}
          />
        </div>
      )}
    </div>
  );
};

export default MyAdjustableTextarea;
