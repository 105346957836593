import React, { useEffect, useRef } from "react";
import { motion, useAnimate, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";
import { PonyLogo, PageCont, Typography } from "../../components";
import cl from "./ChooseView.module.css";

const ChooseView = () => {
  const { t } = useTranslation();

  const [textRef, animateText] = useAnimate();
  const isInView = useInView(textRef);

  const containerRef = useRef();

  useEffect(() => {
    if (isInView) {
      animateText(textRef.current, { opacity: 1, y: 0 }, { duration: 1 });
    }
  }, [isInView]);

  return (
    <PageCont color={"white"} style={{ overflow: "hidden scroll" }}>
      <div ref={containerRef} className={cl.container}>
        <motion.div
          ref={textRef}
          key="chooseText"
          initial={{ opacity: 0, y: "50%" }}
        >
          <div className={cl.horizontalContainer}>
            <Typography
              variant={"h2"}
              color={"#000000"}
              style={{ whiteSpace: "nowrap" }}
            >
              {t("new_landing.choose_title")}
            </Typography>
            <PonyLogo size={"large"} />
          </div>
          <Typography variant={"h4"} color={"#000000"}>
            {t("new_landing.choose_subtitle")}
          </Typography>
        </motion.div>
      </div>
    </PageCont>
  );
};

export default ChooseView;
