import { googleLngList } from "../../../data/languagesList";
import { LanguageLevels } from "../../../data/common";

export const UsersPerPage = 50;

export const AllLanguages = [
  ...Object.keys(googleLngList).map((lngCode) => ({
    value: lngCode,
    name: googleLngList[lngCode],
  })),
];

export const LanguageOptions = LanguageLevels.map((l, i) => ({
  value: i,
  name: l,
}));
