import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import situationsStore from "../../store/situationsStore";
import { useFetching } from "../../../../hooks/useFetching";
import SituationsService from "../../../../api/SituationsService";
import {
  BackButton,
  EntitiesContainer,
  NavigationWrapper,
  SituationCard,
} from "..";
import cl from "./QueueList.module.css";

const QueueList = () => {
  const { t } = useTranslation();
  const intervalRef = useRef();
  const [queue, setQueue] = useState([]);

  const handleQueueClose = () => {
    situationsStore.setIsQueue(false);
  };

  const [getQueue] = useFetching(async (prevQueue) => {
    const { data } = await SituationsService.getQueue();
    if (data && data.length) {
      setQueue(data);
    } else {
      setQueue([]);
    }
  });

  useEffect(() => {
    getQueue(queue);
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      getQueue(queue);
    }, 3000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <>
      <NavigationWrapper>
        <BackButton
          onClick={handleQueueClose}
          text={"situations.categories_btn"}
        />
      </NavigationWrapper>
      <div className={cl.queueList}>
        {queue.length ? (
          <EntitiesContainer>
            {queue.map((sit) => (
              <SituationCard key={sit.id} situation={sit} showLanguage />
            ))}
          </EntitiesContainer>
        ) : (
          <p style={{ margin: "auto" }}>
            {t("dictionary_administration.empty_queue")}
          </p>
        )}
      </div>
    </>
  );
};

export default observer(QueueList);
