export const chips = [
  { text: "привет", size: "large" },
  { text: "hola", size: "medium" },
  { text: "salve", size: "large" },
  { text: "سَلام", size: "large" },
  { text: "merhaba", size: "large" },
  { text: "xin chào", size: "medium" },
  { text: "您好", size: "large" },
  { text: "こんにちは", size: "medium" },
  { text: "olá", size: "large" },
  { text: "السلام عليكم", size: "large" },
  { text: "bonjour", size: "medium" },
  { text: "hello", size: "large" },
  { text: "dzień dobry", size: "medium" },
  { text: "guten Tag", size: "medium" },
  { text: "안녕하세요", size: "large" },
  { text: "përshëndetje", size: "large" },
  { text: "hei", size: "medium" },
  { text: "Привіт", size: "large" },
  { text: "Alô", size: "large" },
  { text: "Hej", size: "large" },
  { text: "habari", size: "medium" },
  { text: "שלום", size: "large" },
  { text: "Ahoj", size: "medium" },
  { text: "ciao", size: "large" },
  { text: "прывітанне", size: "medium" },
  { text: "saluton", size: "large" },
];
