import React, { useEffect, useRef, useState } from "react";
import MyCloseButton from "../../components/UI/MyCloseButton/MyCloseButton";
import cl from "./InteractiveInput.module.css";

const InteractiveInput = ({
  value,
  placeholder,
  onChange,
  onDelete,
  isFocus,
}) => {
  const inputRef = useRef(null);
  const [isBlur, setIsBlur] = useState(true);

  useEffect(() => {
    if (isFocus) {
      inputRef.current.focus();
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      inputRef.current.blur();
    }
  };

  const handleBlur = () => {
    if (!value.length && onDelete) {
      onDelete();
    }
    setIsBlur(true);
  };

  return (
    <div className={cl.inputCont}>
      <input
        ref={inputRef}
        placeholder={placeholder}
        value={value}
        type="text"
        className={`${cl.input} ${isBlur ? cl.blur : cl.focus}`}
        onChange={(e) => onChange(e.target.value, e)}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsBlur(false)}
        onBlur={handleBlur}
      />
      {onDelete && (
        <div className={`${cl.deleteIcon} ${isBlur ? "" : cl.focus}`}>
          <MyCloseButton
            style={{
              width: 10,
              height: 10,
              top: "50%",
              transform: "translateY(-50%)",
              right: 10,
            }}
            onClick={onDelete}
          />
        </div>
      )}
    </div>
  );
};
export default InteractiveInput;
