import React from "react";
import { WordStatuses } from "../../data/constants";
import cl from "./WordChip.module.css";
import { Checkbox } from "../../../../UI";

const WordChip = React.forwardRef(
  ({ wordObj, multiselect, isActive, onClick, onSelect }, ref) => {
    const { word, partOfSpeech, checked, selected, status } = wordObj;

    const getClassName = () => {
      const classNames = [cl.word];

      if (isActive) classNames.push(cl.active);
      if (checked) classNames.push(cl.checked);
      if (status !== WordStatuses.ok) classNames.push(cl.invalid);
      if(multiselect) classNames.push(cl.shifted);

      return classNames.join(" ");
    };

    return (
      <div
        className={getClassName()}
        onClick={onClick}
        ref={isActive ? ref : null}
      >
        {multiselect && (
          <div
            className={cl.checkBoxCont}
            onClick={(e) => e.stopPropagation()}
          >
            <Checkbox value={selected} onChange={onSelect} />
          </div>
        )}
        <span>{word}</span>
        <span>{`(${partOfSpeech})`}</span>
      </div>
    );
  }
);

export default WordChip;
