import React from "react";
import { ToggleButton } from "..";
import cl from "./LabeledToggleButton.module.css";

const LabeledToggleButton = ({
  title,
  options,
  nullable,
  selectedOption,
  availableOptions,
  setOption,
  style,
  variant,
  withTranslations,
  multiple,
  label,
}) => {
  return (
    <div>
      <p className={cl.label}>{label}</p>
      <ToggleButton
        title={title}
        options={options}
        nullable={nullable}
        selectedOption={selectedOption}
        availableOptions={availableOptions}
        setOption={setOption}
        style={style}
        variant={variant}
        withTranslations={withTranslations}
        multiple={multiple}
      />
    </div>
  );
};

export default LabeledToggleButton;
