import React from "react";
import cl from "./Loader.module.css";

const Loader = ({
  centerText,
  styleForWrapper,
  notFullScreen,
  text,
  style,
}) => {
  return (
    <div
      style={styleForWrapper || {}}
      className={notFullScreen ? null : cl.wrapper}
    >
      <div className={cl.loader} style={{ ...style }}>
        <span className={cl.loaderCenterText}>{centerText}</span>
      </div>
      {text && <span className={cl.loaderText}>{text}</span>}
    </div>
  );
};

export default Loader;
