import React, { useState } from "react";
import { useFetching } from "../../../../hooks/useFetching";
import LanguagesService from "../../../../api/LanguagesService";
import { Switch } from "../../../../UI";
import cl from "./LanguageCard.module.css";

const LanguageCard = ({ lang }) => {
  const { code } = lang;
  const [ready, setReady] = useState(lang.ready);

  const [updateLang, isLoading] = useFetching(async () => {
    setReady(!ready);
    const res = await LanguagesService.updateLanguage({
      code,
      ready: !ready,
    });
    if (res.status !== 200) {
      setReady(!ready);
    }
  });

  return (
    <div className={cl.languageCard}>
      <p className={cl.langTitle}>
        {lang.title}
        {lang.code && <span>{`(${lang.code})`} </span>}
      </p>
      <div className={cl.optionCont}>
        <Switch isOn={ready} handleToggle={updateLang} id={code} />
      </div>
    </div>
  );
};

export default LanguageCard;
