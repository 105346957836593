export const slides = [
  {
    title: "title_1",
    subtitle: "subtitle_1",
    fileName: "slide_1.webp",
  },
  {
    title: "title_2",
    subtitle: "subtitle_2",
    fileName: "slide_2.webp",
  },
  {
    title: "title_3",
    subtitle: "subtitle_3",
    fileName: "slide_3.webp",
  },
  {
    title: "title_4",
    subtitle: "subtitle_4",
    fileName: "slide_4.webp",
  },
  {
    title: "title_5",
    subtitle: "subtitle_5",
    fileName: "slide_5.webp",
  },
  {
    title: "title_6",
    subtitle: "subtitle_6",
    fileName: "slide_6.webp",
  },
];
