import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext, InterfaceContext } from "../../App";
import { interfaceLangs } from "../../data/interfaceLangs";
import { PrimaryButton, PrimarySelect, StyledNavLink } from "../../UI";
import { ROUTES } from "../../router";
import BackPageArrow from "../UI/BackPageArrow/BackPageArrow";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { ReactComponent as IconLogout } from "../../assets/img/icon-logout.svg";
import { ReactComponent as IconProfile } from "../../assets/img/icon-profile.svg";
import cl from "./Header.module.css";

const HEADER_EXCLUDED_PAGES = [ROUTES.default, ROUTES.demo];

const ADMIN_NAVIGATION = [
  { to: ROUTES.library, text: "navbar.tracks" },
  { to: ROUTES.podcasts, text: "navbar.podcasts" },
  { to: ROUTES.users, text: "navbar.users" },
  { to: ROUTES.languages, text: "user.languages" },
  { to: ROUTES.dictionary, text: "landing.dictionary" },
  { to: ROUTES.situations, text: "situations.situations" },
  { to: ROUTES.healthcheck, text: "navbar.services" },
  { to: ROUTES.notify, text: "navbar.notify" },
  { to: ROUTES.exercises, text: "navbar.exercises" },
];

const Header = () => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  const { appInterface } = useContext(InterfaceContext);
  const { t, i18n } = useTranslation();

  const logout = () => {
    auth.logout();
  };

  useEffect(() => {
    if (window.location.href.indexOf("login") >= 0) {
      let userLangs = navigator.languages;
      interfaceLangs.forEach((lang) => {
        if (userLangs.includes(lang.value)) {
          appInterface.setLanguage(lang);
          return;
        }
      });
    }
  }, []);

  const isHeaderVisible =
    !HEADER_EXCLUDED_PAGES.includes(location.pathname) &&
    !location.pathname.includes(ROUTES.share_situation) &&
    !location.pathname.includes(ROUTES.share_dictionary);
  const isLogin = location.pathname.includes("login");

  if (!isHeaderVisible) {
    return null;
  }

  return (
    <header className={cl.header}>
      <BackPageArrow />
      <StyledNavLink
        to={ROUTES.default}
        style={{
          marginLeft: 10,
          marginRight: auth.role !== "guest" ? 60 : 35,
          minWidth: 80,
          width: 80,
          position: "relative",
          top: 3,
        }}
      >
        <Logo style={{ display: "block" }} />
      </StyledNavLink>
      {auth.role === "admin" ? (
        <>
          {ADMIN_NAVIGATION.map((l, i) => (
            <StyledNavLink
              key={l.to}
              to={l.to}
              text={t(l.text)}
              showDivider={i !== ADMIN_NAVIGATION.length - 1}
              showUnderline
            />
          ))}
          <div className={cl.profileBtns}>
            <StyledNavLink to={ROUTES.profile}>
              <PrimaryButton
                variant={"dark"}
                icon={
                  <IconProfile
                    style={{ height: 20, width: 20, minWidth: 20 }}
                  />
                }
                text={auth.user?.name || auth.user?.email?.split("@")[0]}
                style={{ padding: "8px 24px" }}
              />
            </StyledNavLink>
            <PrimaryButton
              variant={"dark"}
              icon={<IconLogout />}
              onClick={logout}
              style={{ width: 36, height: 36, padding: "unset" }}
            />
          </div>
        </>
      ) : (
        isLogin && (
          <PrimarySelect
            value={i18n.language}
            options={interfaceLangs}
            onChange={appInterface.setLanguage}
          />
        )
      )}
    </header>
  );
};

export default observer(Header);
