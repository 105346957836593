import { useState, useRef, useEffect } from "react";

const useHorizontalScroll = ({ isAnimate }) => {
  const [lock, setLock] = useState(false);
  const animationRef = useRef();
  const ref = useRef();

  const animateIn = () => {
    Array.from(ref.current.children).map((c) => (c.style.left = "-15%"));
  };

  const animateOut = () => {
    Array.from(ref.current.children).map((c) => (c.style.left = "0%"));
  };

  useEffect(() => {
    const el = ref.current;

    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0 || lock) return;
        const isStart = el.scrollLeft === 0;
        const isEnd = el.scrollWidth - el.clientWidth - el.scrollLeft <= 1;
        const isUp = e.deltaY > 0 ? false : true;

        if ((isStart && isUp) || (isEnd && !isUp)) {
          el.removeEventListener("wheel", onWheel);
          setLock(true);
          setTimeout(() => {
            setLock(false);
            el.addEventListener("wheel", onWheel, {
              passive: false,
            });
          }, 300);
        } else {
          el.scrollLeft += e.deltaY;
        }
      };

      el.addEventListener("wheel", onWheel, {
        passive: false,
      });

      return () => {
        el.removeEventListener("wheel", onWheel);
      };
    }
  }, []);

  const clearAnimation = () => {
    if (animationRef.current) {
      const { t1, t2 } = animationRef.current;
      clearTimeout(t1);
      clearTimeout(t2);
      animateOut();
    }
  };

  useEffect(() => {
    if (isAnimate && ref.current) {
      const t1 = setTimeout(() => animateIn(), [1250]);
      const t2 = setTimeout(() => animateOut(), [1750]);
      animationRef.current = { t1, t2 };
      ref.current.addEventListener("touchstart", clearAnimation, {
        passive: "false",
      });
    }
    return () => {
      ref.current.removeEventListener("touchstart", clearAnimation);
    };
  }, [isAnimate, ref]);

  return [lock, ref];
};

export default useHorizontalScroll;
