import React, { useEffect, useState } from "react";
import cl from "./QuizPreview.module.css";

const QuizPreview = ({ exerciseObj, showAnswers }) => {
  const { data } = exerciseObj;
  const { questions } = data;
  const [answers, setAnswers] = useState(new Array(questions?.length).fill(""));

  const handleAnswerClick = (qIdx, answer) => {
    const newAnswers = [...answers];
    newAnswers[qIdx] = newAnswers[qIdx] === answer ? "" : answer;
    setAnswers(newAnswers);
  };

  const displayAnswers = () => {
    setAnswers(questions?.map((q) => q.answer));
  };

  const resetAnswers = () => {
    setAnswers(new Array(questions?.length).fill(""));
  };

  useEffect(() => {
    showAnswers ? displayAnswers() : resetAnswers();
  }, [showAnswers]);

  return (
    <div className={cl.questionsCont}>
      {questions?.map((q, qIdx) => (
        <div className={cl.question} key={q.id}>
          <p className={cl.text}>{`${qIdx + 1}. ${q.text}`}</p>
          <div className={cl.answersCont}>
            {q.options.map((o) => (
              <span
                className={`${cl.answer} ${
                  answers[qIdx] === o.text ? cl.active : ""
                } ${showAnswers && o.text === q.answer ? cl.correct : ""}`}
                key={o.id}
                onClick={() => handleAnswerClick(qIdx, o.text)}
              >
                {o.text}
              </span>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuizPreview;
