import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import podcastsStore from "./store/podcastsStore";
import { useFetching } from "../../hooks/useFetching";
import PodcastService from "../../api/PodcastService";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import { PodcastList, SearchBar } from "./components";
import { DEFAULT_SEARCH_OPTION, PODCASTS_PER_PAGE } from "./data/constants";

const Podcasts = () => {
  const { blacklist, language, level, page, promoted, status, searchString } =
    podcastsStore;

  const [isLoading, setIsLoading] = useState(false);

  const requestRef = useRef(null);
  const timeoutRef = useRef(null);

  const [getPodcasts, podcastsLoading] = useFetching(async () => {
    if (requestRef.current) return;
    requestRef.current = true;
    const params = {
      offset: page * PODCASTS_PER_PAGE,
      limit: PODCASTS_PER_PAGE,
      lang: language !== DEFAULT_SEARCH_OPTION.value ? language : null,
      searchString,
      promoted: promoted || null,
      levels: level !== "all" ? [level] : null,
      sortColumn: level !== "all" ? "levels" : null,
      sortOrder: level !== "all" ? "desc" : null,
      processed:
        status === "processed" ? true : status === "unprocessed" ? false : null,
      isNews: status === "news",
    };
    const { data } = await PodcastService.getList(params);
    podcastsStore.setPodcastsData(data);
    requestRef.current = false;
  });

  const [getBlacklist, blacklistLoading] = useFetching(async () => {
    const { data } = await PodcastService.getBlacklist({
      limit: PODCASTS_PER_PAGE,
      offset: page * PODCASTS_PER_PAGE,
    });
    podcastsStore.setPodcastsData(data);
  });

  const [refresh, refreshLoading] = useFetching(async () => {
    await PodcastService.refresh();
    podcastsStore.resetSearch();
    blacklist ? getBlacklist() : getPodcasts();
  });

  const getPodcastsWithDelay = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      getPodcasts();
      timeoutRef.current = null;
    }, 100);
  };

  useEffect(() => {
    blacklist ? getBlacklist() : getPodcasts();
  }, [page]);

  useEffect(() => {
    podcastsStore.resetSearch();
    getPodcasts();
  }, [promoted, language, level, status]);

  useEffect(() => {
    if (requestRef.current) return;
    podcastsStore.resetSearch();
    getPodcastsWithDelay();
  }, [searchString, requestRef]);

  useEffect(() => {
    podcastsStore.resetSearch();
    setTimeout(() => {
      blacklist ? getBlacklist() : getPodcasts();
    });
  }, [blacklist]);

  useEffect(() => {
    setIsLoading(podcastsLoading || refreshLoading || blacklistLoading);
  }, [podcastsLoading, blacklistLoading, refreshLoading]);

  useEffect(() => {
    return () => {
      podcastsStore.resetSearch();
    };
  }, []);

  return (
    <PageWrapper>
      <SearchBar onRefresh={refresh} />
      <PodcastList isLoading={isLoading} />
    </PageWrapper>
  );
};

export default observer(Podcasts);
