import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import libraryStore from "../../store/libraryStore";
import { BlacklistTrackCard, TrackCard } from "../";
import List from "../../../../components/UI/List/List";

const TrackList = ({ isLoading }) => {
  const { t } = useTranslation();
  const scrollRef = useRef(0);
  const { blacklist, paginationEnd, tracks } = libraryStore;

  const handleTrackListScroll = (e) => {
    if (paginationEnd) return;
    const list = e.target;
    if (!isLoading && list.scrollTop > scrollRef.current) {
      if (list.scrollTop > list.scrollHeight - list.clientHeight - 100) {
        libraryStore.setNextPage();
      }
    }
    scrollRef.current = list.scrollTop;
  };

  if (!tracks.length && !isLoading) {
    return (
      <p style={{ textAlign: "center", margin: "auto" }}>
        {t("errors.tracks_filter")}
      </p>
    );
  }

  return (
    <List onScroll={handleTrackListScroll} isLoading={isLoading}>
      {tracks.map((el) =>
        blacklist ? (
          <BlacklistTrackCard
            key={el.id}
            trackData={el}
            onDelete={libraryStore.deleteTrackById}
          />
        ) : (
          <TrackCard
            style={{ marginBottom: null }}
            key={el.id}
            trackData={el}
            onDelete={libraryStore.deleteTrackById}
            onChange={libraryStore.updateTrack}
          />
        )
      )}
    </List>
  );
};

export default observer(TrackList);
