import React from "react";
import cl from "./PageCont.module.css";

const PageCont = ({ children, color, style }) => {
  return (
    <section className={`${cl.page} ${cl[color]}`} style={style}>
      {children}
    </section>
  );
};

export default PageCont;
