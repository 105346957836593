import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import situationsStore from "../../store/situationsStore";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../hooks/useFetching";
import SituationsService, { URL } from "../../../../api/SituationsService";
import { AddInfoModal, ErrorMessage } from "../";
import { PrimaryButton } from "../../../../UI";
import { ReactComponent as IconSound } from "../../../../assets/img/icon-sound_alt.svg";
import cl from "./SituationControlsBar.module.css";

const SituationControlsBar = ({
  options,
  availableOptions,
  isEmpty,
  isEmptyInfo,
  isSituationLoading,
}) => {
  const { t } = useTranslation();
  const { currentSituation, language, playbackRate } = situationsStore;
  const { situationInfoId } = currentSituation;
  const [errorMessage, setErrorMessage] = useState("");
  const [audio] = useState(new Audio());
  const [isPlay, setIsPlay] = useState(false);

  const [regenerateSituation, isRegenerateLoading] = useFetching(async () => {
    const res = await SituationsService.regenerateSituation({
      situationInfoId,
    });
    if (!res || (res.status !== 200 && res.status !== 201)) {
      setErrorMessage(
        res?.data?.message || "Unexpected error, please try again"
      );
      return;
    }
    handleRegenerate();
  });

  const handleSendButton = () => {
    setErrorMessage("");
    regenerateSituation();
  };

  const isLoading = () =>
    (!isEmpty && isRegenerateLoading) || isSituationLoading;

  const handleSpeedChange = (e) => {
    const value = parseInt(e.target.value);
    situationsStore.setPlaybackRate(value);
  };

  const handleRegenerate = () => {
    situationsStore.setCurrentSituation(null);
  };

  const handleStatementsPlay = () => setIsPlay(!isPlay);

  useEffect(() => {
    if (!currentSituation || !currentSituation.statements) return;

    const { statements } = currentSituation;
    let index = 0;

    const playNextAudio = () => {
      if (index >= statements.length) return;
      const statement = statements[index];
      if (!statement.phraseVoice) {
        index++;
        setTimeout(() => {
          playNextAudio();
        }, 100);
        return;
      }
      audio.src = `${URL}${statement.phraseVoice}`;
      audio.playbackRate = playbackRate / 100 + 1;
      audio.play();
    };

    const playNextCallback = () => {
      index++;
      setTimeout(() => {
        playNextAudio();
      }, 100);
    };

    audio.addEventListener("ended", playNextCallback);
    if (isPlay) {
      playNextAudio();
    } else {
      audio.removeEventListener("ended", playNextCallback);
      audio.src = "";
      audio.pause();
    }

    return () => {
      audio.removeEventListener("ended", playNextCallback);
    };
  }, [isPlay]);

  useEffect(() => {
    setErrorMessage("");
  }, [options, language]);

  useEffect(() => {
    audio.src = "";
    audio.pause();
    setIsPlay(false);
  }, [options, language, playbackRate]);

  useEffect(
    () => () => {
      audio.src = "";
      audio.pause();
      setIsPlay(false);
    },
    []
  );

  return (
    <>
      {!isEmpty && (
        <>
          <PrimaryButton
            onClick={handleStatementsPlay}
            style={{ padding: "9px 5px", width: 36, height: 36, minWidth: 36 }}
            variant={isPlay ? "dark" : "light"}
            icon={
              <IconSound
                className={`${cl.buttonIcon} ${isPlay ? "" : cl.dark}`}
              />
            }
          />
          <div className={cl.playback}>
            <span className={cl.indication}>{"1x"}</span>
            <input
              className={cl.range}
              type="range"
              value={situationsStore.playbackRate}
              onChange={handleSpeedChange}
            />
            <span className={cl.indication}>{"2x"}</span>
          </div>
          <PrimaryButton
            variant={"dark"}
            text={t("situations.regenerate")}
            isLoading={isLoading()}
            onClick={handleSendButton}
          />
          <ErrorMessage message={errorMessage} />
        </>
      )}
      <AddInfoModal isEmpty={isEmptyInfo} existingOptions={availableOptions} />
    </>
  );
};

export default observer(SituationControlsBar);
