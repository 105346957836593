import React, { useEffect, useState } from "react";
import cl from "./MyInfoLabel.module.css";
import { ReactComponent as IconInfo } from "../../../assets/img/icon-info.svg";
import MyButton from "../MyButton/MyButton";
import MyCloseButton from "../MyCloseButton/MyCloseButton";
import getParents from "../../../utils/getParents";

const MyInfoLabel = ({ text, infoText }) => {
  const [isShowText, setIsShowText] = useState(false);

  useEffect(() => {
    document.addEventListener("closeMenus", handleClickOutside);
    return () => {
      document.removeEventListener("closeMenus", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    let parents = getParents(e.detail).reverse();
    let isClickOnInfo = false;
    for (let parent of parents) {
      const cl = parent.className;
      if (typeof cl === "string") {
        if (
          cl.toLowerCase().includes("infotext") ||
          cl.toLowerCase().includes("mybutton")
        ) {
          isClickOnInfo = true;
          break;
        }
      }
    }
    if (!isClickOnInfo) {
      setIsShowText(false);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div className={cl.labelCont}>
        <span className={cl.labelText}>{text}</span>
        <MyButton
          color={"circle dark"}
          style={{ width: 16, height: 16 }}
          onClick={() => {
            setIsShowText(!isShowText);
          }}
        >
          <IconInfo style={{ width: 8, height: 8 }} />
        </MyButton>
      </div>
      {isShowText && (
        <div className={cl.infoText}>
          {infoText
            .split(".")
            .filter((t) => t.length)
            .map((line, idx) => (
              <p className={cl.textLine} key={idx}>{`${line}.`}</p>
            ))}
          <MyCloseButton
            style={{ top: 15, right: 10 }}
            onClick={() => {
              setIsShowText(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MyInfoLabel;
