import React, { useRef, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import UserService from "../../api/UserService";
import { useFetching } from "../../hooks/useFetching";
import isEmailValid from "../../utils/isEmailValid";
import { appInterface, AuthContext } from "../../App";
import player from "../../store/player";
import MyButton from "../../components/UI/MyButton/MyButton";
import Loader from "../../components/UI/Loader/Loader";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import MyInputLabel from "../../components/UI/MyInputLabel/MyInputLabel";
import MyInput from "../../components/UI/MyInput/MyInput";
import MyModal from "../../components/UI/MyModal/MyModal";
import { googleLngList } from "../../data/languagesList";
import { interfaceLangs } from "../../data/interfaceLangs";
import { PrimaryButton, LabeledSelect } from "../../UI";
import cl from "./Profile.module.css";

const Profile = () => {
  const { t, i18n } = useTranslation();
  const { auth } = useContext(AuthContext);
  const { user } = auth;
  const [userData, setUserData] = useState({ stats: { durations: {} } });
  const userDataRef = useRef({});
  const userGetted = useRef(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassRepeat, setNewPassRepeat] = useState("");
  const [wasChanged, setWasChanged] = useState(false);
  const [complete, setComplete] = useState(false);
  const [formError, setFormError] = useState({
    email: false,
  });

  const [saveChanges, isLoading, error, resetError] = useFetching(
    async (params) => {
      const { data } = await UserService.updateUser(params);
      if (data.status === "200") {
        setWasChanged(false);
      }
    }
  );

  useEffect(() => {
    setName(user.name || "");
    setEmail(user.email);
    userDataRef.current = {
      ...user,
      name: user.name || "",
    };
    setUserData({ ...user });
  }, []);

  useEffect(() => {
    if (userGetted.current) {
      checkIsWasChanged();
    }
  }, [showPasswordChange, newPass, newPassRepeat, name, email]);

  useEffect(() => {
    if (userGetted.current)
      setFormError({
        email: !isEmailValid(email),
      });
  }, [email]);

  function checkIsWasChanged() {
    let _isChanges = false;
    if (showPasswordChange && newPass.length > 0) {
      if (newPass === newPassRepeat) {
        _isChanges = true;
      }
    }
    if (userDataRef.current.email !== email) {
      _isChanges = true;
    } else if (userDataRef.current.name !== name) {
      _isChanges = true;
    }
    if (!isEmailValid(email)) _isChanges = false;
    return setWasChanged(_isChanges);
  }

  function handleSaveChanges() {
    const params = {
      id: user.id,
      email: email,
      name: name,
    };
    saveChanges(params);
  }

  const togglePasswordChange = () => {
    setShowPasswordChange(!showPasswordChange);
  };

  return (
    <PageWrapper style={{ overflowY: "auto", position: "relative" }}>
      {isLoading && <Loader />}
      <MyModal
        visible={complete}
        title={t(`success.${complete}`)}
        setVisible={setComplete}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <MyButton
            color={"dark"}
            onClick={() => {
              setComplete(false);
            }}
          >
            {t("buttons.ok")}
          </MyButton>
        </div>
      </MyModal>
      <MyModal visible={error} title={t(`errors.${error}`)}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <MyButton
            color={"dark"}
            onClick={() => {
              if (resetError) resetError();
            }}
          >
            {t("buttons.ok")}
          </MyButton>
        </div>
      </MyModal>
      <div className={cl.userDataInputsCont}>
        <label className={cl.userDataInputLabel}>
          <MyInputLabel text={t("profile.name")} />
          <MyInput
            color={"light"}
            value={name || ""}
            onChange={(val) => {
              setName(val);
            }}
          />
        </label>
        <label className={cl.userDataInputLabel}>
          <MyInputLabel text={t("email")} isError={formError.email} />
          <MyInput
            color={"light"}
            value={email || ""}
            onChange={(val) => {
              setEmail(val);
            }}
          />
        </label>
        <label className={cl.userDataInputLabel}>
          <MyInputLabel text={t("profile.language")} />
          <MyInput
            disabled={true}
            color={"light"}
            value={userData.lang || ""}
            onChange={() => {}}
          />
        </label>
      </div>
      {showPasswordChange && (
        <div className={cl.userDataInputsPassCont}>
          <label style={{ width: 380 }}>
            <MyInputLabel text={t("profile.new_password")} />
            <MyInput
              type={"password"}
              color={"light"}
              value={newPass}
              onChange={(val) => {
                setNewPass(val);
              }}
            />
          </label>
          <label style={{ width: 380 }}>
            <MyInputLabel text={t("profile.repeat_password")} />
            <MyInput
              type={"password"}
              color={"light"}
              value={newPassRepeat}
              onChange={(val) => {
                setNewPassRepeat(val);
              }}
            />
          </label>
        </div>
      )}
      <div className={cl.btnsCont}>
        <PrimaryButton
          isDisabled={!wasChanged}
          text={t("buttons.save_changes")}
          variant={"dark"}
          onClick={handleSaveChanges}
        />
        <PrimaryButton
          variant={showPasswordChange ? "red" : "magenta"}
          text={t(
            showPasswordChange ? "buttons.close" : "profile.change_password"
          )}
          onClick={togglePasswordChange}
        />
      </div>
      {!showPasswordChange && (
        <div className={cl.userDataInputsPassCont}>
          <LabeledSelect
            label={t("profile.translate_to")}
            value={player.targetlanguage}
            options={Object.keys(googleLngList).map((lngCode) => ({
              value: lngCode,
              name: googleLngList[lngCode],
            }))}
            onChange={player.setTargetLanguage}
          />
          <LabeledSelect
            label={t("profile.interface_language")}
            value={i18n.language}
            options={interfaceLangs}
            onChange={appInterface.setLanguage}
          />
        </div>
      )}
    </PageWrapper>
  );
};

export default observer(Profile);
