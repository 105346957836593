import React from "react";
import { useFetching } from "../../../../hooks/useFetching";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../../../UI";
import PodcastService from "../../../../api/PodcastService";
import { YT_URL } from "../../data/constants";
import cl from "./BlacklistPodcastCard.module.css";

const BlacklistPodcastCard = ({ podcastObj, onUnblock }) => {
  const { t } = useTranslation();
  const { name, description, image, customUrl, id } = podcastObj;

  const [unblock, isLoading] = useFetching(async () => {
    const res = await PodcastService.unblacklistPodcast(id);
    if (res.status === 200) {
      onUnblock(id);
    }
  });

  return (
    <div className={cl.podcastCard}>
      <a
        className={cl.imgCont}
        href={`${YT_URL}/@${customUrl}/videos`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className={cl.podcastImg} src={image.md} alt={name} />
      </a>
      <div className={cl.podcastInfo}>
        <a
          href={`${YT_URL}/${customUrl}/videos`}
          target="_blank"
          rel="noopener noreferrer"
          className={cl.title}
        >
          {name}
        </a>
        <p className={cl.description}>{description}</p>
        <PrimaryButton
          style={{ marginLeft: "auto" }}
          variant={"red"}
          text={t("podcasts.unblock")}
          onClick={unblock}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default BlacklistPodcastCard;
