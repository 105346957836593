import React from "react";
import cl from "./PageWrapper.module.css";

function PageWrapper({ children, additionalClass, style, onScroll }) {
  return (
    <div
      className={`${cl.PageWrapper} ${additionalClass || ""}`}
      style={{
        ...style,
      }}
      onScroll={onScroll || null}
    >
      {children}
    </div>
  );
}

export default PageWrapper;
