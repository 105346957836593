import React from "react";
import cl from "./NavigationWrapper.module.css";

const NavigationWrapper = ({ children, style }) => {
  return (
    <div className={cl.navigation} style={style}>
      {children}
    </div>
  );
};

export default NavigationWrapper;
