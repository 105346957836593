import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import FrameLandscape from "../../../../assets/webp/iphone-frame-landscape.webp";
import FramePortrait from "../../../../assets/webp/iphone-frame-portrait.webp";
import cl from "./PhoneFrame.module.css";

const PhoneFrame = ({ orientation, fileName, fileType, isPlay, style }) => {
  const frameSrc = orientation === "landscape" ? FrameLandscape : FramePortrait;

  const videoRef = useRef();
  const frameRef = useRef();

  const [backgroundSize, setBackgroundSize] = useState({});

  const calculateBackgroundSize = () => {
    const { width, height } = frameRef.current.getBoundingClientRect();
    setBackgroundSize({
      width: width * 0.9,
      height: height * 0.95,
    });

    /* 
    When you change orientation in Safari on iOS, it only renders elements after the orientation change animation.
    I added an additional change after 500ms to handle this particular behavior.
    */

    setTimeout(() => {
      const { width, height } = frameRef.current.getBoundingClientRect();
      setBackgroundSize({
        width: width * 0.9,
        height: height * 0.95,
      });
    }, 500);
  };

  useEffect(() => {
    if (fileType !== "video") return;
    isPlay ? videoRef.current?.play() : videoRef.current?.pause();
  }, [isPlay]);

  useEffect(() => {
    if (!frameRef.current || fileType !== "video") return;
    calculateBackgroundSize();
  }, [frameRef]);

  useEffect(() => {
    if (fileType !== "video") return;
    window
      .matchMedia("(orientation: portrait)")
      .addEventListener("change", calculateBackgroundSize, false);
    window.addEventListener("resize", calculateBackgroundSize, false);
    return () => {
      window.removeEventListener("resize", calculateBackgroundSize);
    };
  }, []);

  const renderMedia = () => {
    if (fileType === "image") {
      const imageSrc = require(`../../../../assets/webp/${fileName}`);
      return (
        <img
          className={`${cl.image} ${cl[orientation]}`}
          src={imageSrc}
          alt={"iphone preview"}
        />
      );
    }

    if (fileType === "video") {
      const videoSrc = require("../../../../assets/mp4/landing_demo.mp4");
      return (
        <motion.div
          className={cl.videoCont}
          initial={{
            filter: "brightness(0)",
          }}
          animate={{ filter: "brightness(1)" }}
          transition={{ delay: 1.5, duration: 1 }}
        >
          <video
            className={cl.video}
            ref={videoRef}
            src={videoSrc}
            alt={"iphone preview"}
            autoPlay
            playsInline
            muted
            loop
          />
          <div
            className={cl.background}
            style={{
              width: backgroundSize.width,
              height: backgroundSize.height,
              borderRadius: backgroundSize.width / 10 || 30,
            }}
          />
        </motion.div>
      );
    }
    return null;
  };

  return (
    <>
      <div className={`${cl.container} ${cl[orientation]}`} style={style}>
        <img
          className={`${cl.frame} ${cl[orientation]}`}
          ref={frameRef}
          src={frameSrc}
          alt={"iphone frame"}
        />
        {renderMedia()}
      </div>
    </>
  );
};

export default PhoneFrame;
