import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../hooks/useFetching";
import TrackService from "../../../../api/TrackService";
import { Loader, PrimaryButton } from "../../../../UI";
import MyModal from "../../../../components/UI/MyModal/MyModal";
import cl from "./BlacklistTrackCard.module.css";

const BlacklistTrackCard = ({ trackData, onDelete }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState();
  const { id, title, reason } = trackData;

  const [deleteTrack, deleteLoading] = useFetching(async () => {
    const res = await TrackService.deleteTrack(id);
    processResponse(res);
  });

  const [unblacklistTrack, unbanLoading] = useFetching(async () => {
    const res = await TrackService.unblacklistTrack({ id });
    processResponse(res);
  });

  const processResponse = (res) => {
    if (res.status === 200) {
      setModal();
      if (onDelete) onDelete([id]);
    }
  };

  const handleDeleteTrack = () => {
    setModal("delete");
  };

  const handleUnblacklistTrack = () => {
    setModal("unblacklist");
  };

  return (
    <>
      <MyModal
        title={
          modal === "delete"
            ? t("track_card.confirm_deletion")
            : t("track_card.confirm_unblocking")
        }
        visible={modal}
        setVisible={setModal}
      >
        <p>
          {modal === "delete"
            ? `${t("track_card.are_u_sure")} "${title}"?`
            : `${t("track_card.ask_unblock_track")} "${title}"?`}
        </p>
        <div className={cl.buttonsContainer}>
          {modal === "delete" && (
            <PrimaryButton
              variant={"red"}
              onClick={deleteTrack}
              text={t("buttons.delete_and_blacklist")}
            />
          )}
          <PrimaryButton
            variant={"red"}
            onClick={() => {
              modal === "delete" ? deleteTrack() : unblacklistTrack();
            }}
            text={t("buttons.yes")}
          />
          <PrimaryButton onClick={() => setModal()} text={t("buttons.no")} />
        </div>
      </MyModal>
      <div className={cl.blacklistTrackCard}>
        <div className={cl.imageCont}>
          <img src={`https://i.ytimg.com/vi/${id}/sddefault.jpg`} alt={title} />
        </div>
        {(deleteLoading || unbanLoading) && <Loader />}

        <div>
          <p>{title}</p>
          <p className={cl.reason}>{`(${reason || "Unknown reason"})`}</p>
        </div>
        <div className={cl.buttons}>
          <PrimaryButton
            onClick={handleUnblacklistTrack}
            text={t("track_card.unblock_track")}
          />
          <PrimaryButton
            variant={"red"}
            onClick={handleDeleteTrack}
            text={t("track_card.delete")}
          />
        </div>
      </div>
    </>
  );
};

export default BlacklistTrackCard;
