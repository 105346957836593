import React from "react";
import cl from "./DemoPageLoader.module.css";

const DemoPageLoader = ({ size = 64, color = "#000000" }) => {
  const style = { "--size": `${size}px`, "--color": color };
  return (
    <div className={cl.loader} style={style}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default DemoPageLoader;
