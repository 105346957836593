import axios from "axios";

const URL = "exercises";

export default class ExerciseService {
  static async getExercises({
    offset,
    limit,
    searchString,
    lang,
    type,
    difficulty,
  }) {
    const params = new URLSearchParams({ offset, limit });

    const optional = {
      searchString,
      lang,
      type,
      difficulty,
    };

    Object.keys(optional).forEach((param) => {
      if (
        optional[param] !== undefined &&
        optional[param] !== null &&
        optional[param] !== ""
      ) {
        params.append(param, optional[param]);
      }
    });

    const exercises = await axios.get(`${URL}/exercises?${params.toString()}`);

    return exercises;
  }

  static async getExercise({ id }) {
    const exercise = await axios.get(`${URL}/exercises/${id}`);
    return exercise;
  }

  static async updateExercise({
    id,
    title,
    data,
    description,
    difficulty,
    categoryId,
  }) {
    const body = { id, description };

    const optional = {
      title,
      data,
      difficulty,
      categoryId,
    };

    Object.keys(optional).forEach((param) => {
      if (
        optional[param] !== undefined &&
        optional[param] !== null &&
        optional[param] !== ""
      ) {
        body[param] = optional[param];
      }
    });

    const response = await axios.patch(`${URL}/exercises/${id}`, body);

    return response;
  }

  static async createExercise({
    type,
    topic,
    text,
    setting,
    sentencesAmount,
    difficulty,
    lang,
    categoryId,
    trackId,
    trackInterval,
    mediaType,
  }) {
    const response = await axios.post(`${URL}/exercises/generate`, {
      type,
      topic,
      text,
      setting,
      sentencesAmount,
      difficulty,
      lang,
      categoryId,
      trackId,
      trackInterval,
      mediaType,
    });
    return response;
  }

  static async deleteExercise({ id }) {
    const response = await axios.delete(`${URL}/exercises/${id}`);
    return response;
  }

  static async getCategories({
    searchString,
    withExercises,
    sortColumn,
    sortOrder,
  }) {
    const params = new URLSearchParams();

    const optional = {
      searchString,
      withExercises,
      sortColumn,
      sortOrder,
    };

    Object.keys(optional).forEach((param) => {
      if (
        optional[param] !== undefined &&
        optional[param] !== null &&
        optional[param] !== ""
      ) {
        params.append(param, optional[param]);
      }
    });

    const categories = await axios.get(
      `${URL}/categories?${params.toString()}`
    );

    return categories;
  }

  static async createCategory({ title }) {
    const response = await axios.post(`${URL}/categories`, { title });
    return response;
  }

  static async updateCategory({ id, categoryTitle }) {
    const response = await axios.patch(`${URL}/categories/${id}`, {
      categoryTitle,
    });
    return response;
  }

  static async deleteCategory({ id }) {
    const response = await axios.delete(`${URL}/categories/${id}`);
    return response;
  }

  static async generateText({ topic, genre, lang, difficulty }) {
    const response = await axios.post(`${URL}/exercises/generate-text`, {
      topic,
      genre,
      lang,
      difficulty,
    });

    return response;
  }
}
