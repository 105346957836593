import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useFetching } from "../../../../hooks/useFetching";
import exercisesPlayer from "../../store/exercisesPlayer";
import exercisesStore from "../../store/exercisesStore";
import TrackService from "../../../../api/TrackService";
import { ExercisePlayer, ExercisePlayerControls } from "..";
import MyAdjustableTextarea from "../../../../components/UI/MyAdjustableTextarea/MyAdjustableTextarea";
import { MediaTypes } from "../../data/constants";
import { Loader } from "../../../../UI";
import cl from "./EditMedia.module.css";

const EditMedia = ({ mediaType }) => {
  const { t } = useTranslation();

  const { currentTrack } = exercisesStore;

  const [getTrack, trackLoading] = useFetching(async () => {
    const { data } = await TrackService.getTrackSentences({ id: currentTrack });
    exercisesPlayer.setTrackData(data);
    exercisesStore.setTrackText(
      data?.sentences.map((s) => s.text).join(" ") ?? ""
    );
  });

  useEffect(() => {
    if (exercisesStore.currentTrack && exercisesStore.trackText) return;
    getTrack();
  }, [currentTrack]);

  useEffect(() => {
    renderMedia();
  }, [trackLoading]);

  const renderMedia = () => {
    switch (mediaType) {
      case MediaTypes.Video:
        return (
          <div className={cl.videoContainer} key={currentTrack}>
            <ExercisePlayer isPreview={false} />
            <ExercisePlayerControls mode={"video"} isPreview={false} />
          </div>
        );
      case MediaTypes.Audio:
        return (
          <div className={cl.videoContainer} key={currentTrack}>
            <div className={cl.hideYT}>
              <ExercisePlayer isPreview={false} />
            </div>
            <ExercisePlayerControls mode={"audio"} isPreview={false} />
          </div>
        );
      case MediaTypes.Text:
        return (
          <MyAdjustableTextarea
            placeholder={t("exercises.media_placeholder")}
            value={exercisesStore.trackText}
            onChange={exercisesStore.setTrackText}
          />
        );
      default:
        return <></>;
    }
  };

  return <>{trackLoading ? <Loader /> : renderMedia()}</>;
};

export default observer(EditMedia);
