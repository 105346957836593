import React, { useEffect, useRef, useState } from "react";
import cl from "./ErrorMessage.module.css";

const ErrorMessage = ({ message }) => {
  const timeoutRef = useRef();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (message.length) setVisible(true);
  }, [message]);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (!visible) return;
    timeoutRef.current = setTimeout(() => {
      setVisible(false);
    }, 2000);
  }, [visible]);

  return (
    <div className={`${cl.errorMessage} ${visible ? cl.visible : ""}`}>
      {message}
    </div>
  );
};

export default ErrorMessage;
