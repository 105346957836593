import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetching } from "../../hooks/useFetching";
import { observer } from "mobx-react-lite";
import player from "../../store/player";
import TrackService from "../../api/TrackService";
import { TextDisplay, Translation } from "./components";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import PlayerControls from "../../components/PlayerControls/PlayerControls";
import YoutubePlayer from "../../components/YoutubePlayer/YoutubePlayer";
import { Loader } from "../../UI";
import { ROUTES } from "../../router";
import { parseTrackData } from "../../utils/parseTrackData";
import { LanguageLevels } from "../../data/common";
import cl from "./Player.module.css";

const Player = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { id } = location.state || { id: null };

  const [trackData, setTrackData] = useState({});

  const textRef = useRef(null);
  const timerRef = useRef();

  const [getTrack, isLoaading] = useFetching(async () => {
    const { data } = await TrackService.getTrackSentences({ id });
    setTrackData(data);
    parseTrackData({ ...data });
  });

  useEffect(() => {
    if (!id) navigate(ROUTES.library);
    player.resetStates();
    player.setContentId(id);
    const lastProgress = parseInt(localStorage.getItem("progressTimeOf:" + id));
    if (lastProgress) player.setCurrentTime(lastProgress);
    player.setContentId(id);
    getTrack();
  }, []);

  const setAutoScrollDelay = () => {
    clearTimeout(timerRef.current);
    window.autoScrollDelay = true;
    timerRef.current = setTimeout(() => {
      window.autoScrollDelay = false;
    }, 4000);
  };

  const handleArrowClick = (e) => {
    const dir = e.currentTarget.attributes.getNamedItem("data-direction").value;
    let curSenIndex = 0;
    if (player.currentSentenceObj) {
      player.textArr.forEach((el, i) => {
        if (el.key === player.currentSentenceObj.key) {
          curSenIndex = i;
          return;
        }
      });
    }
    if (!curSenIndex && !dir) return;
    const nextIndex = dir === "prev" ? curSenIndex - 1 : curSenIndex + 1;
    const newSentenceObj = player.textArr[nextIndex];
    if (!newSentenceObj) return;
    clearTimeout(window.falseScrollTimeout);
    window.autoScrollDelay = false;
    player.setCurrentSentenceObj(newSentenceObj);
    player.handleChangeAudioTime(newSentenceObj.offset, true);
  };

  return (
    <PageWrapper>
      {isLoaading ? (
        <Loader style={{ margin: "auto" }} />
      ) : (
        <>
          <div className={cl.horizontalContainer}>
            <p className={cl.infoText}>{trackData?.title}</p>
            <p className={cl.infoText}>{trackData?.lang}</p>
            <p className={cl.infoText}>
              {LanguageLevels[trackData?.difficulty]}
            </p>
          </div>

          <div className={cl.playerContainer}>
            <div className={cl.trackContainer}>
              <YoutubePlayer />
              <PlayerControls handlePrevNextSentenceBtn={handleArrowClick} />
            </div>
            <div className={cl.textContainer}>
              <Translation text={player.textToTranslate} />
              <TextDisplay textRef={textRef} onScroll={setAutoScrollDelay} />
            </div>
          </div>
        </>
      )}
    </PageWrapper>
  );
};

export default observer(Player);
