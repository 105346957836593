import React, { useEffect, useState } from "react";
import { useFetching } from "../../hooks/useFetching";
import UserService from "../../api/UserService";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import { PrimaryButton } from "../../UI";
import MyInputLabel from "../../components/UI/MyInputLabel/MyInputLabel";
import MyInput from "../../components/UI/MyInput/MyInput";
import cl from "./Support.module.css";

const DEFAULT_STATE = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
};

const Support = () => {
  const [userData, setUserData] = useState(DEFAULT_STATE);

  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [wasSent, setWasSent] = useState(false);

  const [sendMessage, _isLoading] = useFetching(async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setWasSent(true);
      setUserData(DEFAULT_STATE);
    }, 800);
  });

  const setName = (name) => {
    setUserData({ ...userData, name });
  };

  const setSurname = (surname) => {
    setUserData({ ...userData, surname });
  };

  const setEmail = (email) => {
    setUserData({ ...userData, email });
  };

  const setPhone = (phone) => {
    setUserData({ ...userData, phone });
  };

  const setSubject = (subject) => {
    setUserData({ ...userData, subject });
  };

  const setMessage = (message) => {
    setUserData({ ...userData, message });
  };

  useEffect(() => {
    setIsEmpty(Object.values(userData).some((v) => !v));
  }, [userData]);

  useEffect(() => {
    if (wasSent) {
      setTimeout(() => {
        setWasSent(false);
      }, 2500);
    }
  }, [wasSent]);

  return (
    <PageWrapper>
      <section className={cl.supportSection}>
        <p className={cl.title}>Contact Us</p>
        <p className={cl.subtitle}> Are you having any trouble? </p>
        <div className={cl.inner}>
          <div className={cl.verticalCont}>
            <label style={{ width: "100%", flexGrow: 1, marginBottom: 20 }}>
              <MyInputLabel text={"First Name"} />
              <MyInput
                color={"light"}
                value={userData.name}
                onChange={setName}
              />
            </label>
            <label style={{ width: "100%", flexGrow: 1, marginBottom: 20 }}>
              <MyInputLabel text={"Last Name"} />
              <MyInput
                color={"light"}
                value={userData.surname}
                onChange={setSurname}
              />
            </label>
          </div>
          <div className={cl.verticalCont}>
            <label style={{ width: "100%", flexGrow: 1, marginBottom: 20 }}>
              <MyInputLabel text={"Email"} />
              <MyInput
                color={"light"}
                value={userData.email}
                onChange={setEmail}
              />
            </label>
            <label style={{ width: "100%", flexGrow: 1, marginBottom: 20 }}>
              <MyInputLabel text={"Phone"} />
              <MyInput
                color={"light"}
                value={userData.phone}
                onChange={setPhone}
              />
            </label>
          </div>
          <label style={{ width: "100%", flexGrow: 1, marginBottom: 20 }}>
            <MyInputLabel text={"Subject"} />
            <MyInput
              color={"light"}
              value={userData.subject}
              onChange={setSubject}
            />
          </label>
          <label style={{ marginBottom: 20 }}>
            <MyInputLabel text={"Message"} />
            <MyInput
              color={"light"}
              value={userData.message}
              onChange={setMessage}
              type={"textarea"}
              style={{
                minHeight: "20vh",
                minWidth: "30vw",
                paddingTop: 10,
                paddingBottom: 10,
                resize: "none",
              }}
            />
          </label>
          <div className={cl.buttonCont}>
            <p className={`${cl.alert} ${wasSent ? cl.success : ""}`}>
              Thank you for contacting us! We've received your message and our
              team is on it.
            </p>
            <PrimaryButton
              text={"Send"}
              variant={"dark"}
              isDisabled={isEmpty}
              isLoading={isLoading}
              onClick={sendMessage}
            />
          </div>
        </div>
      </section>
      <section className={cl.supportSection}>
        <p className={cl.title}>Partnership</p>
        <p className={cl.subtitle}> Looking to partner with us?</p>
        <div className={cl.inner}>
          <p className={cl.subtitle}>
            Mail to
            <a href="mailto:oleg.gribkov@gmail.com"> oleg.gribkov@gmail.com</a>
          </p>
        </div>
      </section>
    </PageWrapper>
  );
};

export default Support;
