import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import player from "../../../../store/player";
import cl from "./Sentence.module.css";

const Sentence = ({ sentence, calculateDotPos }) => {
  const pRef = useRef(null);
  const { active, words } = sentence;
  const [spans, setSpans] = useState([]);

  useEffect(() => {
    if (active === true) {
      calculateDotPos(pRef);
      player.setCurrentSentenceObj(sentence);
    }
  }, [active]);

  useEffect(() => {
    if (player.isPlaying) {
      if (player.currentSentenceObj.key === sentence.key) {
        calculateDotPos(pRef);
      }
    }
  }, [player.isPlaying]);

  useEffect(() => {
    if (!words || !words.length) return;
    const arr = [];
    words.forEach((w) => {
      if (!w.text) {
        return;
      }
      const wBySpaces = w.text.split(" ");
      wBySpaces.forEach((sW) => {
        arr.push({
          offset: w.offset,
          duration: w.duration,
          text: sW,
          speaker: w.speaker,
          active: w.active,
        });
      });
    });
    setSpans(arr);
  }, [player.currentTime]);

  return (
    <div ref={pRef} className={`${cl.sentence} ${active ? cl.active : ""}`}>
      {spans.map((word, i) => {
        const { text, offset } = word;
        const classNames = [cl.span];
        if (word.active === true && active === true) {
          classNames.push(cl.active);
        }
        return (
          <span key={`${text}_${offset}_${i}`} className={classNames.join(" ")}>
            {word.text}
          </span>
        );
      })}
    </div>
  );
};

export default observer(Sentence);
