import axios from "axios";

const URL = "categories";

export default class CategoryService {
  static async getCategories({ lang }) {
    const categories = await axios.get(`${URL}/?lang=${lang}`);
    return categories;
  }

  static async addCategory({ title }) {
    const categories = await axios.post(`${URL}/admin`, {
      title,
      priority: 0,
    });
    return categories;
  }
}
