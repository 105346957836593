import React, { useState, useEffect } from "react";
import cl from "./AdjustableInput.module.css";

const MyAdjustableInput = ({ value, onChange, isCorrect }) => {
  const className =
    isCorrect !== undefined ? (isCorrect ? cl.correct : cl.wrong) : "";
  const [width, setWidth] = useState(0);

  const getWidthOfInput = () => {
    const tempElem = document.createElement("span");
    tempElem.textContent = value;
    tempElem.className = cl.widthCalculator;
    document.body.appendChild(tempElem);
    setWidth(+tempElem.getBoundingClientRect().width + 20);
    document.body.removeChild(tempElem);
  };

  const changeHandler = (e) => {
    getWidthOfInput();
    onChange(e);
  };

  useEffect(() => {
    getWidthOfInput();
  }, [value]);

  return (
    <input
      style={{ width: width + "px" }}
      placeholder="..."
      className={`${cl.keyboardGap} ${className}`}
      type="textarea"
      value={value}
      onChange={changeHandler}
    />
  );
};

export default MyAdjustableInput;
