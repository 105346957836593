import React from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../../../UI";
import { ReactComponent as Arrow } from "../../../../assets/img/arrow_left.svg";

import cl from "./BackButton.module.css";

const BackButton = ({ style, onClick, text, isDisabled }) => {
  const { t } = useTranslation();
  return (
    <PrimaryButton
      style={{ position: "absolute", left: 0, ...style }}
      variant={"dark"}
      onClick={onClick}
      text={t(text)}
      icon={<Arrow className={cl.arrow} />}
      isDisabled={isDisabled}
    />
  );
};

export default BackButton;
