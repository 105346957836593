import React, { useState } from "react";
import cl from "./SummaryPreview.module.css";

const SummaryPreview = ({ exerciseObj, showAnswers }) => {
  const { data } = exerciseObj;

  const [active, setActive] = useState();

  const options = data?.options ?? [];

  return (
    <div className={cl.previewCont}>
      {options.map((o) => (
        <div
          className={`${cl.answer} ${
            showAnswers && o.correct ? cl.correct : ""
          } ${active === o.id ? cl.active : ""}`}
          onClick={() => {
            setActive(active === o.id ? null : o.id);
          }}
          key={o.id}
        >
          {o.text}
        </div>
      ))}
    </div>
  );
};

export default SummaryPreview;
