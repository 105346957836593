import React from "react";
import cl from "./Chip.module.css";

const Chip = React.forwardRef(({ exerciseObj, isActive, onClick }, ref) => {
  const { title } = exerciseObj;

  const getClassName = () => {
    const classNames = [cl.exercise];

    if (isActive) classNames.push(cl.active);

    return classNames.join(" ");
  };

  return (
    <div
      className={getClassName()}
      onClick={onClick}
      ref={isActive ? ref : null}
    >
      <span>{title}</span>
    </div>
  );
});

export default Chip;
