import axios from "axios";

const URL = "languages";

export default class LanguagesService {
  static async saveLanguage({ code, title, ready }) {
    const body = {
      code,
      title,
      ready,
    };
    return axios.post(`${URL}/languages`, body);
  }

  static async getLanguage({ code }) {
    try {
      const response = await axios.get(`${URL}/languages?code=${code}`);
      return response;
    } catch (e) {
      return null;
    }
  }

  static async updateLanguage({ code, title, ready }) {
    try {
      const response = await axios.put(`${URL}/languages`, {
        code,
        title,
        ready,
      });
      return response;
    } catch (e) {
      return null;
    }
  }

  static async deleteLanguage({ code }) {
    return axios.delete(`${URL}/languages?code${code}`);
  }

  static async getAllLanguages({ ready }) {
    const params = new URLSearchParams({});
    if (typeof ready === "boolean") params.append("ready", ready);

    return axios.get(`${URL}/languages/get-list?${params.toString()}`);
  }
}
