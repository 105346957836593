export const AddBatchStates = {
  Added: "added",
  Translated: "translated",
  Error: "error",
  Default: "",
};

export const WordStatuses = {
  ok: "ok",
  invalid: "invalid",
  translationError: "translation failed",
  generationError: "generation failed",
};

export const DictionaryViews = {
  add: "add",
  file: "file",
  queue: "queue",
  words: "words",
};

export const SortTypes = {
  wordAsc: "word_asc",
  wordDesc: "word_desc",
  createdDesc: "createdAt_desc",
  updatedDesc: "updatedAt_desc",
};

export const ChunkSize = 25;

export const WordsPaginationCount = 100;
