import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { InterfaceContext } from "../../../../App";
import dictionaryStore from "../../store/dictionaryStore";
import { useFetching } from "../../../../hooks/useFetching";
import DictionaryService from "../../../../api/DictionaryService";
import DemoPageLoader from "../../../../components/UI/DemoPageLoader/DemoPageLoader";
import {
  LabeledSelect,
  Loader,
  PrimaryButton,
  PrimaryInput,
} from "../../../../UI";
import { WordsPaginationCount, WordStatuses } from "../../data/constants";
import cl from "./QueueList.module.css";
import List from "../../../../components/UI/List/List";

const QueueList = () => {
  const { t } = useTranslation();

  const { appInterface } = useContext(InterfaceContext);
  const { languages } = appInterface;

  const { language } = dictionaryStore;

  const [words, setWords] = useState([]);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(0);
  const [searchString, setSearchString] = useState("");

  const lastScroll = useRef(0);

  const [getList, isLoading] = useFetching(async () => {
    const { data } = await DictionaryService.getQueue({
      limit: WordsPaginationCount,
      offset: page * WordsPaginationCount,
      language,
      searchString,
    });

    if (data.items && data.items.length) {
      const { items, total } = data;
      const newWords = (page > 0 ? [...words, ...items] : items).filter(
        (t, index, self) =>
          !self.slice(0, index).some((item) => item.id === t.id)
      );
      setWords(newWords);
      setTotal(total);
    }
  });

  const [remove, removeLoading] = useFetching(async ({ id }) => {
    await DictionaryService.deleteWord({
      id,
    });
    setWords((prev) => prev.filter((w) => w.id !== id));
    setTotal((prev) => prev - 1);
  });

  const handleListScroll = (e) => {
    const list = e.target;
    if (!isLoading && list.scrollTop > lastScroll.current) {
      if (list.scrollTop > list.scrollHeight - list.clientHeight - 100) {
        setPage((prev) => prev + 1);
      }
    }
    lastScroll.current = list.scrollLeft;
  };

  const clearWords = () => {
    setWords([]);
    setTotal(null);
    setPage(0);
  };

  const handleRefresh = () => {
    clearWords();
    getList();
  };

  const handleSearchStringInput = (value) => {
    clearWords();
    setSearchString(value);
  };

  useEffect(() => {
    getList();
  }, [searchString, page]);

  useEffect(() => {
    clearWords();
    getList();
  }, [language]);

  return (
    <>
      <div className={cl.searchBar}>
        <LabeledSelect
          label={t("dictionary_administration.source_lang")}
          value={language}
          options={languages}
          onChange={dictionaryStore.setLanguage}
        />
        <PrimaryInput
          style={{
            flex: "25%",
            width: 230,
            fontWeight: 400,
          }}
          color={"light"}
          placeholder={t("library.search")}
          value={searchString}
          onChange={handleSearchStringInput}
        />
        <PrimaryButton text={t("buttons.refresh")} onClick={handleRefresh} />
      </div>
      {total && (
        <p className={cl.total}>{`${t(
          "dictionary_administration.queue_total"
        )}: ${total}`}</p>
      )}
      {words.length ? (
        <List
          onScroll={handleListScroll}
          style={{ padding: "10px 15px 0px 10px" }}
        >
          {words.map((wObj) => (
            <div className={cl.wordTab} key={wObj.id}>
              <p className={cl.word}>
                {wObj.word}{" "}
                <span className={cl.wordInfo}>
                  {wObj.partOfSpeech ? `(${wObj.partOfSpeech})` : ""}
                </span>
              </p>
              <div className={cl.buttonContainer}>
                <PrimaryButton
                  style={{
                    padding: "4px 8px",
                    fontSize: 14,
                  }}
                  variant={"red"}
                  text={t("buttons.delete")}
                  onClick={() => remove({ id: wObj.id })}
                  isLoading={removeLoading}
                />
              </div>
              {wObj.status ? (
                <p className={cl.status}>
                  {wObj.status === WordStatuses.ok
                    ? "unexpected error"
                    : wObj.status}
                </p>
              ) : (
                <div className={cl.loaderCont}>
                  {!wObj.status && (
                    <DemoPageLoader size={20} color={"#cbcbcb"} />
                  )}
                </div>
              )}
            </div>
          ))}
        </List>
      ) : isLoading ? (
        <Loader style={{ margin: "auto" }} />
      ) : (
        <p className={cl.emptyAlert}>
          {t("dictionary_administration.empty_queue")}
        </p>
      )}
    </>
  );
};

export default observer(QueueList);
