import { makeAutoObservable } from "mobx";
import { DEFAULT_SEARCH_OPTION } from "../data/constants";

class LibraryStore {
  page = 0;
  tracks = [];
  tracksCount = null;

  category = DEFAULT_SEARCH_OPTION.value;
  difficulty = DEFAULT_SEARCH_OPTION.value;
  language = DEFAULT_SEARCH_OPTION.value;
  searchString = "";
  status = DEFAULT_SEARCH_OPTION.value;
  paginationEnd = false;

  blacklist = false;
  languageOptions = [];
  categoryOptions = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true, deep: true });
  }

  setTracks(tracks) {
    this.tracks = tracks;
  }

  setDifficulty(difficulty) {
    this.difficulty = difficulty;
  }

  setPage(page) {
    this.page = page;
  }

  setNextPage() {
    this.setPage(this.page + 1);
  }

  setTracksCount(tracksCount) {
    this.tracksCount = tracksCount;
  }

  setSearchString(searchString) {
    this.searchString = searchString;
  }

  setCategory(category) {
    this.category = category;
  }

  setLanguage(language) {
    this.language = language;
  }

  setStatus(status) {
    this.status = status;
  }

  setPaginationEnd(paginationEnd) {
    this.paginationEnd = paginationEnd;
  }

  setCategoryOptions(data) {
    const options = data
      .map((cObj) => ({
        value: cObj.id,
        name: cObj.translation || cObj.title,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    this.setCategory(DEFAULT_SEARCH_OPTION.value);
    this.categoryOptions = [DEFAULT_SEARCH_OPTION, ...options];
  }

  addCategoryOption(data) {
    const newCategory = {
      value: data.id,
      name: data.translation || data.title,
    };
    this.categoryOptions = [...this.categoryOptions, newCategory];
  }

  toggleBlacklist() {
    this.setTracks([]);
    this.blacklist = !this.blacklist;
  }

  setLanguageOptions(data) {
    const options = data
      .map((lObj) => ({
        value: lObj.code.toLowerCase(),
        name: lObj.title,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    this.setLanguage(DEFAULT_SEARCH_OPTION.value);
    this.languageOptions = [DEFAULT_SEARCH_OPTION, ...options];
  }

  setTrackData(data) {
    const { items, total } = data;
    if (!items.length) {
      this.setPaginationEnd(true);
    }
    this.setTracksCount(total);
    const tracks = (this.page > 0 ? [...this.tracks, ...items] : items).filter(
      (t, index, self) => !self.slice(0, index).some((item) => item.id === t.id)
    );
    this.setTracks(tracks);
  }

  deleteTrackById(id) {
    const newTracks = this.tracks.filter((t) => !id.includes(t.id));
    this.setTracks(newTracks);
  }

  updateTrack(id, { difficulty, processed, categories, lang }) {
    const newTracks = this.tracks.map((t) =>
      t.id === id
        ? {
            ...t,
            difficulty: difficulty ?? t.difficulty,
            processed: processed ?? t.processed,
            categories: categories ?? t.categories,
            lang: lang ?? t.lang,
          }
        : t
    );
    this.setTracks(newTracks);
  }

  resetSearch() {
    this.setPaginationEnd(false);
    this.setPage(0);
    this.setTracks([]);
  }
}

const libraryStore = new LibraryStore();
export default libraryStore;
