import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import shuffleArray from "../../../../../../utils/shuffleArray";
import cl from "./MultichoicePreview.module.css";

const MultichoicePreview = ({ exerciseObj, showAnswers }) => {
  const { t } = useTranslation();

  const { sentences } = exerciseObj.data;

  const tooltipRef = useRef();

  const [words, setWords] = useState([]);
  const [dropdownAnswers, setDropdownAnswers] = useState([]);
  const [currentDropdown, setCurrentDropdown] = useState(null);

  const renderWord = (w) => {
    if (!w?.options) return w.word;

    const classNames = [cl.dropdown];
    if (currentDropdown === w.id) classNames.push(cl.selected);

    return (
      <span
        key={w.id}
        className={classNames.join(" ")}
        onClick={() => handleWordClick(w.id)}
        ref={currentDropdown === w.id ? tooltipRef : null}
      >
        <span className={cl.dropdownIcon}></span>
        {dropdownAnswers.find((a) => a.word.id === w.id)?.answer}
        <div className={cl.dropdownTooltip}>
          <p className={cl.dropdownText}>{t("exercises.dropdown_title")}</p>
          <div className={cl.optionsCont}>
            {w?.options
              .filter((o) => o.length)
              .map((o, i) => (
                <span key={`${o}${i}`} onClick={() => handleOptionClick(w, o)}>
                  {o}
                </span>
              ))}
          </div>
        </div>
      </span>
    );
  };

  const handleWordClick = (id) => {
    setCurrentDropdown(currentDropdown === id ? null : id);
  };

  const handleOptionClick = (word, option) => {
    const newAnswers = dropdownAnswers.map((a) => {
      if (a.word.id === word.id) {
        return { ...a, answer: option };
      }
      return a;
    });
    setDropdownAnswers(newAnswers);
  };

  const displayAnswers = () => {
    setDropdownAnswers(
      dropdownAnswers.map((a) => ({
        ...a,
        answer: a.word.word,
      }))
    );
  };

  const resetAnswers = () => {
    if (dropdownAnswers.some((w) => w.answer)) {
      setDropdownAnswers(dropdownAnswers.map((w) => ({ ...w, answer: "" })));
    }
  };

  const handleClickOutside = (e) => {
    if (tooltipRef.current && !tooltipRef.current.contains(e.target)) {
      setCurrentDropdown(null);
    }
  };

  useEffect(() => {
    showAnswers ? displayAnswers() : resetAnswers();
  }, [showAnswers]);

  useEffect(() => {
    const flatWords = sentences?.map((s) => s.words).flat() ?? [];
    const dropdownWords = flatWords
      .filter((w) => w?.options)
      .map((w) => ({ word: w, answer: "" }));
    setWords(
      flatWords.map((w) =>
        w?.options ? { ...w, options: shuffleArray(w?.options ?? []) } : w
      )
    );
    setDropdownAnswers(dropdownWords);
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipRef]);

  return (
    words.length !== 0 && (
      <div className={cl.exerciseText}>
        {words.map((w, i) => (
          <span className={cl.wordContainer} key={w.id}>
            {renderWord(w)}
            {w.endChar && <span>{w.endChar}</span>}
          </span>
        ))}
      </div>
    )
  );
};

export default MultichoicePreview;
