import { useState, useCallback, useEffect } from "react";

const useScrollAnimation = (containerRef, isAnimationEnd) => {
  const [isFirstScroll, setIsFirstScroll] = useState(false);

  const handleScroll = useCallback(
    (e) => {
      e.preventDefault();
      const { deltaY } = e;
      const scrollDown = deltaY > 0 ? true : false;
      if (scrollDown) {
        setIsFirstScroll(true);
      }
    },
    [isFirstScroll]
  );

  const handleTouch = useCallback(
    (e) => {
      e.preventDefault();
      const touchStartY = e.touches[0].clientY;
      const touchMove = (e) => {
        const deltaY = touchStartY - e.touches[0].clientY;
        const scrollDown = deltaY > 0 ? true : false;

        if (scrollDown) {
          setIsFirstScroll(true);
        }
      };

      const touchEnd = () => {
        containerRef.current.removeEventListener("touchmove", touchMove);
        containerRef.current.removeEventListener("touchend", touchEnd);
      };

      containerRef.current.addEventListener("touchmove", touchMove);
      containerRef.current.addEventListener("touchend", touchEnd);
    },
    [isFirstScroll]
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container || isFirstScroll) return;

    container.addEventListener("wheel", handleScroll, {
      passive: false,
    });

    container.addEventListener("touchstart", handleTouch, {
      passive: false,
    });

    return () => {
      container.removeEventListener("wheel", handleScroll);
      container.removeEventListener("touchstart", handleTouch);
    };
  }, [containerRef, isFirstScroll, handleScroll, handleTouch]);

  useEffect(() => {
    if (isFirstScroll && isAnimationEnd) {
      setTimeout(() => {
        containerRef.current.removeEventListener("wheel", handleScroll);
      }, 200);
    }
  }, [isFirstScroll, isAnimationEnd]);

  return { isFirstScroll };
};

export default useScrollAnimation;
