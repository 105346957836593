import React from "react";
import { PrimarySelect } from "../";
import cl from "./LabeledSelect.module.css";

const LabeledSelect = ({ value, options, onChange, style, label, height }) => {
  return (
    <div>
      <p className={cl.label}>{label}</p>
      <PrimarySelect
        value={value}
        options={options}
        onChange={onChange}
        style={style}
        height={height}
      />
    </div>
  );
};

export default LabeledSelect;
