import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import exercisesStore from "../../store/exercisesStore";
import { InterfaceContext } from "../../../../App";
import {
  LabeledSelect,
  LabeledToggleButton,
  PrimaryInput,
} from "../../../../UI";
import {
  GrammarExerciseTypes,
  MediaExerciseTypes,
} from "../../data/constants";
import { LanguageLevels } from "../../../../data/common";
import cl from "./SearchBar.module.css";

const SearchBar = () => {
  const { t } = useTranslation();

  const { searchString, lang, type, difficulty } = exercisesStore;

  const { appInterface } = useContext(InterfaceContext);
  const { languages } = appInterface;

  const TypeOptions = [
    { value: "", name: t("podcasts.all") },
    ...Object.values(GrammarExerciseTypes).map((type) => ({
      value: type,
      name: t(`exercises.${type}`),
    })),
    ...Object.values(MediaExerciseTypes).map((type) => ({
      value: type,
      name: t(`exercises.${type}`),
    })),
  ];

  return (
    <div className={cl.searchBarCont}>
      <div className={cl.searchBar}>
        <PrimaryInput
          style={{
            flex: "25%",
            width: 230,
            fontWeight: 400,
          }}
          color={"light"}
          placeholder={t("library.search")}
          value={searchString}
          onChange={exercisesStore.setSearchString}
        />
        <LabeledToggleButton
          label={t("users.level")}
          options={LanguageLevels}
          selectedOption={difficulty}
          setOption={exercisesStore.setDifficulty}
          variant={"round"}
          nullable
        />
        <LabeledSelect
          label={t("dictionary_administration.source_lang")}
          value={lang}
          options={languages}
          onChange={exercisesStore.setLang}
        />
        <LabeledSelect
          label={t("exercises.type")}
          value={type}
          options={TypeOptions}
          onChange={exercisesStore.setType}
        />
      </div>
    </div>
  );
};

export default observer(SearchBar);
