import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimate, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";
import { Chip, PageCont, Typography } from "../../components";
import { anchorWords, chips, supportedLanguages } from "../../data";
import OrangeGradient from "../../../../assets/webp/gradient-orange.webp";
import { ReactComponent as Conversation } from "../../../../assets/svg/conversation.svg";
import cl from "./NeededView.module.css";

const NeededView = ({ setLock }) => {
  const { t, i18n } = useTranslation();
  const translation = anchorWords.find(
    (element) => element.language === i18n.language
  );
  const anchorChip = translation ? translation.word : "hello";

  const [anchoredChips, setAnchoredChips] = useState([]);
  const [hideChips, setHideChips] = useState(false);
  const [animated, setAnimated] = useState(false);

  const anchorChipRef = useRef();

  const [containerRef, animateContainer] = useAnimate();
  const [textRef, animateText] = useAnimate();
  const [chipsRef, animateChips] = useAnimate();
  const [innerRef, animateInner] = useAnimate();

  const isInView = useInView(containerRef);

  const zoomToChip = () => {
    const container = chipsRef.current;
    const anchor = anchorChipRef.current;
    if (!container || !anchor) return;
    const containerRect = container.getBoundingClientRect();
    const anchorRect = anchor.getBoundingClientRect();
    const x =
      (container.offsetWidth - anchor.offsetWidth) / 2 +
      containerRect.x -
      anchorRect.x;
    const y =
      (container.offsetHeight - anchor.offsetHeight) / 2 +
      containerRect.y -
      anchorRect.y;
    animateChips(chipsRef.current, { x, y }, { duration: 0, delay: 0 });
  };

  const runAnimation = () => {
    setHideChips(false);
    animateInner(innerRef.current, { scale: 1 }, { duration: 1, delay: 0 });
    animateChips(chipsRef.current, { x: 0, y: 0 }, { duration: 1, delay: 0 });
    animateChips(
      chipsRef.current,
      { opacity: 0 },
      { duration: 0.5, delay: 1.25 }
    );
    animateText(
      textRef.current,
      { opacity: 1, y: 0 },
      { duration: 1, delay: 1.5 }
    );
  };

  useEffect(() => {
    if (!isInView || animated) return;
    setLock(true);
    setAnimated(true);
    zoomToChip();
    setHideChips(true);
    animateContainer(
      containerRef.current,
      { scale: 1, opacity: 1 },
      { duration: 0.5, delay: 0.5 }
    );
    animateInner(innerRef.current, { scale: 3.5 }, { duration: 0, delay: 0.0 });
    animateChips(chipsRef.current, { opacity: 1 }, { duration: 0, delay: 0 });

    setTimeout(() => {
      runAnimation();
    }, [1000]);

    setTimeout(() => {
      setLock(false);
    }, [3000]);
  }, [isInView]);

  useEffect(() => {
    const anchorIndex = chips.findIndex((chip) => chip.text === anchorChip);
    const middleIndex = Math.floor(chips.length / 2);
    if (anchorIndex !== -1) {
      const newChips = [...chips];
      const anchorChipObject = newChips.splice(anchorIndex, 1)[0];
      newChips.splice(middleIndex, 0, anchorChipObject);
      setAnchoredChips(newChips);
    } else {
      setAnchoredChips(chips);
    }
  }, [chips]);

  return (
    <PageCont color={"black"}>
      <motion.div
        layout
        key="neededContainer"
        initial={{ scale: 1, opacity: 0 }}
        ref={containerRef}
        className={cl.container}
      >
        <motion.div
          layout
          key="neededInner"
          ref={innerRef}
          className={cl.innerContainer}
        >
          <motion.div
            layout
            ref={chipsRef}
            key="neededChips"
            initial={{ opacity: 0 }}
            className={cl.chipsContainer}
          >
            {anchoredChips.map((chip) => (
              <Chip
                key={chip.text}
                text={chip.text}
                size={chip.size}
                ref={chip.text === anchorChip ? anchorChipRef : null}
                className={
                  hideChips && chip.text !== anchorChip ? cl.hidden : ""
                }
              />
            ))}
          </motion.div>
          <motion.div
            layout
            key="neededText"
            ref={textRef}
            initial={{ y: 0, opacity: 0 }}
            className={cl.textContainer}
          >
            <Conversation className={cl.decoration} />
            <Typography variant={"h2"} className={cl.yellowText}>
              {t("new_landing.needed_title")}
            </Typography>
            <div className={cl.languagesContainer}>
              {supportedLanguages.map((l) => (
                <Chip key={l} text={l} size={"small"}></Chip>
              ))}
            </div>
          </motion.div>
        </motion.div>
        <img src={OrangeGradient} className={cl.gradient} alt="" />
      </motion.div>
    </PageCont>
  );
};

export default NeededView;
