import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { InterfaceContext } from "../../../../App";
import exercisesStore from "../../store/exercisesStore";
import exercisesPlayer from "../../store/exercisesPlayer";
import { useFetching } from "../../../../hooks/useFetching";
import ExerciseService from "../../../../api/ExerciseService";
import {
  LabeledToggleButton,
  PrimaryButton,
  ToggleButton,
  Range,
  LabeledSelect,
} from "../../../../UI";
import {
  DefaultMediaExerciseOptions,
  MaximumMediaCharactersAmount,
  MediaExerciseTypes,
  MediaTypes,
  QuestionsRange,
} from "../../data/constants";
import { LanguageLevels } from "../../../../data/common";
import { GenerateText, SelectMedia } from "..";
import cl from "./MediaOptions.module.css";

const TICKS_IN_SECOND = 10000000;

const MediaOptions = ({ options, setOptions }) => {
  const { t } = useTranslation();

  const { appInterface } = useContext(InterfaceContext);
  const { languages } = appInterface;
  const { type, mediaType, sentencesAmount } = options;

  const [errorMessage, setErrorMessage] = useState("");

  const resetState = () => {
    setOptions(DefaultMediaExerciseOptions);
  };

  const getIsDisabled = () => {
    if (!type) return true;
    if (
      mediaType !== MediaTypes.GeneratedText &&
      !exercisesStore.trackText?.length
    )
      return true;
    if (
      mediaType === MediaTypes.GeneratedText &&
      !exercisesStore.generatedText?.length
    )
      return true;
    if (!exercisesStore.difficulty) return true;
    return false;
  };

  const setSentencesAmount = (e) => {
    setOptions({ ...options, sentencesAmount: e.target.value });
  };

  const changeMediaType = (mediaType) => {
    setOptions({ ...options, mediaType });
  };

  const getText = () => {
    return mediaType === MediaTypes.GeneratedText
      ? exercisesStore.generatedText
      : exercisesStore.trackText;
  };

  const isQuiz = () =>
    [MediaExerciseTypes.AdvancedQuiz, MediaExerciseTypes.Quiz].includes(
      options?.type
    );

  const [getChatResponse, isChatLoading, chatError, resetError] = useFetching(
    async () => {
      const { difficulty, lang } = exercisesStore;

      const withMedia = ![MediaTypes.GeneratedText].includes(mediaType);
      const withInterval = ![
        MediaTypes.Text,
        MediaTypes.GeneratedText,
      ].includes(mediaType);

      const { data } = await ExerciseService.createExercise({
        ...options,
        trackId: withMedia ? exercisesStore.currentTrack : null,
        trackInterval: withInterval
          ? exercisesPlayer.currentRange.map((r) => r / TICKS_IN_SECOND)
          : null,
        text: getText(),
        difficulty: LanguageLevels.indexOf(difficulty),
        lang,
      });
      if (data) {
        exercisesStore.addExercise(data);
      }
    }
  );

  const getCurrentCharactersAmount = () => {
    const text =
      mediaType === MediaTypes.GeneratedText
        ? exercisesStore.generatedText
        : exercisesStore.trackText;
    if (!text) return <>{"0"}</>;
    const amount = text.split("").length;
    const className = amount > MaximumMediaCharactersAmount ? cl.limit : cl.ok;
    return <span className={className}>{amount}</span>;
  };

  useEffect(() => {
    exercisesStore.resetTrackParams();
  }, [options.type]);

  useEffect(() => {
    if (isQuiz()) {
      setOptions({ ...options, sentencesAmount: QuestionsRange.Default });
    }
  }, [options.mediaType]);

  useEffect(() => {
    setErrorMessage(chatError?.message);
  }, [chatError]);

  useEffect(() => {
    const range = exercisesPlayer.currentRange;
    if (!range?.length) {
      exercisesStore.setTrackText(
        exercisesPlayer.trackSentences?.map((t) => t.text).join(" ")
      );
      return;
    }

    const segment = exercisesPlayer.trackSentences?.filter(
      (t) => t.offset >= range[0] && t.offset <= range[1]
    );
    exercisesStore.setTrackText(segment?.map((t) => t.text).join(" "));
  }, [exercisesPlayer.currentRange]);

  useEffect(
    () => () => {
      exercisesStore.resetTrackParams();
      exercisesPlayer.resetMediaParams();
    },
    []
  );

  useEffect(() => {
    exercisesPlayer.setMediaRange(exercisesPlayer.currentRange);
  }, [mediaType]);

  return (
    <div className={cl.exerciseOptions}>
      <p className={cl.title}>{t(`exercises.${type}`)}</p>
      <p className={cl.infoText}>{t(`exercises.${type}_desc`)}</p>

      <div
        className={cl.optionsContainer}
        style={{ justifyContent: "space-between" }}
      >
        <LabeledSelect
          label={t("situations.language")}
          value={exercisesStore.lang}
          options={languages}
          onChange={exercisesStore.setLang}
          height={300}
        />
        {isQuiz() && (
          <div className={cl.inputContainer}>
            <p className={cl.label}>{`${t(
              "exercises.questions_amount"
            )} (${sentencesAmount})`}</p>
            <Range
              value={sentencesAmount ?? QuestionsRange.Default}
              min={QuestionsRange.Min}
              step={QuestionsRange.Step}
              max={QuestionsRange.Max}
              onChange={setSentencesAmount}
            />
          </div>
        )}
        <LabeledToggleButton
          label={t("users.level")}
          options={LanguageLevels}
          selectedOption={exercisesStore.difficulty}
          setOption={exercisesStore.setDifficulty}
          variant={"round"}
        />
      </div>

      <ToggleButton
        selectedOption={mediaType}
        options={Object.values(MediaTypes)}
        setOption={changeMediaType}
        withTranslations={"exercises"}
        isGrid
      />

      <GenerateText mediaType={mediaType} />
      <SelectMedia mediaType={mediaType} />

      {errorMessage ? (
        <div className={cl.optionsContainer}>
          <p className={cl.errorMessage}>{errorMessage}</p>
          <PrimaryButton
            text={t("buttons.reset")}
            variant={"red"}
            onClick={resetError}
          />
        </div>
      ) : (
        <>
          <p className={cl.infoText}>
            {`${t("exercises.limit_info")} ${t("exercises.limit_current")}: `}
            {getCurrentCharactersAmount()}
          </p>

          <div className={cl.optionsContainer}>
            <PrimaryButton
              text={t("situations.generate")}
              variant={"magenta"}
              isDisabled={getIsDisabled()}
              onClick={getChatResponse}
              isLoading={isChatLoading}
            />
            <PrimaryButton
              style={{ marginLeft: "auto" }}
              text={t("buttons.close")}
              variant={"red"}
              onClick={resetState}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default observer(MediaOptions);
