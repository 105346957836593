import React, { forwardRef } from "react";
import { motion } from "framer-motion";
import { useHorizontalScroll } from "../../hooks";
import Slide from "../Slide/Slide";
import cl from "./Slider.module.css";

const Slider = forwardRef(
  ({ slides, keyName, initial, style, inView, isCompact }, sliderRef) => {
    const isTouch =
      !!("ontouchstart" in window) || window.navigator.msMaxTouchPoints > 0;

    const [lock, scrollRef] = useHorizontalScroll({
      isAnimate: isTouch && inView,
    });

    return (
      <motion.div
        ref={sliderRef}
        key={keyName}
        initial={initial}
        className={cl.container}
        style={style}
      >
        <div
          className={`${cl.sliderContainer} ${isTouch ? cl.snap : ""} ${
            isCompact ? cl.compact : ""
          }`}
          ref={scrollRef}
          style={{
            overflow: lock ? "hidden" : "scroll hidden",
          }}
        >
          {slides.map((slide) => (
            <Slide key={slide.title} data={slide} />
          ))}
        </div>
      </motion.div>
    );
  }
);

export default Slider;
