import React, { useEffect, useState, createContext } from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/Header/Header";
import AppRouter from "./router/AppRouter";
import Auth from "./store/auth";
import Interface from "./store/interface";
import { observer } from "mobx-react-lite";
import AppWrapper from "./components/UI/AppWrapper/AppWrapper";
import Footer from "./components/Footer";
import { useFetching } from "./hooks/useFetching";
import "./style/App.css";

export const auth = new Auth();
export const AuthContext = createContext({
  auth,
});

export const appInterface = new Interface();
export const InterfaceContext = createContext({
  appInterface,
});

const App = () => {
  const [authWasChecked, setAuthWasChecked] = useState(false);
  const [checkAuth, isLoading, error] = useFetching(async () => {
    await auth.checkAuth();
    setAuthWasChecked(true);
  });

  const createCustomEvent = () => {
    document.addEventListener("click", (e) => {
      document.dispatchEvent(
        new CustomEvent("closeMenus", {
          detail: e.target,
        })
      );
    });
  };

  useEffect(() => {
    checkAuth();
    createCustomEvent();
  }, []);

  useEffect(() => {
    setAuthWasChecked(true);
  }, [error]);

  if (isLoading || !authWasChecked) {
    return <></>;
  }

  return (
    <AuthContext.Provider value={{ auth }}>
      <InterfaceContext.Provider value={{ appInterface }}>
        <BrowserRouter>
          <AppWrapper>
            <Header />
            <AppRouter />
            <Footer />
          </AppWrapper>
        </BrowserRouter>
      </InterfaceContext.Provider>
    </AuthContext.Provider>
  );
};

(function () {
  /**
   * Корректировка округления десятичных дробей.
   *
   * @param {String}  type  Тип корректировки.
   * @param {Number}  value Число.
   * @param {Integer} exp   Показатель степени (десятичный логарифм основания корректировки).
   * @returns {Number} Скорректированное значение.
   */
  function decimalAdjust(type, value, exp) {
    // Если степень не определена, либо равна нулю...
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // Если значение не является числом, либо степень не является целым числом...
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN;
    }
    // Сдвиг разрядов
    value = value.toString().split("e");
    value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
    // Обратный сдвиг
    value = value.toString().split("e");
    return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
  }

  // Десятичное округление к ближайшему
  if (!Math.round10) {
    Math.round10 = function (value, exp) {
      return decimalAdjust("round", value, exp);
    };
  }
  // Десятичное округление вниз
  if (!Math.floor10) {
    Math.floor10 = function (value, exp) {
      return decimalAdjust("floor", value, exp);
    };
  }
  // Десятичное округление вверх
  if (!Math.ceil10) {
    Math.ceil10 = function (value, exp) {
      return decimalAdjust("ceil", value, exp);
    };
  }
})();

export default observer(App);
