import React, { useState, useEffect } from "react";
import { AdjustableInput } from "../../../../../../UI";
import cl from "./ClozePreview.module.css";

const ClozePreview = ({ exerciseObj, showAnswers }) => {
  const { data } = exerciseObj;
  const { sentences } = data;

  const [words, setWords] = useState([]);
  const [keyboardAnswers, setKeyboardAnswers] = useState([]);

  const renderWord = (w) => {
    if (!w.hidden) return w.word;

    return (
      <AdjustableInput
        isCorrect={w.isCorrect}
        value={keyboardAnswers.find((a) => a.word.id === w.id)?.answer}
        onChange={(e) => {
          handleKeyboardInput(w, e.target.value);
        }}
        key={w.id}
      />
    );
  };

  const handleKeyboardInput = (w, input) => {
    const newAnswers = keyboardAnswers.map((a) =>
      a.word.id === w.id ? { ...a, answer: input.trim() } : a
    );
    setKeyboardAnswers(newAnswers);
  };

  const displayAnswers = () => {
    setKeyboardAnswers(
      keyboardAnswers.map((a) => ({
        ...a,
        answer: a.word.word,
      }))
    );
  };

  const resetAnswers = () => {
    if (!words.length) return;
    setKeyboardAnswers(
      keyboardAnswers.map((a) => ({
        ...a,
        answer: "",
      }))
    );
  };

  useEffect(() => {
    const flatWords = sentences?.map((s) => s.words).flat() ?? [];
    const hiddenWords = flatWords.filter((w) => w?.hidden);

    setWords(flatWords);
    setKeyboardAnswers(hiddenWords.map((w) => ({ word: w, answer: "" })));
  }, [exerciseObj]);

  useEffect(() => {
    showAnswers ? displayAnswers() : resetAnswers();
  }, [showAnswers]);

  return (
    words.length !== 0 && (
      <div className={cl.exerciseText}>
        {words.map((w) => (
          <span className={cl.wordContainer} key={w.id}>
            {renderWord(w)}
            {w.endChar && <span>{w.endChar}</span>}
          </span>
        ))}
      </div>
    )
  );
};

export default ClozePreview;
