import React from "react";
import { observer } from "mobx-react-lite";
import situationsStore from "../../store/situationsStore";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../../../UI";
import { URL } from "../../../../api/SituationsService";
import cl from "./SituationCategoryCard.module.css";

const SituationCategoryCard = ({ filtered, category, style }) => {
  const { t } = useTranslation();

  const { picture, title } = category;
  const { situations } = filtered;

  const handleCategoryClick = () => {
    situationsStore.setCurrentCategory(category);
  };

  const handleSituationClick = (situation) => {
    situationsStore.setCurrentSituation(situation);
  };

  return (
    <div className={cl.categoryCard} style={style}>
      <div className={cl.imageCont}>
        <img
          onClick={handleCategoryClick}
          className={cl.categoryImg}
          src={`${URL}${picture}`}
          alt={title}
        />
      </div>
      <div className={cl.infoCont}>
        <div className={cl.horizontalCont}>
          <p className={cl.categoryTitle}>{title}</p>
          <div className={cl.buttonCont}>
            <PrimaryButton
              text={t("buttons.open")}
              onClick={handleCategoryClick}
            />
          </div>
        </div>

        <div className={cl.situationsList}>
          {situations.map((s) => (
            <p
              key={s.id}
              className={cl.situationTitle}
              onClick={() => {
                handleSituationClick(s);
              }}
            >
              {s.title}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(SituationCategoryCard);
