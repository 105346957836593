import React, { useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cl from "./DatePicker.module.css";

const DatePicker = ({ label, value, onChange, ...props }) => {
  useEffect(() => {
    if (props.showTimeSelectOnly) {
      import("../../style/ReactDatepicker.css").catch((_e) => {});
    }
    return () => {
      const styleSheet = document.getElementById(
        "react-datepicker-optional-css"
      );
      if (styleSheet) {
        styleSheet.remove();
      }
    };
  }, []);

  return (
    <div className={cl.datePicker}>
      <p className={cl.label}>{label}</p>
      <ReactDatePicker
        dateFormat="dd.MM.yyyy"
        selected={value}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

export default DatePicker;
