import React from "react";
import { useTranslation } from "react-i18next";
import PhoneFrame from "../PhoneFrame/PhoneFrame";
import Typography from "../Typography/Typography";
import cl from "./Slide.module.css";

const Slide = ({ data, style }) => {
  const { t } = useTranslation();
  const { title, subtitle, fileName } = data;

  return (
    <div className={cl.slide} style={style}>
      <Typography variant={"h4"} color={"#000000"} className={cl.slideTitle}>
        {t(`new_landing.${title}`)}
      </Typography>
      <Typography variant={"h5"} color={"#000000"} className={cl.slideSubtitle}>
        {t(`new_landing.${subtitle}`)}
      </Typography>
      <div className={cl.frameContainer}>
        <PhoneFrame
          orientation={"portrait"}
          fileName={fileName}
          fileType={"image"}
          style={{ width: "100%", maxWidth: "650px" }}
        />
      </div>
    </div>
  );
};

export default Slide;
