import React, { useRef, useState, useEffect } from "react";
import { DndProvider, useDrag, useDrop, useDragLayer } from "react-dnd";
import { getEmptyImage, HTML5Backend } from "react-dnd-html5-backend";
import shuffleArray from "../../../../../../utils/shuffleArray";
import cl from "./SentenceOrderPreview.module.css";

const SentenceOrderPreview = ({ exerciseObj, showAnswers }) => {
  const { data } = exerciseObj;
  const { sentences } = data;
  const [previewSentences, setPreviewSentences] = useState([]);
  const contRef = useRef();

  const moveSentences = (dragIndex, hoverIndex) => {
    const newSentences = [...previewSentences];
    const [removedSentence] = newSentences.splice(dragIndex, 1);
    newSentences.splice(hoverIndex, 0, removedSentence);
    setPreviewSentences(newSentences);
  };

  const displayAnswers = () => {
    setPreviewSentences(sentences);
  };

  const resetAnswers = () => {};

  useEffect(() => {
    if (!sentences || !sentences.length) return;
    let newSentences = shuffleArray(sentences);
    if (sentences.length > 2) {
      while (JSON.stringify(sentences) === JSON.stringify(newSentences)) {
        newSentences = shuffleArray(sentences);
      }
    }
    setPreviewSentences(newSentences);
  }, [sentences]);

  useEffect(() => {
    showAnswers ? displayAnswers() : resetAnswers();
  }, [showAnswers]);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className={cl.answersCont} ref={contRef}>
          <CustomDragLayer
            sentences={previewSentences}
            width={contRef.current?.clientWidth}
          />
          {previewSentences.map((s, idx) => (
            <SentenceCard
              key={s.id}
              id={s.id}
              idx={idx}
              text={s.text}
              onDrag={moveSentences}
              showAnswers={showAnswers}
            />
          ))}
        </div>
      </DndProvider>
    </>
  );
};

export default SentenceOrderPreview;

const CustomDragLayer = ({ sentences, width }) => {
  const { isDragging, currentOffset, item } = useDragLayer((monitor) => {
    return {
      isDragging: monitor.isDragging(),
      currentOffset: monitor.getSourceClientOffset(),
      item: monitor.getItem(),
    };
  });

  return isDragging && currentOffset ? (
    <div
      className={cl.sentenceCont}
      style={{
        transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
        position: "fixed",
        top: 0,
        left: 0,
        pointerEvents: "none",
        maxWidth: width,
        borderRadius: 21,
      }}
    >
      {sentences.find((s) => s.id === item.id).text}
    </div>
  ) : null;
};

const SentenceCard = ({ id, idx, text, onDrag, showAnswers }) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "sentence",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.idx;
      const hoverIndex = idx;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      onDrag(dragIndex, hoverIndex);
      item.idx = hoverIndex;
    },
  });

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: "sentence",
    item: () => {
      return { id, idx };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  useEffect(() => {
    dragPreview(getEmptyImage());
  }, []);

  return (
    <>
      <div
        className={`${cl.sentenceCont} ${showAnswers ? cl.correct : ""}`}
        ref={ref}
        data-handler-id={handlerId}
        style={{ opacity: isDragging ? 0 : 1 }}
      >
        {text}
      </div>
    </>
  );
};
