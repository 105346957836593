import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import exercisesStore from "./store/exercisesStore.js";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import NavBar from "../../components/NavBar/NavBar.jsx";
import { CreateView, ListView } from "./views";
import { ExercisePageViews } from "./data/constants.js";

const Exercises = () => {
  const { t } = useTranslation();
  const { view } = exercisesStore;

  const ExercisesViews = [
    {
      title: t("exercises.create_exercise"),
      value: ExercisePageViews.Create,
    },
    {
      title: t("exercises.exercises_list"),
      value: ExercisePageViews.List,
    },
  ];

  const renderView = () => {
    switch (view) {
      case ExercisePageViews.Create:
        return <CreateView />;
      case ExercisePageViews.Edit:
        return <></>;
      case ExercisePageViews.List:
      default:
        return <ListView />;
    }
  };

  return (
    <PageWrapper>
      <NavBar
        items={ExercisesViews}
        active={view}
        onSelect={exercisesStore.setView}
        withTranslations={"exercises"}
      />
      {renderView()}
    </PageWrapper>
  );
};

export default observer(Exercises);
