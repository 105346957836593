import React, { useEffect, useRef, useState } from "react";
import {
  ChooseView,
  DownloadView,
  NeededView,
  OfferView,
  SliderView,
  StartingView,
} from "./views";

import cl from "./Landing.module.css";

const Landing = () => {
  const [lock, setLock] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const container = ref.current;
    const onWheel = (event) => {
      event.preventDefault();
    };

    if (container) {
      if (lock) {
        container.addEventListener("wheel", onWheel, {
          passive: false,
        });
        container.addEventListener("touchstart", onWheel, {
          passive: false,
        });
      } else {
        container.removeEventListener("wheel", onWheel);
        container.removeEventListener("touchstart", onWheel);
      }
    }

    return () => {
      if (container) {
        container.removeEventListener("wheel", onWheel);
        container.removeEventListener("touchstart", onWheel);
      }
    };
  }, [ref, lock]);

  return (
    <div className={cl.landingCont} ref={ref}>
      <StartingView setLock={setLock} />
      <NeededView setLock={setLock} />
      <SliderView />
      <OfferView setLock={setLock} />
      <ChooseView />
      <DownloadView />
    </div>
  );
};

export default Landing;
