import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFetching } from "../../../../hooks/useFetching";
import TrackService from "../../../../api/TrackService";
import PodcastService from "../../../../api/PodcastService";
import { LabeledInput, PrimaryButton } from "../../../../UI";
import MyModal from "../../../../components/UI/MyModal/MyModal";
import { ROUTES } from "../../../../router";
import cl from "./TrackCardModal.module.css";

const DuplicatedDataErrorCode = "042";

const TrackCardModal = ({ className, trackData, onDelete }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id, podcastId, title, recognized } = trackData;

  const [modal, setModal] = useState(false);
  const [reason, setReason] = useState("");

  const [deleteTrack, deleteLoading] = useFetching(async () => {
    const res = await TrackService.deleteTrack(id);
    processResponse(res);
  });

  const [blacklistTrack, blTrackLoading, blTrackError, resetBlTrackError] =
    useFetching(async () => {
      const res = await TrackService.blacklistTrack({ id, reason });
      processResponse(res);
    });

  const [
    blacklistPodcast,
    blPodcastLoading,
    blPodcastError,
    resetBlPodcastError,
  ] = useFetching(async () => {
    const res = await PodcastService.blacklistPodcast(podcastId);
    processResponse(res);
  });

  const processResponse = (res) => {
    if (res.status === 200) {
      setModal();
      onDelete(id);
    }
  };

  const handlePlayerButton = () => {
    if (recognized !== "true") return;
    navigate(ROUTES.listen, { state: { id } });
  };

  const handleDeleteTrack = (e) => {
    e.stopPropagation();
    setModal("delete");
  };

  const handleBlacklistTrack = (e) => {
    e.stopPropagation();
    setModal("blacklist");
  };

  const confirmDeleteTrack = (isBlacklist) => {
    deleteTrack();
    if (isBlacklist) {
      blacklistPodcast();
    }
  };

  const renderError = () => {
    const error = blPodcastError || blTrackError;
    if (!error || !error.message || error.code !== DuplicatedDataErrorCode)
      return <p className={cl.errorMessage}>{t("errors.unexpected_error")}</p>;
    return <p className={cl.errorMessage}>{t("errors.blacklisted_track")}</p>;
  };

  useEffect(() => {
    resetBlPodcastError();
    resetBlTrackError();
  }, [modal]);

  return (
    <>
      <div className={className}>
        {recognized === "true" && (
          <PrimaryButton
            variant={"dark"}
            text={t("Pony Player")}
            onClick={handlePlayerButton}
          />
        )}
        <PrimaryButton
          variant={"dark"}
          text={t("track_card.block_track")}
          onClick={handleBlacklistTrack}
        />
        <PrimaryButton
          variant={"red"}
          text={t("track_card.delete")}
          onClick={handleDeleteTrack}
        />
      </div>
      <MyModal
        title={
          modal === "delete"
            ? t("track_card.confirm_deletion")
            : t("track_card.confirm_blocking")
        }
        visible={modal}
        setVisible={setModal}
      >
        <div className={cl.modalInner}>
          <p>
            {modal === "delete"
              ? `${t("track_card.are_u_sure")} "${title}"?`
              : `${t("track_card.ask_block_track")} "${title}"?`}
          </p>
          {blTrackError || blPodcastError ? (
            <div className={cl.buttonsContainer}>
              {renderError()}
              <PrimaryButton
                onClick={() => setModal()}
                text={t("buttons.close")}
              />
            </div>
          ) : (
            <>
              {modal === "blacklist" && (
                <LabeledInput
                  value={reason}
                  onChange={setReason}
                  label={t("track_card.reason")}
                />
              )}
              <div className={cl.buttonsContainer}>
                {modal === "delete" && (
                  <PrimaryButton
                    variant={"red"}
                    text={t("buttons.delete_and_blacklist")}
                    onClick={() => confirmDeleteTrack(true)}
                    isLoading={blPodcastLoading}
                  />
                )}
                <>
                  <PrimaryButton
                    variant={"red"}
                    text={t("buttons.yes")}
                    onClick={() => {
                      modal === "delete"
                        ? confirmDeleteTrack(false)
                        : blacklistTrack();
                    }}
                    isLoading={
                      modal === "delete" ? deleteLoading : blTrackLoading
                    }
                  />
                  <PrimaryButton
                    onClick={() => setModal()}
                    text={t("buttons.no")}
                  />
                </>
              </div>
            </>
          )}
        </div>
      </MyModal>
    </>
  );
};

export default TrackCardModal;
