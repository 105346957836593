import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../hooks/useFetching";
import LanguagesService from "../../api/LanguagesService";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import { LanguageCard, NewLanguageCard } from "./components";
import { PrimaryButton } from "../../UI";
import cl from "./Languages.module.css";

const Languages = () => {
  const { t } = useTranslation();

  const [languageList, setLanguageList] = useState([]);
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);

  const [getLanguages, isLoading] = useFetching(async () => {
    const { data } = await LanguagesService.getAllLanguages({});
    setLanguageList(data);
  });

  const handleLangAdd = () => {
    if (addButtonDisabled) return;
    setLanguageList([...languageList, {}]);
  };

  const handleNewLang = (langObj) => {
    setLanguageList([...languageList.filter((l) => l.code), langObj]);
  };

  const removeAddLang = () => {
    setLanguageList([...languageList.filter((l) => l.code)]);
  };

  const renderView = () => {
    return (
      <div className={cl.languagesList}>
        {languageList.map((lang) =>
          lang.code ? (
            <LanguageCard key={lang.code} lang={lang} />
          ) : (
            <NewLanguageCard
              key={"newLang"}
              onSave={handleNewLang}
              onClose={removeAddLang}
            />
          )
        )}
        {!isLoading && !addButtonDisabled && (
          <PrimaryButton
            variant={"green"}
            text={t("buttons.add")}
            onClick={handleLangAdd}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    getLanguages();
  }, []);

  useEffect(() => {
    setAddButtonDisabled(languageList.some((l) => !l.code));
  }, [languageList]);

  return <PageWrapper>{renderView()}</PageWrapper>;
};

export default Languages;
