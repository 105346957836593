import React from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../../../UI";
import cl from "./ConfirmationBar.module.css";

const ConfirmationBar = ({ words, sourceLang, onClick, isLoading }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={cl.infoCont}>
        <span>{`${t("dictionary_administration.words_count")}: ${
          words.length
        }`}</span>
        {sourceLang && (
          <span>{`${t(
            "dictionary_administration.source_lang"
          )}: ${sourceLang.toUpperCase()}`}</span>
        )}
      </div>
      <PrimaryButton
        text={t("buttons.send")}
        color={"dark"}
        isDisabled={!sourceLang}
        isLoading={isLoading}
        onClick={onClick}
      />
    </>
  );
};

export default ConfirmationBar;
