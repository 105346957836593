import React from "react";
import { NavLink } from "react-router-dom";
import cl from "./StyledNavLink.module.css";

const StyledNavLink = ({
  to,
  style,
  children,
  text,
  showDivider,
  showUnderline,
}) => {
  return (
    <>
      <NavLink
        className={({ isActive }) =>
          `${cl.navLink} ${showUnderline ? cl.underline : ""} ${
            isActive ? cl.active : ""
          }`
        }
        to={to}
        style={style}
      >
        {text || children}
      </NavLink>
      {showDivider && <hr className={cl.navLinkDivider} />}
    </>
  );
};

export default StyledNavLink;
