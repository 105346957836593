import React from "react";
import cl from "./NavBar.module.css";

const NavBar = ({ items, active, onSelect }) => {
  return (
    <div className={cl.navBar}>
      {items.map((item) => (
        <div
          className={`${cl.item} ${item.value === active ? cl.active : ""}`}
          key={item.title}
          onClick={() => onSelect(item.value)}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

export default NavBar;
