import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DefaultTitle } from "../data/common";

const usePageTitle = (routes) => {
  const location = useLocation();

  useEffect(() => {
    const currentRoute = routes.find(
      (route) => route.path === location.pathname
    );
    if (currentRoute && currentRoute.title) {
      document.title = currentRoute.title;
    } else {
      document.title = DefaultTitle;
    }
  }, [location, routes]);
};

export default usePageTitle;
