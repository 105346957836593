import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import CategoryService from "../../../../api/CategoryService";
import libraryStore from "../../store/libraryStore";
import { PrimaryButton, PrimaryInput } from "../../../../UI";
import cl from "./TrackCategories.module.css";
import TrackService from "../../../../api/TrackService";

const TrackCategories = ({ id, categories }) => {
  const { t } = useTranslation();

  const [isPopup, setIsPopup] = useState(false);
  const [isInput, setIsInput] = useState();
  const [categoryName, setCategoryName] = useState("");
  const popupRef = useRef();

  const { categoryOptions } = libraryStore;

  const handleAddButton = async () => {
    if (isInput && categoryName) {
      const { data } = await CategoryService.addCategory({
        title: categoryName,
      });
      if (data.id) {
        libraryStore.addCategoryOption(data);
        handleCategoryChange(data.id, false);
      }
      setIsInput(false);
    } else {
      setIsInput(true);
    }
  };

  const handleCategoryChange = async (categoryId, isActive) => {
    if (isActive) {
      await TrackService.deleteCategory({ id, categoryId });
    } else {
      await TrackService.addCategory({ id, categoryId });
    }

    const newCategories = isActive
      ? categories.filter((c) => c !== categoryId)
      : [...categories, categoryId];

    libraryStore.updateTrack(id, { categories: newCategories });
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopup(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  useEffect(() => {
    setIsInput(false);
    setCategoryName("");
  }, [isPopup]);

  if (!categories) return <></>;

  const active = categoryOptions.filter((o) => categories.includes(o.value));

  return (
    <div className={cl.categoriesContainer}>
      <p className={cl.categoriesTitle}>{`${t("podcasts.categories")}: `}</p>
      {active.map((c) => (
        <span className={cl.category} key={c.value}>
          {c.name}
        </span>
      ))}
      <div className={cl.categoryButtonContainer}>
        <PrimaryButton
          text={t("buttons.edit")}
          variant={"red"}
          onClick={() => setIsPopup(true)}
        />
      </div>
      {isPopup && (
        <div className={cl.categoriesList} ref={popupRef}>
          {categoryOptions
            .filter((c) => c.value !== "all")
            .map((c) => (
              <PrimaryButton
                key={c.value}
                text={c.name}
                onClick={() =>
                  handleCategoryChange(c.value, categories.includes(c.value))
                }
                variant={categories.includes(c.value) ? "dark" : "light"}
              />
            ))}
          {isInput && (
            <PrimaryInput value={categoryName} onChange={setCategoryName} placeholder={t('categories.category_name')} />
          )}
          <PrimaryButton
            text={t(isInput ? "buttons.save" : "buttons.add")}
            variant={"green"}
            isDisabled={isInput && !categoryName}
            onClick={handleAddButton}
          />
          <PrimaryButton
            text={t("buttons.close")}
            variant={"red"}
            onClick={() => setIsPopup(false)}
          />
        </div>
      )}
    </div>
  );
};

export default observer(TrackCategories);
