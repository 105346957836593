const ButtonTagName = "BUTTON";

const ServiceStatuses = {
  healthy: "healthy",
  errored: "errored",
};

const DefaultServicesState = [
  { serviceName: "tracks" },
  { serviceName: "dictionary" },
  { serviceName: "users" },
  { serviceName: "translation" },
  { serviceName: "situations" },
];

const GrafanaUrl = 'http://3.77.237.255:3000/login'

export { ButtonTagName, DefaultServicesState, GrafanaUrl, ServiceStatuses };
