import { LanguageLevels as DIFFICULTIES } from "../../../data/common";

const DEFAULT_SEARCH_OPTION = { value: "all", name: "All" };

const TRACKS_PER_PAGE = 50;

const DIFF_OPTIONS = [
  ...DIFFICULTIES.map((el) => ({
    value: el,
    name: el,
  })),
  DEFAULT_SEARCH_OPTION,
];

export { DEFAULT_SEARCH_OPTION, DIFFICULTIES, DIFF_OPTIONS, TRACKS_PER_PAGE };
