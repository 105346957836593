import React, { useEffect } from "react";
import cl from "./MyModal.module.css";
import { ReactComponent as IconClose } from "../../../assets/img/icon-close.svg";

const MyModal = ({
  children,
  visible,
  setVisible,
  desc,
  title,
  wrapTitle,
  style,
}) => {
  const rootClasses = [cl.myModal];

  if (visible) {
    rootClasses.push(cl.active);
    // document.querySelector("body").classList.add("blocked");
  }

  useEffect(() => {
    const activeClass = cl.active;
    const activeModal = document.querySelector("." + activeClass);
    if (activeModal) {
      document.querySelector("body").classList.add("blocked");
    } else {
      document.querySelector("body").classList.remove("blocked");
    }
  }, [visible]);

  function close() {
    if (setVisible) {
      setVisible(false);
    }
  }

  return (
    <div
      className={rootClasses.join(" ")}
      onClick={(e) => {
        e.stopPropagation();
        close();
      }}
    >
      <div
        className={cl.myModalContent}
        onClick={(e) => e.stopPropagation()}
        style={{ ...style }}
      >
        {title && (
          <p
            style={{ whiteSpace: wrapTitle ? "normal" : null }}
            dangerouslySetInnerHTML={{ __html: title }}
            className={cl.modalTitle}
          ></p>
        )}
        {desc && (
          <p style={{ marginBottom: 10, textAlign: "center" }}>{desc}</p>
        )}
        {children}
      </div>
    </div>
  );
};

export default MyModal;
