import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../hooks/useFetching";
import ExerciseService from "../../../../api/ExerciseService";
import exercisesStore from "../../store/exercisesStore";
import exercisesPlayer from "../../store/exercisesPlayer";
import { Loader, PrimaryButton } from "../../../../UI";
import {
  ClozePreview,
  SubstitutionPreview,
  MultichoicePreview,
  QuizPreview,
  SentenceOrderPreview,
  SummaryPreview,
  WordsOrderPreview,
} from "./components";
import {
  GrammarExerciseTypes,
  MediaExerciseTypes,
  MediaTypes,
} from "../../data/constants";
import { LanguageLevels } from "../../../../data/common";
import { ReactComponent as IconClose } from "../../../../assets/img/icon-close.svg";
import { ExercisePlayer, ExercisePlayerControls } from "..";
import TrackService from "../../../../api/TrackService";

import cl from "./Preview.module.css";
const TICKS_IN_SECOND = 10000000;

const Preview = () => {
  const { t } = useTranslation();

  const { currentExercise, showAnswers } = exercisesStore;
  const {
    id,
    type,
    lang,
    difficulty,
    title,
    description,
    mediaType,
    trackId,
    trackInterval,
  } = currentExercise;

  const [exerciseObj, setExerciseObj] = useState({});

  const [getExerciseData, dataLoading] = useFetching(async () => {
    if (!id) return;
    const { data } = await ExerciseService.getExercise({ id });
    if (
      [MediaTypes.Video, MediaTypes.Audio].includes(data.mediaType) &&
      data.trackInterval
    ) {
      await getTrack();
    }
    setExerciseObj(data);
  });

  const [getTrack, trackLoading] = useFetching(async () => {
    const { data } = await TrackService.getTrack(trackId);
    const rangeInTicks = trackInterval.map((t) => t * TICKS_IN_SECOND);

    exercisesPlayer.setTrackData(data);
    exercisesPlayer.setMediaRange(rangeInTicks);
    exercisesPlayer.setCurrentTime(rangeInTicks[0]);
  });

  const getMediaPreview = () => {
    switch (mediaType) {
      case MediaTypes.Video:
        return (
          <div className={cl.videoContainer} key={trackId}>
            <ExercisePlayer isPreview={true} />
            <ExercisePlayerControls mode={"video"} isPreview={true} />
          </div>
        );
      case MediaTypes.Audio:
        return (
          <div className={cl.videoContainer} key={trackId}>
            <div className={cl.hideYT}>
              <ExercisePlayer isPreview={true} />
            </div>
            <ExercisePlayerControls mode={"audio"} isPreview={true} />
          </div>
        );
      case MediaTypes.Text:
      case MediaTypes.GeneratedText:
        return <div>{exerciseObj.text}</div>;
      default:
        return <></>;
    }
  };

  const getPreview = () => {
    switch (type) {
      case GrammarExerciseTypes.Multichoice:
        return (
          <MultichoicePreview
            exerciseObj={exerciseObj}
            showAnswers={showAnswers}
          />
        );
      case GrammarExerciseTypes.Substitution:
        return (
          <SubstitutionPreview
            exerciseObj={exerciseObj}
            showAnswers={showAnswers}
          />
        );
      case GrammarExerciseTypes.Cloze:
        return (
          <ClozePreview exerciseObj={exerciseObj} showAnswers={showAnswers} />
        );
      case MediaExerciseTypes.Quiz:
        return (
          <QuizPreview exerciseObj={exerciseObj} showAnswers={showAnswers} />
        );
      case MediaExerciseTypes.SentencesOrder:
        return (
          <SentenceOrderPreview
            exerciseObj={exerciseObj}
            showAnswers={showAnswers}
          />
        );
      case GrammarExerciseTypes.WordsOrder:
        return (
          <WordsOrderPreview
            exerciseObj={exerciseObj}
            showAnswers={showAnswers}
          />
        );
      case MediaExerciseTypes.Summary:
        return (
          <SummaryPreview exerciseObj={exerciseObj} showAnswers={showAnswers} />
        );
      default:
        return <></>;
    }
  };

  const handleShowAnswers = () => {
    exercisesStore.setShowAnswers(!showAnswers);
  };

  useEffect(() => {
    setExerciseObj({});
    if (!currentExercise.id) return;
    exercisesStore.setShowAnswers(false);
    getExerciseData();
  }, [currentExercise]);

  if (!exerciseObj.id || dataLoading || trackLoading)
    return <Loader style={{ margin: "auto" }} />;

  return (
    <>
      <p className={cl.exerciseTitle}>{title}</p>
      <p className={cl.exerciseDescription}>{description}</p>
      <div className={cl.info}>
        <span> {t(`exercises.${type}`)}</span>
        <span>{"|"}</span>
        <span>{lang?.toUpperCase()}</span>
        <span>{"|"}</span>
        <span>{LanguageLevels[difficulty]}</span>
      </div>
      <div className={cl.previewContainer}>
        {getMediaPreview()}
        {getPreview()}
      </div>
      <div className={cl.buttons}>
        <PrimaryButton
          text={t(showAnswers ? "buttons.reset" : "exercises.show_answers")}
          variant={showAnswers ? "dark" : "light"}
          onClick={handleShowAnswers}
        />
        <PrimaryButton
          text={t("buttons.edit")}
          variant={"green"}
          isDisabled={type === GrammarExerciseTypes.Multichoice}
          onClick={() => exercisesStore.setEditMode(true)}
        />
      </div>
      <PrimaryButton
        onClick={() => exercisesStore.setCurrentExercise({})}
        icon={<IconClose />}
        variant={"light"}
        style={{
          width: 38,
          height: 38,
          minWidth: 38,
          padding: "unset",
          position: "absolute",
          right: 20,
          top: 20,
        }}
      />
    </>
  );
};

export default observer(Preview);
