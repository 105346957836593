import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../../../UI";
import { MediaOptions, Options } from "../../components";
import {
  DefaultExerciseOptions,
  DefaultMediaExerciseOptions,
  GrammarExerciseTypes,
  MediaExerciseTypes,
} from "../../data/constants";
import cl from "./CreateView.module.css";

const CreateView = () => {
  const { t } = useTranslation();
  const [options, setOptions] = useState({
    ...DefaultExerciseOptions,
  });

  const [mediaOptions, setMediaOptions] = useState({
    ...DefaultMediaExerciseOptions,
  });

  const handleOptionsTypeSelect = (type) => {
    setMediaOptions({ ...DefaultMediaExerciseOptions });
    setOptions({ ...options, type });
  };

  const handleMediaOptionsTypeSelect = (type) => {
    setOptions({ ...DefaultExerciseOptions });
    setMediaOptions({ ...options, type });
  };

  const renderOptions = () => {
    if (
      options.type &&
      Object.values(GrammarExerciseTypes).includes(options.type)
    )
      return <Options options={options} setOptions={setOptions} />;
    if (
      mediaOptions.type &&
      Object.values(MediaExerciseTypes).includes(mediaOptions.type)
    )
      return (
        <MediaOptions options={mediaOptions} setOptions={setMediaOptions} />
      );
  };

  useEffect(() => {
    setOptions({ ...DefaultExerciseOptions, type: options.type });
  }, [options.type]);

  useEffect(() => {
    setMediaOptions({
      ...DefaultMediaExerciseOptions,
      type: mediaOptions.type,
    });
  }, [mediaOptions.type]);

  return (
    <div className={cl.horizontalContainer}>
      <div className={cl.list}>
        <p className={cl.title}>{t("exercises.grammar_exercises")}</p>
        <div className={cl.typesContainer}>
          {Object.values(GrammarExerciseTypes).map((type) => (
            <PrimaryButton
              key={type}
              variant={type === options.type ? "dark" : "light"}
              text={t(`exercises.${type}`)}
              onClick={() => handleOptionsTypeSelect(type)}
            />
          ))}
        </div>
        <p className={cl.title}>{t("exercises.media_exercises")}</p>
        <div className={cl.typesContainer}>
          {Object.values(MediaExerciseTypes).map((type) => (
            <PrimaryButton
              key={type}
              variant={type === mediaOptions?.type ? "dark" : "light"}
              text={t(`exercises.${type}`)}
              onClick={() => handleMediaOptionsTypeSelect(type)}
              isDisabled={[MediaExerciseTypes.AdvancedQuiz].includes(type)}
            />
          ))}
        </div>
      </div>
      {renderOptions()}
    </div>
  );
};

export default CreateView;
