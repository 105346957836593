import { makeAutoObservable } from "mobx";
import { ExercisePageViews, ExercisesPaginationCount } from "../data/constants";

class ExercisesStore {
  view = ExercisePageViews.Create;

  page = 0;
  exercises = [];
  currentExercise = {};
  currentTrack = null;
  exercisesCount = null;

  searchString = "";
  lang = JSON.parse(localStorage.getItem("exercisesDefaultLanguage")) || "en";
  type = "";
  difficulty =
    JSON.parse(localStorage.getItem("exercisesDefaultDifficulty")) || "";

  editMode = false;
  showAnswers = false;
  paginationEnd = false;
  disableAutoScroll =
    JSON.parse(localStorage.getItem("exercisesDisableAutoScroll")) || false;

  trackText = null;
  generatedText = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true, deep: true });
  }

  setView(view) {
    this.view = view;
  }

  setPage(page) {
    this.page = page;
  }

  setExercises(exercises) {
    this.exercises = exercises;
  }

  setCurrentExercise(currentExercise) {
    this.currentExercise = currentExercise;
  }

  setCurrentTrack(currentTrack) {
    this.currentTrack = currentTrack;
  }

  setExercisesCount(exercisesCount) {
    this.exercisesCount = exercisesCount;
  }

  setSearchString(searchString) {
    this.searchString = searchString;
  }

  setLang(lang) {
    this.lang = lang;
    localStorage.setItem("exercisesDefaultLanguage", JSON.stringify(lang));
  }

  setType(type) {
    this.type = type;
  }

  setDifficulty(difficulty) {
    this.difficulty = difficulty;
    localStorage.setItem(
      "exercisesDefaultDifficulty",
      JSON.stringify(difficulty)
    );
  }

  setPaginationEnd(paginationEnd) {
    this.paginationEnd = paginationEnd;
  }

  setDisableAutoScroll(disableAutoScroll) {
    this.disableAutoScroll = disableAutoScroll;
    localStorage.setItem(
      "exercisesDisableAutoScroll",
      JSON.stringify(disableAutoScroll)
    );
  }

  setEditMode(editMode) {
    this.editMode = editMode;
  }

  setShowAnswers(showAnswers) {
    this.showAnswers = showAnswers
  }

  setExercisesData(data) {
    const { items, total } = data;
    if (!items.length || items.length < ExercisesPaginationCount) {
      this.setPaginationEnd(true);
    }
    this.setExercisesCount(total);
    const exercises = (
      this.page > 0 ? [...this.exercises, ...items] : items
    ).filter(
      (t, index, self) => !self.slice(0, index).some((item) => item.id === t.id)
    );
    this.setExercises(exercises);
  }

  setGeneratedText(generatedText) {
    this.generatedText = generatedText;
  }

  setTrackText(trackText) {
    this.trackText = trackText;
  }

  resetSearch() {
    this.setPage(0);
    this.setPaginationEnd(false);
    this.setExercises([]);
    this.setCurrentExercise({});
  }

  resetFilter() {
    this.setDifficulty("");
    this.setSearchString("");
    this.setType("");
  }

  updateCurrentExercise({ title, description, difficulty }) {
    if (!this.currentExercise || !this.currentExercise.id) return;
    if (title !== undefined)
      this.setCurrentExercise({ ...this.currentExercise, title });
    if (description !== undefined)
      this.setCurrentExercise({ ...this.currentExercise, description });
    if (difficulty !== undefined)
      this.setCurrentExercise({ ...this.currentExercise, difficulty });
  }

  addExercise(exerciseObj) {
    this.setView(ExercisePageViews.List);
    setTimeout(() => {
      this.setCurrentExercise({ ...exerciseObj });
      this.setEditMode(true);
    }, 500);
  }

  updateExercise(exerciseObj) {
    this.setCurrentExercise({ ...exerciseObj });
    this.setExercises(
      this.exercises.map((e) => (e.id === exerciseObj.id ? exerciseObj : e))
    );
    this.setEditMode(false);
  }

  deleteExercise(id) {
    this.setExercises(this.exercises.filter((e) => e.id !== id));
    this.setExercisesCount(this.exercisesCount - 1);
    this.setCurrentExercise({});
    this.setEditMode(false);
  }

  resetTrackParams() {
    this.setCurrentTrack();
    this.setTrackText(null);
    this.setGeneratedText(null);
  }
}

const exercisesStore = new ExercisesStore();
export default exercisesStore;
