import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import dictionaryStore from "../../store/dictionaryStore";
import { useFetching } from "../../../../hooks/useFetching";
import DictionaryService from "../../../../api/DictionaryService";
import { WordStatuses } from "../../data/constants";
import { InteractiveInput, PrimaryButton } from "../../../../UI";
import { ReactComponent as IconClose } from "../../../../assets/img/icon-close.svg";
import { ReactComponent as IconSound } from "../../../../assets/img/icon-sound_alt.svg";
import cl from "./WordCard.module.css";

const WordCard = () => {
  const { t } = useTranslation();

  const { active } = dictionaryStore;
  const [wordObj, setWordObj] = useState({});
  const [audio] = useState(new Audio());
  const [editMode, setEditMode] = useState(false);

  const [getWord, isLoading, isError, resetError] = useFetching(async () => {
    if (!active || !active.id) return;
    setEditMode(false);
    resetError();

    const { data } = await DictionaryService.getWord({ id: active.id });
    setWordObj(data);
  });

  const [regenerate, regenerateLoading] = useFetching(async () => {
    if (!active || !active.id) return;

    const res = await DictionaryService.regenerateWord({
      word: active.word,
      sourceLang: active.language,
    });

    if (res) {
      clearActiveWord();
    }
  });

  const [remove, removeLoading] = useFetching(async () => {
    if (!active || !active.id) return;

    const res = await DictionaryService.deleteWord({
      id: active.id,
    });

    if (res) {
      dictionaryStore.deleteWord(active.id);
      clearActiveWord();
    }
  });

  const [validate, validateLoading] = useFetching(async () => {
    if (!active || !active.id) return;

    const status =
      active.status === WordStatuses.ok
        ? WordStatuses.invalid
        : WordStatuses.ok;

    const res = await DictionaryService.validateWord({
      id: active.id,
      status,
    });

    if (res) {
      dictionaryStore.changeWord({ ...wordObj, status });
    }
  });

  const [update, updateLoading] = useFetching(async () => {
    if (!wordObj || !wordObj.id) return;

    const res = await DictionaryService.updateWord({
      ...wordObj,
      synonyms: wordObj.synonyms.filter((s) => s),
      forms: wordObj.forms.filter((f) => f),
    });

    if (res) {
      setEditMode(false);
      dictionaryStore.changeWord(wordObj);
    }
  });

  const [unmark, unMarkLoading] = useFetching(async () => {
    if (!wordObj || !wordObj.id) return;
    const res = await DictionaryService.markWords({
      ids: [wordObj.id],
      status: false,
    });
    if (res) {
      dictionaryStore.changeWord({ ...wordObj, checked: false });
    }
  });

  const toggleEditMode = () => {
    if (!editMode) {
      setEditMode(true);
    } else {
      setEditMode(false);
      getWord();
    }
  };

  const clearActiveWord = () => {
    dictionaryStore.setActive({});
  };

  const addSynonym = () => {
    setWordObj((prev) => ({
      ...prev,
      synonyms: [...prev.synonyms, ""],
    }));
  };

  const changeSynonym = (newSynonym, idx) => {
    setWordObj((prev) => ({
      ...prev,
      synonyms: prev.synonyms.map((s, i) => (i === idx ? newSynonym : s)),
    }));
  };

  const removeSynonym = (synonym) => {
    setWordObj((prev) => ({
      ...prev,
      synonyms: prev.synonyms.filter((s) => s !== synonym),
    }));
  };

  const addForm = () => {
    setWordObj((prev) => ({
      ...prev,
      forms: [...prev.forms, ""],
    }));
  };

  const changeForm = (newForm, idx) => {
    setWordObj((prev) => ({
      ...prev,
      forms: prev.forms.map((s, i) => (i === idx ? newForm : s)),
    }));
  };

  const removeForm = (form) => {
    setWordObj((prev) => ({
      ...prev,
      forms: prev.forms.filter((s) => s !== form),
    }));
  };

  const handleAudioClick = (path) => {
    audio.pause();
    audio.src = path;
    setTimeout(() => {
      audio.play();
    });
  };

  const renderFormsBlock = () => {
    if (!editMode && (!forms || forms.length === 0)) return <></>;

    return (
      <div className={cl.infoCont}>
        <p className={cl.infoLabel}>{t("demo_page.forms")}</p>
        {editMode ? (
          <div className={cl.horizontalCont}>
            {forms.map((form, idx) => (
              <InteractiveInput
                key={`synonym_${idx}`}
                value={form}
                onChange={(val) => changeForm(val, idx)}
                onDelete={() => removeForm(form)}
              />
            ))}
            <div className={cl.addButton} onClick={addForm}>
              +
            </div>
          </div>
        ) : (
          <p className={cl.infoText}>{forms.join(", ")}</p>
        )}
      </div>
    );
  };

  const renderSynonymsBlock = () => {
    if (!editMode && (!synonyms || synonyms.length === 0)) return <></>;

    return (
      <div className={cl.infoCont}>
        <p className={cl.infoLabel}>{t("demo_page.synonyms")}</p>
        {editMode ? (
          <div className={cl.horizontalCont}>
            {synonyms.map((synonym, idx) => (
              <InteractiveInput
                key={`synonym_${idx}`}
                value={synonym}
                onChange={(val) => changeSynonym(val, idx)}
                onDelete={() => removeSynonym(synonym)}
              />
            ))}
            <div className={cl.addButton} onClick={addSynonym}>
              +
            </div>
          </div>
        ) : (
          <p className={cl.infoText}>{synonyms.join(", ")}</p>
        )}
      </div>
    );
  };

  useEffect(() => {
    getWord();
    if (active) {
      audio.pause();
      audio.src = active.voicePath ?? "";
    } else {
      audio.src = "";
    }
  }, [active]);

  const {
    article,
    checked,
    examples,
    forms,
    language,
    pronunciation,
    synonyms,
    status,
    word,
  } = wordObj;

  if (!active || !active.id)
    return (
      <p className={cl.emptyAlert}>
        {t("dictionary_administration.select_alert")}
      </p>
    );

  if (isError)
    return (
      <p className={cl.emptyAlert}>
        {`${t("dictionary_administration.empty_word")} (${active.word})`}
      </p>
    );

  return (
    <div className={cl.wordCard}>
      <div className={cl.wordText}>
        {language === "de" && article}
        {word && <span>{word}</span>}
        {wordObj?.partOfSpeech ? `(${wordObj.partOfSpeech})` : ""}
      </div>
      {wordObj.id && wordObj.status !== WordStatuses.ok && (
        <p className={cl.errorStatus}>{`${t("library.status")}: ${
          wordObj.status
        }`}</p>
      )}

      {!isLoading && (
        <>
          {pronunciation && <p className={cl.wordText}>{`${pronunciation}`}</p>}
          {renderFormsBlock()}
          {renderSynonymsBlock()}
          {examples && examples.length !== 0 && (
            <div className={cl.infoCont}>
              <p className={cl.infoLabel}>{t("demo_page.phrases")}</p>
              {examples.map((ex) => (
                <div className={cl.exampleCont} key={ex.example}>
                  <p className={cl.exampleText}>{ex.example}</p>
                  {!editMode && (
                    <PrimaryButton
                      onClick={() => handleAudioClick(ex.voicePath)}
                      icon={<IconSound />}
                      variant={"dark"}
                      style={{
                        width: 24,
                        height: 24,
                        minWidth: 24,
                        padding: "unset",
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          <div className={cl.buttons}>
            {editMode ? (
              <>
                <PrimaryButton
                  variant={"light"}
                  text={t("buttons.close")}
                  onClick={toggleEditMode}
                />
                <PrimaryButton
                  isDisabled={
                    !wordObj.forms?.length || !wordObj.synonyms?.length
                  }
                  variant={"green"}
                  text={t("buttons.save")}
                  onClick={update}
                  isLoading={updateLoading}
                />
              </>
            ) : (
              <>
                <PrimaryButton
                  variant={"light"}
                  text={t("dictionary_administration.regenerate")}
                  onClick={regenerate}
                  isLoading={regenerateLoading}
                />
                {![WordStatuses.invalid, WordStatuses.generationError].includes(
                  status
                ) && (
                  <PrimaryButton
                    variant={"light"}
                    text={t("buttons.edit")}
                    onClick={toggleEditMode}
                  />
                )}
                {checked && (
                  <PrimaryButton
                    variant={"light"}
                    text={t("dictionary_administration.unmark")}
                    onClick={unmark}
                    isLoading={unMarkLoading}
                  />
                )}
                <PrimaryButton
                  variant={"light"}
                  text={t(
                    status === WordStatuses.invalid
                      ? "dictionary_administration.make_valid"
                      : "dictionary_administration.make_invalid"
                  )}
                  onClick={validate}
                  isLoading={validateLoading}
                />
                <PrimaryButton
                  variant={"red"}
                  text={t("buttons.delete")}
                  onClick={remove}
                  isLoading={removeLoading}
                />
              </>
            )}
          </div>
        </>
      )}
      {active?.voicePath && (
        <PrimaryButton
          onClick={() => handleAudioClick(active.voicePath)}
          icon={<IconSound />}
          variant={"dark"}
          style={{
            width: 38,
            height: 38,
            minWidth: 38,
            padding: "unset",
            position: "absolute",
            right: 70,
            top: 20,
          }}
        />
      )}

      <PrimaryButton
        onClick={clearActiveWord}
        icon={<IconClose />}
        variant={"light"}
        style={{
          width: 38,
          height: 38,
          minWidth: 38,
          padding: "unset",
          position: "absolute",
          right: 20,
          top: 20,
        }}
      />
    </div>
  );
};

export default observer(WordCard);
