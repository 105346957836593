import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import exercisesStore from "../../store/exercisesStore";
import { useFetching } from "../../../../hooks/useFetching";
import ExerciseService from "../../../../api/ExerciseService";
import {
  SubstitutionPreview,
  MultichoicePreview,
  ClozePreview,
  SentenceOrderPreview,
  QuizPreview,
  WordsOrderPreview,
} from "../Preview/components";
import { InterfaceContext } from "../../../../App";
import {
  LabeledInput,
  LabeledToggleButton,
  Range,
  PrimaryButton,
  LabeledSelect,
} from "../../../../UI";
import {
  DefaultExerciseOptions,
  GrammarExerciseTypes,
  MediaExerciseTypes,
  SentencesRange,
} from "../../data/constants";
import { LanguageLevels } from "../../../../data/common";
import cl from "./Options.module.css";

const Options = ({ options, setOptions }) => {
  const { t } = useTranslation();
  const { appInterface } = useContext(InterfaceContext);
  const { languages } = appInterface;

  const [exerciseObj, setExerciseObj] = useState();
  const [preview, setPreview] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { type, topic, setting, sentencesAmount } = options;
  const setTopic = (topic) => setOptions({ ...options, topic });
  const setSetting = (setting) => setOptions({ ...options, setting });
  const setSentencesAmount = (e) => {
    setOptions({ ...options, sentencesAmount: e.target.value });
  };

  const resetState = () => {
    setOptions(DefaultExerciseOptions);
  };

  const [getExample, exampleLoading] = useFetching(async () => {
    const { data: listData } = await ExerciseService.getExercises({
      offset: 0,
      limit: 1,
      type,
    });
    if (listData && listData.items && listData.items) {
      const { data: exerciseData } = await ExerciseService.getExercise({
        id: listData.items[0].id,
      });
      setExerciseObj(exerciseData);
    }
  });

  const [getChatResponse, isChatLoading, chatError, resetError] = useFetching(
    async () => {
      const { difficulty, lang } = exercisesStore;
      const { data } = await ExerciseService.createExercise({
        ...options,
        difficulty: LanguageLevels.indexOf(difficulty),
        lang,
      });
      if (data) {
        exercisesStore.addExercise(data);
      }
    }
  );

  const getPreview = () => {
    switch (type) {
      case GrammarExerciseTypes.Multichoice:
        return <MultichoicePreview exerciseObj={exerciseObj} />;
      case GrammarExerciseTypes.Substitution:
        return <SubstitutionPreview exerciseObj={exerciseObj} />;
      case GrammarExerciseTypes.Cloze:
        return <ClozePreview exerciseObj={exerciseObj} />;
      case MediaExerciseTypes.Quiz:
        return <QuizPreview exerciseObj={exerciseObj} />;
      case MediaExerciseTypes.SentencesOrder:
        return <SentenceOrderPreview exerciseObj={exerciseObj} />;
      case GrammarExerciseTypes.WordsOrder:
        return <WordsOrderPreview exerciseObj={exerciseObj} />;
      default:
        return <></>;
    }
  };

  const getIsDisabled = () => {
    if (!type) return true;
    if (!topic) return true;
    if (!exercisesStore.difficulty) return true;
    return false;
  };

  useEffect(() => {
    setExerciseObj();
    getExample();
    setPreview(false);
  }, [options.type]);

  useEffect(() => {
    setErrorMessage(chatError?.message);
  }, [chatError]);

  return (
    <div className={cl.exerciseOptions}>
      <p className={cl.title}>{t(`exercises.${type}`)}</p>
      <p className={cl.infoText}>{t(`exercises.${type}_desc`)}</p>

      <div
        className={cl.optionsContainer}
        style={{ justifyContent: "space-between" }}
      >
        <LabeledSelect
          label={t("situations.language")}
          value={exercisesStore.lang}
          options={languages}
          onChange={exercisesStore.setLang}
          height={300}
        />
        <div className={cl.inputContainer}>
          <p className={cl.label}>{`${t(
            "exercises.sentences_amount"
          )} (${sentencesAmount})`}</p>
          <Range
            value={sentencesAmount}
            min={SentencesRange.Min}
            step={SentencesRange.Step}
            max={SentencesRange.Max}
            onChange={setSentencesAmount}
          />
        </div>
        <LabeledToggleButton
          label={t("users.level")}
          options={LanguageLevels}
          selectedOption={exercisesStore.difficulty}
          setOption={exercisesStore.setDifficulty}
          variant={"round"}
        />
      </div>

      <LabeledInput
        label={t("exercises.theme")}
        value={topic}
        onChange={setTopic}
        placeholder={"verbs"}
      />
      <LabeledInput
        label={t("exercises.setting")}
        value={setting}
        onChange={setSetting}
        placeholder={"food store"}
      />

      {errorMessage ? (
        <div className={cl.optionsContainer}>
          <p className={cl.errorMessage}>{errorMessage}</p>
          <PrimaryButton
            text={t("buttons.reset")}
            variant={"red"}
            onClick={resetError}
          />
        </div>
      ) : (
        <div className={cl.optionsContainer}>
          <PrimaryButton
            text={t("situations.generate")}
            variant={"magenta"}
            isDisabled={getIsDisabled()}
            onClick={getChatResponse}
            isLoading={isChatLoading}
          />
          <PrimaryButton
            variant={"dark"}
            text={t("demo_page.example")}
            onClick={() => setPreview(!preview)}
            isLoading={exampleLoading}
          />
          <PrimaryButton
            style={{ marginLeft: "auto" }}
            text={t("buttons.close")}
            variant={"red"}
            onClick={resetState}
          />
        </div>
      )}
      {exerciseObj?.id && preview && (
        <div className={cl.preview}>{getPreview()}</div>
      )}
    </div>
  );
};

export default observer(Options);
