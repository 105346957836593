import React from "react";
import { ReactComponent as Logo } from "../../../../assets/svg/pony-logo.svg";
import cl from "./PonyLogo.module.css";

const PonyLogo = ({ size }) => {
  return (
    <div className={`${cl.ponyLogo} ${cl[size]}`}>
      <div className={cl.ponyLogoInner}>
        <Logo className={cl.svg} />
      </div>
    </div>
  );
};

export default PonyLogo;
