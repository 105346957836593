import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useFetching } from "../../hooks/useFetching";
import libraryStore from "./store/libraryStore";
import CategoryService from "../../api/CategoryService";
import TrackService from "../../api/TrackService";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import { SearchBar, TrackList } from "./components";
import {
  DEFAULT_SEARCH_OPTION,
  DIFFICULTIES,
  TRACKS_PER_PAGE,
} from "./data/constants";
import { useTranslation } from "react-i18next";

const Library = () => {
  const { i18n } = useTranslation();
  const {
    blacklist,
    category,
    difficulty,
    language,
    page,
    searchString,
    status,
  } = libraryStore;

  const [isLoading, setIsLoading] = useState(false);

  const timeoutRef = useRef();
  const requestRef = useRef(null);

  const getData = () => {
    blacklist ? getBlacklist() : getTracks();
  };

  const getDataWithDelay = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      blacklist ? getBlacklist() : getTracks();
      timeoutRef.current = null;
    }, 100);
  };

  const [getTracks, tracksLoading] = useFetching(async () => {
    if (requestRef.current) return;
    requestRef.current = true;
    const params = {
      offset: page * TRACKS_PER_PAGE,
      limit: TRACKS_PER_PAGE,
      lang: language !== DEFAULT_SEARCH_OPTION.value ? language : null,
      categories: category !== DEFAULT_SEARCH_OPTION.value ? [category] : null,
      difficulty:
        difficulty !== DEFAULT_SEARCH_OPTION.value
          ? DIFFICULTIES.indexOf(difficulty)
          : null,
      searchString,
      recognized:
        status === "recognized"
          ? true
          : status === "unrecognized"
          ? false
          : null,
      processed:
        status === "processed" ? true : status === "unprocessed" ? false : null,
    };
    const { data } = await TrackService.getTracks(params);
    libraryStore.setTrackData(data);
    requestRef.current = false;
  });

  const [getCategories, categoriesLoading] = useFetching(async () => {
    const { data } = await CategoryService.getCategories({
      lang: i18n.language,
    });
    libraryStore.setCategoryOptions(data);
  });

  const [getBlacklist, blacklistLoading] = useFetching(async () => {
    const params = {
      offset: page * TRACKS_PER_PAGE,
      limit: TRACKS_PER_PAGE,
      searchString,
    };
    const { data } = await TrackService.getBlacklist(params);
    libraryStore.setTrackData(data);
  });

  const [refresh, refreshLoading] = useFetching(async () => {
    await TrackService.refresh();
    libraryStore.resetSearch();
    getTracks();
  });

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    libraryStore.setPage(0);
    getData();
  }, [category, difficulty, language, status]);

  useEffect(() => {
    if (requestRef.current) return;
    libraryStore.resetSearch();
    getDataWithDelay();
  }, [searchString, requestRef]);

  useEffect(() => {
    libraryStore.resetSearch();
    setTimeout(() => {
      blacklist ? getBlacklist() : getTracks();
    });
  }, [blacklist]);

  useEffect(() => {
    getCategories();
  }, [i18n.language]);

  useEffect(() => {
    setIsLoading(
      tracksLoading || blacklistLoading || refreshLoading || categoriesLoading
    );
  }, [tracksLoading, blacklistLoading, refreshLoading, categoriesLoading]);

  useEffect(() => {
    return () => {
      libraryStore.resetSearch();
    };
  }, []);

  return (
    <PageWrapper>
      <SearchBar onRefresh={refresh} />
      <TrackList isLoading={isLoading} />
    </PageWrapper>
  );
};

export default observer(Library);
