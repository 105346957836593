import React from "react";
import cl from "./Range.module.css";

const Range = ({ value, min, step, max, onChange, style }) => {
  return (
    <div className={cl.rangeContainer} style={style}>
      <span className={cl.label}>{min.toString()}</span>
      <input
        className={cl.range}
        type="range"
        value={value}
        onChange={onChange}
        min={min}
        step={step}
        max={max}
      />
      <span className={cl.label}>{max.toString()}</span>
    </div>
  );
};

export default Range;
