import React, { useEffect, useRef, useState } from "react";
import { useFetching } from "../../../../hooks/useFetching";
import LanguagesService from "../../../../api/LanguagesService";
import { PrimaryButton, Switch } from "../../../../UI";
import MyInput from "../../../../components/UI/MyInput/MyInput";
import cl from "./NewLanguageCard.module.css";
import { useTranslation } from "react-i18next";

const INPUT_STYLE = {
  width: "20%",
  padding: "0 5px",
  paddingLeft: 10,
  borderRadius: 32,
  height: 30,
  minHeight: 30,
  flex: "none",
  fontWeight: 400,
  border: "1px solid #74748035",
};

const NewLanguageCard = ({ onSave, onClose }) => {
  const { t } = useTranslation();

  const cardRef = useRef();

  const [code, setCode] = useState("");
  const [title, setTitle] = useState("");

  const [saveLang, saveLoading, error] = useFetching(async () => {
    const { data } = await LanguagesService.saveLanguage({
      code,
      title,
      ready: false,
    });
    if (data.code) {
      onSave(data);
    }
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        onClose(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cardRef]);

  return (
    <div className={`${cl.newLangCard} ${error ? cl.error : ""}`} ref={cardRef}>
      <MyInput
        style={INPUT_STYLE}
        placeholder={"de"}
        value={code}
        onChange={setCode}
      />
      <MyInput
        style={{ ...INPUT_STYLE, width: "40%" }}
        placeholder={"Deutsch"}
        value={title}
        onChange={setTitle}
      />
      <PrimaryButton
        style={{ marginLeft: "auto" }}
        text={t("buttons.save")}
        onClick={saveLang}
        variant={"green"}
        isLoading={saveLoading}
        isDisabled={!code || !title}
      />
    </div>
  );
};

export default NewLanguageCard;
