import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import MyModal from "../UI/MyModal/MyModal";
import MyButton from "../UI/MyButton/MyButton";
import MyInputLabel from "../UI/MyInputLabel/MyInputLabel";
import MyInput from "../UI/MyInput/MyInput";
import UserService from "../../api/UserService";
import Loader from "../UI/Loader/Loader";
import { useFetching } from "../../hooks/useFetching";

const SupportModal = ({ showSupportModal, setShowSupportModal }) => {
  const { t } = useTranslation();
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [problemDescription, setProblemDescription] = useState("");
  const [notEmpty, setNotEmpty] = useState(false);
  const [complete, setComplete] = useState(false);

  const [sendMessage, isLoading, error, resetError] = useFetching(
    async (params) => {
      const res = await UserService.support(params);
      const data = res.data;
      if (data.status === "success") {
        setShowSupportModal(false);
        setComplete(true);
      }
    }
  );

  useEffect(() => {
    if (subject && problemDescription && email) {
      setNotEmpty(true);
    } else {
      setNotEmpty(false);
    }
  }, [subject, problemDescription, email]);

  function handleClickButton() {
    const params = {
      subject: subject,
      problemDescription: problemDescription,
      email: email,
    };
    sendMessage(params);
    setSubject("");
    setEmail("");
    setProblemDescription("");
  }

  return (
    <>
      <MyModal visible={complete} title={t("success.support")}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <MyButton
            color={"dark"}
            onClick={() => {
              setComplete(false);
            }}
          >
            {t("buttons.ok")}
          </MyButton>
        </div>
      </MyModal>

      <MyModal visible={showSupportModal} title={t("footer.support")}>
        {isLoading && <Loader />}
        <div>
          <label style={{ width: "100%", flexGrow: 1, marginBottom: 20 }}>
            <MyInputLabel text={t("email")} />
            <MyInput
              color={"light"}
              value={email}
              onChange={(val) => {
                setEmail(val);
              }}
            />
          </label>
          <label style={{ width: "100%", flexGrow: 1, marginBottom: 20 }}>
            <MyInputLabel text={t("footer.subject")} />
            <MyInput
              color={"light"}
              value={subject}
              onChange={(val) => {
                setSubject(val);
              }}
            />
          </label>
          <label style={{ marginBottom: 20 }}>
            <MyInputLabel text={t("footer.description")} />
            <MyInput
              color={"light"}
              value={problemDescription}
              onChange={setProblemDescription}
              type={"textarea"}
              style={{
                minHeight: "20vh",
                minWidth: "30vw",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            />
          </label>
        </div>
        <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
          <MyButton
            color={"white"}
            onClick={() => {
              setShowSupportModal(false);
            }}
          >
            {t("buttons.back")}
          </MyButton>
          <MyButton
            color={"dark"}
            isDisabled={!notEmpty}
            onClick={() => {
              handleClickButton();
            }}
          >
            {t("buttons.send")}
          </MyButton>
        </div>
      </MyModal>
    </>
  );
};

export default SupportModal;
