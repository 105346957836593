export const ExercisesPaginationCount = 100;

export const MediaTypes = {
  Video: "video_type",
  Audio: "audio_type",
  Text: "text_type",
  GeneratedText: "generated_type",
};

export const GrammarExerciseTypes = {
  Substitution: "substitution",
  Multichoice: "multichoice",
  Cloze: "cloze",
  WordsOrder: "words_order",
};

export const MediaExerciseTypes = {
  SentencesOrder: "sentence_order",
  Quiz: "quiz",
  AdvancedQuiz: "advanced_quiz",
  Summary: "summary",
};

export const TextGenres = {
  Text: "text",
  Story: "fictional story",
  Article: "descriptive article",
  Newspaper: "newspaper article",
  Argumentative: "argumentative article",
  Blog: "blog post",
  Review: "review",
  Letter: "formal letter",
  InformalLetter: "informal letter",
};

export const SentencesRange = {
  Min: 3,
  Step: 3,
  Max: 18,
  Default: "6",
};

export const QuestionsRange = {
  Min: 1,
  Step: 1,
  Max: 10,
  Default: "5",
};

export const DefaultExerciseOptions = {
  type: "",
  topic: "",
  setting: "",
  sentencesAmount: SentencesRange.Default,
};

export const DefaultMediaExerciseOptions = {
  type: "",
  topic: "",
  mediaType: MediaTypes.Video,
};

export const ExercisePageViews = {
  Create: "create_exercise",
  List: "exercises_list",
};

export const MaximumMediaCharactersAmount = 3000;
