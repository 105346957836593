import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import player from "../../store/player";
import cl from "./AudioPlayer.module.css";
const TICKS_IN_SECOND = 10000000;

const AudioPlayer = ({ small }) => {
  const ref = useRef(null);

  useEffect(() => {
    player.setPlayerRef(ref.current);
    ref.current.currentTime = player.currentTime / TICKS_IN_SECOND;
    return () => {
      player.setIsPlaying(false);
    };
  }, []);

  function handleOnProgress(e) {
    const time = e.target.currentTime;
    if (
      player.isRecognizing &&
      player.maxTime &&
      time * TICKS_IN_SECOND > player.maxTime
    ) {
      player.handleChangeAudioTime(player.maxTime, true);
      player.playerRef.pause();
      return false;
    } else {
      player.handleChangeAudioTime(time);
    }
  }

  return (
    <>
      <audio
        ref={ref}
        src={player.fileSrc}
        width="100%"
        height="50px"
        className={cl.AudioPlayer}
        style={{ flex: "0 0 auto" }}
        onTimeUpdate={(e) => handleOnProgress(e)}
        onPlay={() => player.setIsPlaying(true)}
        onPause={() => player.setIsPlaying(false)}
        controls={true}
      />
      {small && (
        <img
          src={player.image}
          alt={player.contentTitle}
          style={{
            width: 176,
            height: 108,
          }}
        />
      )}
    </>
  );
};

export default observer(AudioPlayer);
