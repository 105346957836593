import React from "react";
import cl from "./FileUploader.module.css";
import { useTranslation } from "react-i18next";
import MyModal from "../MyModal/MyModal";

const FileUploader = ({ mode, visible, setVisible, inputKey, onSave }) => {
  const { t } = useTranslation();

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      onSave(e.dataTransfer.files[0]);
      setVisible(false);
    }
  };

  const handleFileSelect = (e) => {
    if (e.target.files[0]) {
      onSave(e.target.files[0]);
      setVisible(false);
    }
  };

  const getModalTitle = () => {
    switch (mode) {
      case "audio":
        return t("exercises.file_modal_title");
      case "text":
        return t("dictionary_administration.upload_file");
      case "image":
        return t("courses.file_modal_title");
      default:
        return "";
    }
  };

  const getModalSubtitle = () => {
    switch (mode) {
      case "audio":
        return t("exercises.file_modal_subtitle");
      case "text":
        return t("dictionary_administration.upload_file_subtitle");
      case "image":
        return t("courses.file_modal_subtitle");
      default:
        return "";
    }
  };

  const getAccept = () => {
    if (mode !== "text") return `${mode}/*`;
    return "text/plain";
  };

  return (
    <MyModal
      visible={visible}
      setVisible={setVisible}
      style={{ padding: 0, borderRadius: 10 }}
    >
      {visible && (
        <div
          className={cl.fileModal}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <p className={cl.uploadTitle}>{getModalTitle()}</p>
          <p className={cl.uploadSubtitle}>{getModalSubtitle()}</p>
          <span className={cl.uploadIcon} />
          <div className={cl.inputCont}>
            <p className={cl.uploadSubtitle}>
              {t("exercises.file_modal_button_text")}
            </p>
            <label className={cl.customUploadButton}>
              {t("exercises.upload_button")}
              <input
                type="file"
                accept={getAccept()}
                key={inputKey}
                onChange={handleFileSelect}
              />
            </label>
          </div>
        </div>
      )}
    </MyModal>
  );
};

export default FileUploader;
