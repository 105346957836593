import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { motion, useAnimate } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useScrollAnimation } from "../../hooks";
import {
  DownloadButton,
  PageCont,
  PhoneFrame,
  PonyLogo,
  Typography,
} from "../../components";
import ytLogo from "../../../../assets/webp/yt-logo.webp";
import cl from "./StartingView.module.css";

const StartingView = ({ setLock }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const containerRef = useRef();
  const [isAnimationEnd, setIsAnimationEnd] = useState(false);

  const [textRef, animateText] = useAnimate();
  const [phoneRef, animatePhone] = useAnimate();

  const [isPlay, setIsPlay] = useState(false);

  const { isFirstScroll } = useScrollAnimation(containerRef, isAnimationEnd);

  useEffect(() => {
    if (isFirstScroll) {
      setIsPlay(true);
      setLock(true);
      animateText(
        textRef.current,
        { top: "-10%", opacity: 0 },
        { duration: 1.5 }
      );

      animatePhone(phoneRef.current, { bottom: 0, y: 0 }, { duration: 1.5 });

      setTimeout(() => {
        setIsAnimationEnd(true);
        setLock(false);
      }, 1500);
    }
  }, [isFirstScroll]);

  return (
    <PageCont color={"white"} style={{ overflow: "hidden scroll" }}>
      <motion.div
        layout
        key="startingCont"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 1.5 }}
        className={cl.container}
        ref={containerRef}
      >
        <motion.div
          key="startigTitle"
          ref={textRef}
          initial={{ top: "50%", y: "-50%", height: "70%" }}
          className={cl.titleContainer}
        >
          <div className={cl.logoCont}>
            <PonyLogo size={"responsive"} />
          </div>
          <Typography
            variant={"h3"}
            color={"#000000"}
            style={{ width: "fit-content", fontWeight: 400 }}
          >
            {t("new_landing.starting_title")}
          </Typography>
          <div className={cl.ytLogo}>
            <img src={ytLogo} alt="YouTube logo" />
          </div>
        </motion.div>
        <motion.div
          key="phone"
          ref={phoneRef}
          initial={{ bottom: "0", y: isMobile ? "80%" : "100%" }}
          className={cl.phoneContainer}
        >
          <PhoneFrame
            orientation={"portrait"}
            fileName={"landing_demo.mp4"}
            fileType={"video"}
            style={{ height: isMobile ? "80%" : "100%" }}
            isPlay={isPlay}
          />
        </motion.div>
      </motion.div>
    </PageCont>
  );
};

export default StartingView;
