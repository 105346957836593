import React, { useRef } from "react";
import { Loader } from "../../../UI";
import cl from "./List.module.css";

const List = ({ children, style, onScroll, isLoading }) => {
  const listRef = useRef(null);

  const handleScroll = (e) => {
    if (onScroll) onScroll(e);
  };

  return (
    <>
      <div
        onScroll={handleScroll}
        ref={listRef}
        className={cl.List}
        style={style}
      >
        {children}
      </div>
      {isLoading && (
        <div className={cl.loaderOverlay}>
          <div className={cl.overlayInner}>
            <Loader />
          </div>
        </div>
      )}
    </>
  );
};

export default List;
