import React, { useContext } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { observer } from "mobx-react-lite";
import usePageTitle from "../hooks/usePageTitle";
import { ADMIN_ROUTES, PUBLIC_ROUTES, ROUTES } from ".";
import { AuthContext } from "../App";

const AppRouter = () => {
  const { auth } = useContext(AuthContext);

  const isGuest = auth.role === "guest";
  const routes = isGuest ? PUBLIC_ROUTES : ADMIN_ROUTES;

  usePageTitle(routes);

  return (
    <Routes>
      {routes.map((route) => (
        <Route
          path={route.path}
          exact={route.exact}
          element={route.component}
          key={route.path}
        />
      ))}
      <Route
        path="*"
        element={
          <Navigate to={isGuest ? ROUTES.default : ROUTES.library} replace />
        }
      />
    </Routes>
  );
};

export default observer(AppRouter);
