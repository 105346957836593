export default function getTopCenterAndOffset(elem) {
  if (!elem) return {};
  if (!elem.getClientRects().length) {
    return { top: 0, left: 0 };
  }
  let rect = elem.getBoundingClientRect();
  let win = elem.ownerDocument.defaultView;
  return {
    top: rect.top + win.pageYOffset,
    height: rect.height,
    left: rect.left + win.pageXOffset,
    right: rect.left + rect.width,
    width: rect.width,
    center: rect.left + rect.width / 2 + win.pageXOffset,
  };
}
