import { makeAutoObservable } from "mobx";
import { DEFAULT_SEARCH_OPTION } from "../data/constants";

class PodcastsStore {
  page = 0;
  podcasts = [];
  podcastsCount = null;

  difficulty = DEFAULT_SEARCH_OPTION.value;
  language = DEFAULT_SEARCH_OPTION.value;
  level = DEFAULT_SEARCH_OPTION.value;
  promoted = null;
  searchString = "";
  status = DEFAULT_SEARCH_OPTION.value;
  blacklist = false;
  paginationEnd = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true, deep: true });
  }

  setPodcasts(podcasts) {
    this.podcasts = podcasts;
  }

  setDifficulty(difficulty) {
    this.difficulty = difficulty;
  }

  setPage(page) {
    this.page = page;
  }

  setNextPage() {
    this.setPage(this.page + 1);
  }

  setPodcastsCount(podcastsCount) {
    this.podcastsCount = podcastsCount;
  }

  setSearchString(searchString) {
    this.searchString = searchString;
  }

  setLanguage(language) {
    this.language = language;
  }

  setLevel(level) {
    this.level = level;
  }

  setStatus(status) {
    this.status = status;
  }

  setPaginationEnd(paginationEnd) {
    this.paginationEnd = paginationEnd;
  }

  setPodcastsData(data) {
    const { items, total } = data;
    this.setPodcastsCount(total);
    if (!items.length) {
      this.setPaginationEnd(true);
    }
    const podcasts = (
      this.page > 0 ? [...this.podcasts, ...items] : items
    ).filter(
      (p, index, self) => !self.slice(0, index).some((item) => item.id === p.id)
    );
    this.setPodcasts(podcasts);
  }

  setBlacklist(blacklist) {
    this.blacklist = blacklist;
  }

  toggleBlacklist() {
    this.setPodcasts([]);
    this.blacklist = !this.blacklist;
  }

  togglePromoted() {
    this.promoted = !this.promoted;
  }

  deletePodcastById(id) {
    this.setPodcasts(this.podcasts.filter((p) => p.id !== id));
  }

  resetSearch() {
    this.setPaginationEnd(false);
    this.setPage(0);
    this.setPodcasts([]);
  }
}

const podcastsStore = new PodcastsStore();
export default podcastsStore;
