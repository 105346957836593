import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InterfaceContext } from "../../../../App";
import { useFetching } from "../../../../hooks/useFetching";
import NotifyService from "../../../../api/NotifyService";
import TranslationService from "../../../../api/TranslationService";
import { DatePicker, PrimaryButton, PrimaryInput } from "../../../../UI";
import { Localizations } from "..";
import { ReactComponent as IconClose } from "../../../../assets/img/icon-close.svg";
import { dateToTime, getTimeWithTimezone, timeToDate } from "../../helpers";
import { blankNotifyId } from "../../data";
import cl from "./NotifyCard.module.css";

const NotifyCard = ({ notifyObj, onChange, onClose, onDelete, onSave }) => {
  const { t } = useTranslation();

  const { appInterface } = useContext(InterfaceContext);
  const { languages } = appInterface;

  const { time, title, text } = notifyObj;

  const [editedNotify, setEditedNotify] = useState(notifyObj);
  const [editMode, setEditMode] = useState(false);

  const [updateNotify, updateLoading] = useFetching(async () => {
    if (!notifyObj || !notifyObj.id) return;
    const res =
      notifyObj.id === blankNotifyId
        ? await NotifyService.createNotification(editedNotify)
        : await NotifyService.updateNotification(notifyObj.id, editedNotify);
    if (res.data) {
      notifyObj.id === blankNotifyId ? onSave(res.data) : onChange(res.data);
      setEditMode(false);
    }
  });

  const [deleteNotify, deleteLoading] = useFetching(async () => {
    if (!notifyObj || !notifyObj.id) return;
    const res = await NotifyService.deleteNotification(notifyObj.id);
    if (res) {
      onDelete(notifyObj.id);
    }
  });

  const [getLocalizations, localizationLoading] = useFetching(async () => {
    const localizations = [];
    for (const language of languages) {
      const { data: titleData } = await TranslationService.translateSentence({
        text: editedNotify.title,
        targetLang: language.value,
        sourceLang: "en",
      });
      const translatedTitle = titleData.targetText;
      const { data: textData } = await TranslationService.translateSentence({
        text: editedNotify.text,
        targetLang: language.value,
        sourceLang: "en",
      });
      const translatedText = textData.targetText;
      localizations.push({
        lang: language.value,
        title: translatedTitle,
        text: translatedText,
      });
      changeLocalizations(localizations);
    }
  });

  const changeText = (text) => {
    setEditedNotify({ ...editedNotify, text });
  };

  const changeTitle = (title) => {
    setEditedNotify({ ...editedNotify, title });
  };

  const changeTime = (date) => {
    const time = dateToTime(date);
    setEditedNotify({ ...editedNotify, time });
  };

  const changeLocalizations = (localizations) => {
    setEditedNotify({ ...editedNotify, localizations });
  };

  const isSaveDisabled = () => {
    return (
      !editedNotify.title ||
      !editedNotify.text ||
      !editedNotify.time ||
      !editedNotify.localizations?.length ||
      localizationLoading
    );
  };

  const renderTranslateButton = () => {
    if (!editedNotify.localizations?.length) return true;
    if (editedNotify.title !== notifyObj.title) return true;
    if (editedNotify.text !== notifyObj.text) return true;
    return false;
  };

  const toggleEditMode = () => {
    if (editMode && notifyObj.id === blankNotifyId) onClose();
    setEditMode(!editMode);
  };

  useEffect(() => {
    setEditMode(false);
    setEditedNotify(notifyObj);
    if (notifyObj.id === blankNotifyId) setEditMode(true);
  }, [notifyObj]);

  return (
    <div className={cl.notifyCard}>
      <div className={cl.section}>
        <span className={cl.label}>{t("notify.title")}</span>
        {editMode ? (
          <PrimaryInput
            value={editedNotify.title}
            placeholder={t("notify.title")}
            onChange={changeTitle}
          />
        ) : (
          <span className={cl.text}>{title}</span>
        )}
      </div>
      <div className={cl.section}>
        <span className={cl.label}>{t("notify.text")}</span>
        {editMode ? (
          <PrimaryInput
            value={editedNotify.text}
            placeholder={t("notify.text")}
            onChange={changeText}
          />
        ) : (
          <span className={cl.text}>{text}</span>
        )}
      </div>
      <div className={cl.section}>
        <span className={cl.label}>{t("notify.time")}</span>
        {editMode ? (
          <DatePicker
            value={timeToDate(editedNotify.time)}
            dateFormat="HH:mm"
            onChange={changeTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption={t("notify.time")}
            timeFormat="HH:mm"
          />
        ) : (
          <span className={cl.text}>{getTimeWithTimezone(time)}</span>
        )}
      </div>
      {renderTranslateButton() && (
        <div className={cl.descriptionContainer}>
          <p className={cl.description}>{t("notify.description_1")}</p>
          <p className={cl.description}>{t("notify.description_2")}</p>
        </div>
      )}
      {editMode ? (
        <div className={cl.buttonsCont}>
          <PrimaryButton
            variant={"green"}
            text={t("buttons.save")}
            onClick={updateNotify}
            isLoading={updateLoading}
            isDisabled={isSaveDisabled()}
          />
          {renderTranslateButton() && (
            <PrimaryButton
              variant={"magenta"}
              isDisabled={!editedNotify.title || !editedNotify.text}
              text={t("notify.add_translations")}
              onClick={getLocalizations}
              isLoading={localizationLoading}
            />
          )}
          <PrimaryButton
            variant={"light"}
            text={t("buttons.close")}
            onClick={toggleEditMode}
          />
          <Localizations
            languages={languages}
            localizations={editedNotify.localizations}
            setLocalizations={changeLocalizations}
            onSave={updateNotify}
            isLoading={localizationLoading}
          />
        </div>
      ) : (
        <div className={cl.buttonsCont}>
          <PrimaryButton
            variant={"dark"}
            text={t("buttons.edit")}
            onClick={toggleEditMode}
          />
          <PrimaryButton
            variant={"red"}
            text={t("buttons.delete")}
            onClick={deleteNotify}
            isLoading={deleteLoading}
          />
        </div>
      )}
      <PrimaryButton
        onClick={onClose}
        icon={<IconClose />}
        variant={"light"}
        style={{
          width: 38,
          height: 38,
          minWidth: 38,
          padding: "unset",
          position: "absolute",
          right: 10,
          top: 10,
        }}
      />
    </div>
  );
};

export default NotifyCard;
