import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { LabeledInput, LabeledSelect, PrimaryButton } from "../../../../UI";
import MyAdjustableTextarea from "../../../../components/UI/MyAdjustableTextarea/MyAdjustableTextarea";
import { useFetching } from "../../../../hooks/useFetching";
import ExerciseService from "../../../../api/ExerciseService";
import exercisesStore from "../../store/exercisesStore";
import { MediaTypes, TextGenres } from "../../data/constants";
import { LanguageLevels } from "../../../../data/common";
import cl from "./GenerateText.module.css";

const GenerateText = ({ mediaType }) => {
  const { t } = useTranslation();

  const { lang, difficulty } = exercisesStore;

  const [textParams, setTextParams] = useState({
    topic: "",
    genre: TextGenres.Text,
  });

  const [generateText, generateLoading] = useFetching(async () => {
    const { data } = await ExerciseService.generateText({
      lang,
      difficulty: LanguageLevels.indexOf(difficulty),
      topic: textParams.topic,
      genre: textParams.genre,
    });

    exercisesStore.setGeneratedText(data.text);
  });

  const isGenerateTextDisabled = () => {
    if (!textParams.topic) return true;
    if (!lang) return true;
    if (!difficulty) return true;
    return false;
  };

  useEffect(() => {
    if (exercisesStore.generatedText === null) {
      setTextParams({
        topic: "",
        genre: TextGenres.Text,
      });
    }
  }, [exercisesStore.generatedText]);

  if (mediaType !== MediaTypes.GeneratedText) return <></>;

  return exercisesStore.generatedText !== null ? (
    <>
      <PrimaryButton
        text={t("exercises.delete_text")}
        variant={"red"}
        style={{ marginLeft: "auto" }}
        onClick={() => exercisesStore.setGeneratedText(null)}
      />
      <MyAdjustableTextarea
        placeholder={t("exercises.media_placeholder")}
        value={exercisesStore.generatedText}
        onChange={exercisesStore.setGeneratedText}
      />
    </>
  ) : (
    <div className={cl.generateText}>
      <LabeledInput
        label={t("exercises.topic")}
        value={textParams.topic}
        onChange={(topic) => setTextParams({ ...textParams, topic })}
      />
      <div className={cl.horizontalContainer}>
        <LabeledSelect
          label={t("exercises.genre")}
          value={textParams.genre}
          options={Object.values(TextGenres).map((g) => ({
            value: g,
            name: t(`exercises.${g}`),
          }))}
          onChange={(genre) => setTextParams({ ...textParams, genre })}
          height={140}
          style={{ width: "100%" }}
        />
        <PrimaryButton
          text={t("exercises.generate_text")}
          variant={"magenta"}
          onClick={generateText}
          isLoading={generateLoading}
          style={{ marginLeft: "auto" }}
          isDisabled={isGenerateTextDisabled()}
        />
      </div>
    </div>
  );
};

export default observer(GenerateText);
