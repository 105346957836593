import React, { forwardRef } from "react";
import cl from "./Chip.module.css";

const Chip = forwardRef(({ size, text, className }, ref) => {
  return (
    <div
      className={`${cl.chip} ${cl[size]} ${className ? className : ""}`}
      ref={ref}
    >
      <p className={`${cl.text} ${cl[size]}`}> {text}</p>
    </div>
  );
});

export default Chip;
