import React, { useContext, useState } from "react";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import MyInputLabel from "../../components/UI/MyInputLabel/MyInputLabel";
import MyModal from "../../components/UI/MyModal/MyModal";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../App";
import { useFetching } from "../../hooks/useFetching";
import Phones from "../../assets/img/phones.png";
import isEmailValid from "../../utils/isEmailValid";
import Loader from "../../components/UI/Loader/Loader";
import MyInput from "../../components/UI/MyInput/MyInput";
import cl from "./Login.module.css";
import { PrimaryButton } from "../../UI";

const Login = () => {
  const { auth } = useContext(AuthContext);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const { t } = useTranslation();

  const [loginUser, isLoading, loginError, resetError] = useFetching(
    async () => {
      await auth.login(login, password);
    }
  );

  const handleLogin = () => {
    if (isEmailValid(login)) {
      setEmailError(false);
      loginUser();
    } else {
      setEmailError(true);
      return false;
    }
  };

  const handleErrorModalClose = () => {
    setPassword("");
    resetError();
  };

  return (
    <PageWrapper
      style={{
        overflow: "auto",
      }}
    >
      {isLoading && <Loader />}
      <MyModal
        visible={loginError}
        title={t("errors.incorrect_login_or_password")}
      >
        <PrimaryButton
          style={{ margin: "auto" }}
          variant={"magenta"}
          text={t("buttons.ok")}
          onClick={handleErrorModalClose}
        />
      </MyModal>
      <div className={cl.loginBlock}>
        <div className={cl.form}>
          <h1>{t("login2.title")}</h1>
          <div className={cl.formInner}>
            <div className={cl.inputCont}>
              <MyInputLabel text={t("email")} style={{ paddingLeft: 10 }} />
              <MyInput
                value={login}
                style={{ borderRadius: 28 }}
                color={emailError ? "logError" : null}
                onChange={(val) => setLogin(val.trim())}
                type="text"
              />
            </div>
            <div className={cl.inputCont}>
              <MyInputLabel
                text={t("login.password")}
                style={{ paddingLeft: 10 }}
                x
              />
              <MyInput
                value={password}
                style={{ borderRadius: 28 }}
                onChange={setPassword}
                type="password"
              />
            </div>
          </div>
          <div className={cl.btnContainer}>
            <PrimaryButton
              variant={"magenta"}
              text={t("login.login")}
              onClick={handleLogin}
            />
          </div>
        </div>
        <img src={Phones} className={cl.phones} alt="" />
      </div>
      <div className={cl.descriptionBlock}>
        <p>{t("login2.description")}</p>
        <p>{t("login2.description_2")}</p>
      </div>
    </PageWrapper>
  );
};

export default Login;
