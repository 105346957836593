import React from "react";
import { ReactComponent as ErrorIcon } from "../../../assets/img/error.svg";

const MyInputLabel = ({ text, isError, style }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
      <span
        style={{
          fontWeight: 400,
          marginBottom: 4,
          lineHeight: "17px",
          display: "flex",
          alignItems: "center",
          color: "#838383",
          textTransform: "capitalize",
          ...style,
        }}
      >
        {text}
      </span>
      {isError ? <ErrorIcon /> : null}
    </div>
  );
};

export default MyInputLabel;
