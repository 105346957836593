import React, { useEffect, useState } from "react";
import { motion, useAnimate, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";
import { DownloadButton, PageCont, Typography } from "../../components";
import PurpleGradient from "../../../../assets/webp/gradient-purple.webp";
import { ReactComponent as ThumbsUp } from "../../../../assets/svg/thumbs-up.svg";
import { ReactComponent as PhoneLandscape } from "../../../../assets/svg/phone-usage-landscape.svg";
import { ReactComponent as PhonePortrait } from "../../../../assets/svg/phone-usage-portrait.svg";
import cl from "./OfferView.module.css";

const OfferView = ({ setLock }) => {
  const { t } = useTranslation();

  const [highlighted, setHighlighted] = useState(false);

  const [bodyRef, animateBody] = useAnimate();

  const [buttonsRef, animateButtons] = useAnimate();
  const isInView = useInView(bodyRef);

  useEffect(() => {
    if (!isInView || highlighted) return;
    setLock(true);
    animateBody(
      bodyRef.current,
      { scale: 1, opacity: 1 },
      { duration: 1.5, delay: 0.5 }
    );
    animateButtons(
      buttonsRef.current,
      { y: 0, opacity: 1 },
      { duration: 1.5, delay: 2 }
    );

    setTimeout(() => {
      setHighlighted(true);
    }, 2000);

    setTimeout(() => {
      setLock(false);
    }, 2500);
  }, [isInView]);

  return (
    <PageCont color={"black"}>
      <motion.div
        layout
        ref={bodyRef}
        key="offerBody"
        initial={{ scale: 0.95, opacity: 0 }}
        className={cl.container}
      >
        <ThumbsUp className={cl.decoration} />
        <PhonePortrait className={cl.decoration} />
        <PhoneLandscape className={cl.decoration} />
        <div className={`${cl.textCont} ${highlighted ? cl.highlighted : ""}`}>
          <Typography variant={"h2"} color={"#ffffff"}>
            {t("new_landing.offer_title")}
          </Typography>
          <Typography
            variant={"h4"}
            color={"#ffffff"}
            style={{ fontFamily: "Noto Sans", maxWidth: "80%" }}
            dangerouslySetInnerHTML={{
              __html: t("new_landing.offer_subtitle"),
            }}
          />
          <motion.div
            layout
            className={cl.buttonCont}
            ref={buttonsRef}
            key="offerButtons"
            initial={{ y: 50, opacity: 0 }}
          >
            <div className={cl.buttons}>
              <DownloadButton
                variant={"light"}
                size={"medium"}
                type={"appstore"}
              />
              <DownloadButton
                variant={"light"}
                size={"medium"}
                type={"playmarket"}
              />
            </div>
            <Typography
              variant={"h5"}
              color={"#ffffff"}
              className={cl.buttonContText}
            >
              {t("new_landing.offer_btn_text")}
            </Typography>
          </motion.div>
        </div>
      </motion.div>
      <img src={PurpleGradient} className={cl.gradient} alt="" />
    </PageCont>
  );
};

export default OfferView;
