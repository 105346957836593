import React from "react";
import { observer } from "mobx-react-lite";
import situationsStore from "../../store/situationsStore";
import cl from "./CategoriesList.module.css";

const CategoriesList = () => {
  const { categories } = situationsStore;
  const isActive = (c) => c.id === situationsStore.currentCategory?.id;

  return (
    <div className={cl.list}>
      {categories.map((c) => (
        <div
          className={`${cl.category} ${isActive(c) ? cl.active : ""}`}
          key={`${c.id}_${c.title}`}
        >
          {c.title}
        </div>
      ))}
    </div>
  );
};

export default observer(CategoriesList);
