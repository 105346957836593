import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../hooks/useFetching";
import ExerciseService from "../../../../api/ExerciseService";
import exercisesStore from "../../store/exercisesStore";
import {
  LabeledInput,
  LabeledToggleButton,
  PrimaryButton,
} from "../../../../UI";
import {
  ClozeEditor,
  MultichoiceEditor,
  QuizEditor,
  SentenceOrderEditor,
  SubstitutionEditor,
  SummaryEditor,
  WordsOrderEditor,
} from "./components";
import {
  GrammarExerciseTypes,
  MediaExerciseTypes,
} from "../../data/constants";
import { LanguageLevels } from "../../../../data/common";
import cl from "./Editor.module.css";

const Editor = () => {
  const { t } = useTranslation();

  const { currentExercise } = exercisesStore;
  const { id, type, categoryId, difficulty, title, description } =
    currentExercise;

  const [exerciseData, setExerciseData] = useState({});

  const [getExerciseData] = useFetching(async () => {
    if (!id) return;
    const { data: exerciseResponse } = await ExerciseService.getExercise({
      id,
    });
    setExerciseData(exerciseResponse?.data);
  });

  const [updateExercise, updateLoading] = useFetching(async () => {
    const { data } = await ExerciseService.updateExercise({
      id,
      type,
      categoryId,
      difficulty,
      title,
      data: exerciseData,
      description,
    });
    exercisesStore.updateExercise(data);
  });

  const [deleteExercise, deleteLoading] = useFetching(async () => {
    const res = await ExerciseService.deleteExercise({ id });
    if (res) {
      exercisesStore.deleteExercise(id);
    }
  });

  const handleTitleChange = (title) => {
    exercisesStore.updateCurrentExercise({ title });
  };

  const handleDescChange = (description) => {
    exercisesStore.updateCurrentExercise({ description });
  };

  const handleDifficultyChange = (level) => {
    const difficulty = LanguageLevels.indexOf(level);
    exercisesStore.updateCurrentExercise({ difficulty });
  };

  const renderEditor = () => {
    switch (type) {
      case GrammarExerciseTypes.Multichoice:
        return (
          <MultichoiceEditor
            exerciseData={exerciseData}
            onChange={setExerciseData}
          />
        );
      case GrammarExerciseTypes.Cloze:
        return (
          <ClozeEditor exerciseData={exerciseData} onChange={setExerciseData} />
        );
      case GrammarExerciseTypes.Substitution:
        return (
          <SubstitutionEditor
            exerciseData={exerciseData}
            onChange={setExerciseData}
          />
        );
      case MediaExerciseTypes.Quiz:
        return (
          <QuizEditor exerciseData={exerciseData} onChange={setExerciseData} />
        );
      case MediaExerciseTypes.SentencesOrder:
        return (
          <SentenceOrderEditor
            exerciseData={exerciseData}
            onChange={setExerciseData}
          />
        );
      case GrammarExerciseTypes.WordsOrder:
        return (
          <WordsOrderEditor
            exerciseData={exerciseData}
            onChange={setExerciseData}
          />
        );
        case MediaExerciseTypes.Summary:
          return (
            <SummaryEditor
              exerciseData={exerciseData}
              onChange={setExerciseData}
            />
          );
      default:
        return <></>;
    }
  };

  const isSaveDisabled = () => {
    if (!title.length) return true;
    return false;
  };

  useEffect(() => {
    setExerciseData({});
    if (!currentExercise.id) return;

    getExerciseData();
    return () => {
      exercisesStore.setEditMode(false);
    };
  }, [currentExercise.id]);

  return (
    <div className={cl.editorCont}>
      <div className={cl.horizontalCont}>
        <LabeledInput
          label={t("exercises.exercise_title")}
          value={title}
          placeholder={t("exercises.title_placeholder")}
          onChange={handleTitleChange}
        />
        <LabeledToggleButton
          label={t("situations.level")}
          options={LanguageLevels}
          selectedOption={LanguageLevels[difficulty]}
          setOption={handleDifficultyChange}
          variant={"round"}
        />
      </div>
      <LabeledInput
        label={t("exercises.exercise_explanatory")}
        value={description}
        placeholder={t("exercises.explanatory_placeholder")}
        onChange={handleDescChange}
      />

      {renderEditor()}
      <div className={cl.horizontalCont}>
        <PrimaryButton
          text={t("buttons.save")}
          variant={"dark"}
          onClick={updateExercise}
          isLoading={updateLoading}
          disabled={isSaveDisabled()}
        />
        <PrimaryButton
          text={t("buttons.close")}
          variant={"light"}
          onClick={() => exercisesStore.setEditMode(false)}
        />
        <PrimaryButton
          text={t("buttons.delete")}
          variant={"red"}
          onClick={deleteExercise}
          isLoading={deleteLoading}
          style={{ marginLeft: "auto" }}
        />
      </div>
    </div>
  );
};

export default observer(Editor);
