import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import situationsStore from "../../store/situationsStore";
import { useFetching } from "../../../../hooks/useFetching";
import SituationsService from "../../../../api/SituationsService";
import { PrimaryButton, ToggleButton } from "../../../../UI";
import MyModal from "../../../../components/UI/MyModal/MyModal";
import {
  AddSituationModes,
  GENDERS,
  LEVELS,
  MODALITIES,
} from "../../data/constants";
import cl from "./AddInfoModal.module.css";

const AddInfoModal = ({ isEmpty, existingOptions }) => {
  const { t } = useTranslation();

  const { currentSituation, language } = situationsStore;

  const [response, setResponse] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const [mode, setMode] = useState(AddSituationModes.currentLevel);
  const [level, setLevel] = useState();

  const [maleModalities, setMaleModalities] = useState([]);
  const [femaleModalities, setFemaleModalities] = useState([]);

  const [availableLevels, setAvailableLevels] = useState([]);
  const [maleOptions, setMaleOptions] = useState([]);
  const [femaleOptions, setFemaleOptions] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  const isSendDisabled = () => {
    if (![...maleModalities, ...femaleModalities].length) return true;
    if (mode === AddSituationModes.otherLevel && !level) return true;
    return false;
  };

  const formatRequestByModalities = () => {
    return [
      ...maleModalities.map((modality) => ({
        id: currentSituation.id,
        language,
        gender: GENDERS[0],
        modality,
      })),
      ...femaleModalities.map((modality) => ({
        id: currentSituation.id,
        language,
        gender: GENDERS[1],
        modality,
      })),
    ];
  };

  const handleRegenerate = () => {
    situationsStore.setCurrentSituation(null);
  };

  const handleLevelSelect = (level) => {
    if (!availableLevels.includes(level)) return;
    setLevel(level);
  };

  const [generateSituation, generateLoading] = useFetching(async () => {
    const requests = formatRequestByModalities();
    for (const body of requests) {
      const res = await SituationsService.generateSituationBy(body);
      if (!res || (res.status !== 200 && res.status !== 201)) {
        setErrorMessage(
          res?.data?.message || "Unexpected error, please try again"
        );
        return;
      }
    }
    handleRegenerate();
  });

  const [generateOtherLevel, generateLevelLoading] = useFetching(async () => {
    const requests = formatRequestByModalities();
    for (const body of requests) {
      const res = await SituationsService.generateSituationBy({
        ...body,
        level,
      });
      if (!res || (res.status !== 200 && res.status !== 201)) {
        setErrorMessage(
          res?.data?.message || "Unexpected error, please try again"
        );
        return;
      }
    }
    handleRegenerate();
  });

  useEffect(() => {
    if (!expanded) {
      setErrorMessage("");
      setMaleModalities([]);
      setFemaleModalities([]);
      setResponse([]);
    }
  }, [expanded]);

  useEffect(() => {
    if (!response || !response.length) return;
    if (response.some((s) => s.status === 400)) {
    } else {
      setExpanded(false);
    }
  }, [response]);

  useEffect(() => {
    const maleExisting = Object.keys(existingOptions["Male"] || {});
    const femaleExisting = Object.keys(existingOptions["Female"] || {});
    setMaleOptions(MODALITIES.filter((m) => !maleExisting.includes(m)));
    setFemaleOptions(MODALITIES.filter((m) => !femaleExisting.includes(m)));
    setAvailableLevels(
      isEmpty
        ? LEVELS
        : LEVELS.filter((l) => !currentSituation.level.includes(l))
    );
  }, [existingOptions]);

  useEffect(() => {
    setErrorMessage("");
    setMaleModalities([]);
    setFemaleModalities([]);
  }, [mode]);

  useEffect(() => {
    setMode(
      isEmpty ? AddSituationModes.otherLevel : AddSituationModes.currentLevel
    );
  }, [isEmpty]);

  return (
    <>
      <MyModal visible={expanded} setVisible={setExpanded}>
        <div className={cl.modalInner}>
          <p className={cl.title}>{t("situations.generate")}</p>
          <ToggleButton
            options={Object.values(AddSituationModes)}
            availableOptions={
              isEmpty
                ? [AddSituationModes.otherLevel]
                : Object.values(AddSituationModes)
            }
            selectedOption={mode}
            setOption={setMode}
            withTranslations={"situations"}
            disabled={isEmpty}
          />
          {mode === AddSituationModes.otherLevel ? (
            <>
              <div className={cl.horizontalContainer}>
                {t("situations.level")}
                <ToggleButton
                  options={LEVELS}
                  availableOptions={availableLevels}
                  selectedOption={level}
                  setOption={handleLevelSelect}
                  variant={"round"}
                  style={{ marginRight: "auto" }}
                  disabled
                />
              </div>
              <div className={cl.horizontalContainer}>
                {t("situations.male")}
                <ToggleButton
                  options={MODALITIES}
                  selectedOption={maleModalities}
                  setOption={setMaleModalities}
                  withTranslations={"situations"}
                  multiple
                  style={{ marginRight: "auto" }}
                />
              </div>
              <div className={cl.horizontalContainer}>
                {t("situations.female")}
                <ToggleButton
                  options={MODALITIES}
                  selectedOption={femaleModalities}
                  setOption={setFemaleModalities}
                  withTranslations={"situations"}
                  multiple
                  style={{ marginRight: "auto" }}
                />
              </div>
              <p className={errorMessage ? cl.error : cl.description}>
                {errorMessage || t("situations.add_new_level_desc")}
              </p>
              <PrimaryButton
                variant={"dark"}
                text={`${t("buttons.add")}  (${
                  [...maleModalities, ...femaleModalities].length
                })`}
                onClick={generateOtherLevel}
                isLoading={generateLevelLoading}
                isDisabled={isSendDisabled()}
              />
            </>
          ) : (
            <>
              <p className={cl.description}>
                {t("situations.add_several_desc")}
              </p>
              {maleOptions.length !== 0 && (
                <div className={cl.horizontalContainer}>
                  {t("situations.male")}
                  <ToggleButton
                    options={maleOptions}
                    selectedOption={maleModalities}
                    setOption={setMaleModalities}
                    withTranslations={"situations"}
                    multiple
                    style={{ marginRight: "auto" }}
                  />
                </div>
              )}
              {femaleOptions.length !== 0 && (
                <div className={cl.horizontalContainer}>
                  {t("situations.female")}
                  <ToggleButton
                    options={femaleOptions}
                    selectedOption={femaleModalities}
                    setOption={setFemaleModalities}
                    withTranslations={"situations"}
                    multiple
                    style={{ marginRight: "auto" }}
                  />
                </div>
              )}
              {
                <p className={cl.description}>
                  {errorMessage ||
                    `${t("situations.level_desc")}: ${
                      currentSituation.level[0]
                    }`}
                </p>
              }
              <PrimaryButton
                variant={"dark"}
                text={`${t("buttons.add")}  (${
                  [...maleModalities, ...femaleModalities].length
                })`}
                onClick={generateSituation}
                isLoading={generateLoading}
                isDisabled={isSendDisabled()}
              />
            </>
          )}
        </div>
      </MyModal>
      <PrimaryButton
        variant={"dark"}
        text={t(isEmpty ? "situations.generate" : "situations.add_other")}
        onClick={() => setExpanded(true)}
      />
    </>
  );
};

export default observer(AddInfoModal);
