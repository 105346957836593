import React from "react";
import { observer } from "mobx-react-lite";
import dictionaryStore from "./store/dictionaryStore";
import { useTranslation } from "react-i18next";
import NavBar from "../../components/NavBar/NavBar";
import PageWrapper from "../../components/UI/PageWrapper/PageWrapper";
import { AddWords, LoadFile, QueueList, Words } from "./components";
import { DictionaryViews } from "./data/constants";

const DictionaryAdministration = () => {
  const { t } = useTranslation();

  const VIEWS = [
    {
      title: t("dictionary_administration.edit_words"),
      value: DictionaryViews.words,
    },
    {
      title: t("dictionary_administration.words_queue"),
      value: DictionaryViews.queue,
    },
    {
      title: t("dictionary_administration.add_words"),
      value: DictionaryViews.add,
    },
    {
      title: t("dictionary_administration.load_file"),
      value: DictionaryViews.file,
    },
  ];

  const { view } = dictionaryStore;

  const handleViewSelect = (newView) => {
    dictionaryStore.setView(newView);
  };

  const renderView = () => {
    switch (view) {
      case DictionaryViews.add:
        return <AddWords />;
      case DictionaryViews.words:
        return <Words />;
      case DictionaryViews.file:
        return <LoadFile />;
      case DictionaryViews.queue:
        return <QueueList />;
      default:
        return null;
    }
  };

  return (
    <PageWrapper>
      <NavBar items={VIEWS} active={view} onSelect={handleViewSelect} />
      {renderView()}
    </PageWrapper>
  );
};

export default observer(DictionaryAdministration);
