import { makeAutoObservable } from "mobx";

const TICKS_IN_SECOND = 10000000;

class ExercisesPlayer {
  trackSentences = [];
  externalId = "";

  trackLength = 0;
  currentTime = 0;
  mediaRange = [0, 0];
  currentRange = [];

  playerRef = null;
  prevRef = null;

  isPlaying = false;
  isLoaded = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true, deep: true });
  }

  setTrackData(data) {
    this.trackSentences = data.sentences;
    this.externalId = data.externalId;
    this.trackLength = data.duration * TICKS_IN_SECOND;
    this.setMediaRange([0, data.duration * TICKS_IN_SECOND]);
  }

  setPlayerRef(player) {
    if (this.playerRef) {
      this.prevRef = this.playerRef;
    }
    this.playerRef = player;
  }

  setTrackLength(length) {
    this.trackLength = length;
  }

  setCurrentTime(time) {
    this.currentTime = time;
  }

  setIsPlaying(flag) {
    this.isPlaying = flag;
  }

  setIsLoaded(flag) {
    this.isLoaded = flag;
  }

  handlePlayPauseClick() {
    if (this.isPlaying) {
      this.playerRef.pause();
      this.isPlaying = false;
    } else {
      this.playerRef.play();
      this.isPlaying = true;
    }
  }

  handleVideoPlay(time) {
    if (isNaN(time)) return;
    if (this.playerRef === null) return;
    this.setCurrentTime(time * TICKS_IN_SECOND);
  }

  handleChangeMediaTime(time) {
    if (isNaN(time)) return;
    if (this.playerRef === null) return;
    const trackTime = time / TICKS_IN_SECOND;
    this.playerRef.currentTime = trackTime;
    if (this.playerRef.seek) {
      this.playerRef.seek(trackTime);
      if (!this.isPlaying) {
        this.playerRef.play();
        this.playerRef.pause();
      }
    }
    this.setCurrentTime(time);
  }

  resetVideoParams(isPreview) {
    if (!isPreview) {
      this.setIsLoaded(false);
    }
    this.setIsPlaying(false);
    this.setPlayerRef(this.prevRef);
    this.setCurrentTime(this.currentRange[0] ?? 0);
  }

  setCurrentRange(range) {
    this.currentRange = range;
  }

  setMediaRange(range) {
    this.mediaRange = range;
  }

  resetRange() {
    this.setMediaRange([0, this.trackLength]);
    this.setCurrentRange([0, this.trackLength]);
  }

  resetMediaParams() {
    this.trackSentences = [];
    this.setMediaRange([0, 0]);
    this.setCurrentRange([0, 0]);
  }
}

const exercisesPlayer = new ExercisesPlayer();
export default exercisesPlayer;
