export const getAvailableOptions = (data) => {
  const result = {};

  data.forEach((item) => {
    const { gender, modality } = item;

    if (!result[gender]) {
      result[gender] = {};
    }

    if (!result[gender][modality]) {
      result[gender][modality] = [];
    }
  });

  return result;
};
