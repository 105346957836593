import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../hooks/useFetching";
import SituationsService from "../../../../api/SituationsService";
import { PrimaryButton, PrimaryInput ,ToggleButton} from "../../../../UI";
import FileUploader from "../../../../components/UI/FileUploader/FileUploader";
import MyModal from "../../../../components/UI/MyModal/MyModal";
import { GENDERS, LEVELS, MODALITIES } from "../../data/constants";
import { ICONS } from "../../data/icons";
import cl from "./AddSituation.module.css";

const AddSituation = ({ categoryId, language }) => {
  const { t } = useTranslation();

  const [response, setResponse] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [addFile, setAddFile] = useState(false);

  const [level, setLevel] = useState(LEVELS[0]);
  const [maleModalities, setMaleModalities] = useState([]);
  const [femaleModalities, setFemaleModalities] = useState([]);
  const [requestText, setRequestText] = useState("");
  const [file, setFile] = useState(null);

  const isSendDisabled = () => {
    if (!requestText) return true;
    if (![...maleModalities, ...femaleModalities].length) return true;
    if (!file) return true;
    return false;
  };

  const formatSituationsByModalities = () => {
    return [
      ...maleModalities.map((modality) => ({
        title: "",
        categoryId,
        requestText: requestText.toLowerCase(),
        language,
        gender: GENDERS[0],
        modality,
        level,
      })),
      ...femaleModalities.map((modality) => ({
        title: "",
        categoryId,
        requestText: requestText.toLowerCase(),
        language,
        gender: GENDERS[1],
        modality,
        level,
      })),
    ];
  };

  const [addSituation, isSituationLoading, error, resetError] = useFetching(
    async () => {
      resetError();
      const situations = formatSituationsByModalities();
      const { data } = await SituationsService.addSituationsBatch({
        situations,
        file,
      });
      if (data && data.length) {
        setResponse(data);
      }
    }
  );

  const handleAddImage = () => {
    setAddFile(true);
  };

  const renderInfoIcons = () => {
    return [
      ...maleModalities.map((m, i) => {
        const modality = m.toLowerCase();
        const key = `male_icon_${i}`;
        return (
          <div className={cl.infoObj} key={key}>
            {"M"}
            <div className={`${cl.icon} ${cl[modality]}`}>
              {ICONS[modality]}
            </div>
            {level}
          </div>
        );
      }),
      ...femaleModalities.map((m, i) => {
        const modality = m.toLowerCase();
        const key = `female_icon_${i}`;
        return (
          <div className={cl.infoObj} key={key}>
            {"F"}
            <div className={`${cl.icon} ${cl[modality]}`}>
              {ICONS[modality]}
            </div>
            {level}
          </div>
        );
      }),
    ];
  };

  useEffect(() => {
    setLevel(LEVELS[0]);
    setMaleModalities([]);
    setFemaleModalities([]);
    setRequestText("");
    setFile(null);
    setResponse([]);
  }, [expanded]);

  useEffect(() => {
    resetError();
  }, [level, requestText]);

  useEffect(() => {
    if (!response || !response.length) return;
    if (response.some((s) => s.status === 400)) {
    } else {
      setExpanded(false);
    }
  }, [response]);

  return (
    <>
      <MyModal visible={expanded} setVisible={setExpanded}>
        <div className={cl.modalInner}>
          <p className={cl.title}>{t("situations.situation_add_btn")}</p>
          <PrimaryInput
            value={requestText}
            onChange={setRequestText}
            color={error ? "red" : "light"}
            placeholder={t("situations.request")}
          />
          <div className={cl.horizontalContainer}>
            <p>{`${t("situations.language")}: ${language} `}</p>
            <ToggleButton
              variant={"round"}
              options={LEVELS}
              selectedOption={level}
              setOption={setLevel}
              style={{ marginRight: "auto" }}
            />
          </div>
          <div className={cl.horizontalContainer}>
            {t("situations.male")}
            <ToggleButton
              options={MODALITIES}
              selectedOption={maleModalities}
              setOption={setMaleModalities}
              withTranslations={"situations"}
              multiple
              style={{ marginRight: "auto" }}
            />
          </div>
          <div className={cl.horizontalContainer}>
            {t("situations.female")}
            <ToggleButton
              options={MODALITIES}
              selectedOption={femaleModalities}
              setOption={setFemaleModalities}
              withTranslations={"situations"}
              multiple
              style={{ marginRight: "auto" }}
            />
          </div>
          <div
            className={`${cl.imageCont} ${file ? "" : cl.placeholder}`}
            onClick={handleAddImage}
          >
            {error && (
              <p className={cl.error}>{t("situations.conflict_error")}</p>
            )}
            {file ? (
              <>
                {requestText && <p className={cl.entityTitle}>{requestText}</p>}
                <img
                  src={URL.createObjectURL(file)}
                  alt={"situation preview"}
                />
                <div className={cl.infoCont}>{renderInfoIcons()}</div>
              </>
            ) : (
              <p>{t("courses.add_cover")}</p>
            )}
          </div>
          <PrimaryButton
            variant={"dark"}
            text={t("buttons.add")}
            onClick={addSituation}
            isLoading={isSituationLoading}
            isDisabled={isSendDisabled()}
          />
          <FileUploader
            mode="image"
            visible={addFile}
            setVisible={setAddFile}
            key="categoryImagePicker"
            onSave={setFile}
          />
        </div>
      </MyModal>
      <PrimaryButton
        variant={"dark"}
        text={t("situations.situation_add_btn")}
        onClick={() => setExpanded(true)}
      />
    </>
  );
};

export default AddSituation;
