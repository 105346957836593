import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonTagName, ServiceStatuses } from "../../data/constants";
import cl from "./ServiceState.module.css";
import DemoPageLoader from "../../../../components/UI/DemoPageLoader/DemoPageLoader";
import { PrimaryButton } from "../../../../UI";

const ServiceState = ({ service }) => {
  const { t } = useTranslation();

  const ref = useRef();
  const [expanded, setExpanded] = useState(false);

  const { serviceName, services, status } = service;

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.tagName !== ButtonTagName
      ) {
        setExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    if (!services) setExpanded(false);
  }, [services]);

  return (
    <div className={cl.serviceCont} ref={ref}>
      <div
        className={`${cl.serviceState} ${
          status === ServiceStatuses.errored ? cl.errored : cl.healthy
        }`}
      >
        <p className={`${cl.serviceName} ${status ? "" : cl.loading}`}>
          {serviceName}
        </p>
        {status ? (
          <>
            <p className={cl.status}>{`(${status})`}</p>
            <PrimaryButton
              text={t(expanded ? "buttons.close" : "buttons.expand")}
              onClick={handleExpand}
            />
          </>
        ) : (
          <div className={cl.loaderCont}>
            <DemoPageLoader size={20} color={"#cbcbcb"} />
          </div>
        )}
      </div>
      {expanded && services && (
        <div className={cl.list}>
          {services.map((childService) => (
            <div
              className={`${cl.childService} ${
                childService.status === ServiceStatuses.errored
                  ? cl.errored
                  : cl.healthy
              }`}
              key={`${serviceName}_${childService.serviceName}`}
            >
              <p className={cl.serviceName}>{childService.serviceName}</p>
              <p className={cl.status}>{`(${
                childService.error ?? childService.status
              })`}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ServiceState;
