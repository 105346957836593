import React from "react";
import cl from "./MyButton.module.css";

const MyButton = React.forwardRef(
  ({ color, children, stopPropagation, isDisabled, ...props }, ref) => {
    let propsOnClick = props.onClick;
    let onClick = function (e) {
      if (stopPropagation) e.stopPropagation();
      if (propsOnClick) {
        propsOnClick(e);
      }
    };
    delete props.onClick;
    let classNames = [cl.myBtn];
    if (props.className) {
      classNames.push(props.className);
      delete props.className;
    }

    if (color) {
      color.split(" ").forEach((el) => {
        classNames.push(cl[el]);
      });
    }
    classNames = classNames.join(" ");

    if (props.isLabel) {
      delete props.isLabel;
      return (
        <label {...props} className={classNames}>
          {children}
        </label>
      );
    } else {
      return (
        <button
          ref={ref}
          {...props}
          onClick={onClick}
          disabled={isDisabled}
          className={classNames}
        >
          {children}
        </button>
      );
    }
  }
);

export default MyButton;
