const DEFAULT_SEARCH_OPTION = { value: "all", name: "All" };
const NEWS_CATEGORY_ID = "93103118-9771-4c7b-ba46-bc393afa107a";
const PODCASTS_PER_PAGE = 50;
const RATINGS = Array(10)
  .fill("")
  .map((_, i) => i + 1);

const YT_URL = "https://www.youtube.com";

export {
  DEFAULT_SEARCH_OPTION,
  NEWS_CATEGORY_ID,
  PODCASTS_PER_PAGE,
  RATINGS,
  YT_URL,
};
