import React from "react";
import { useTranslation } from "react-i18next";
import cl from "./LoaderLine.module.css";

const LoaderLine = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={cl.loaderLine}></div>
      <p className={cl.loadingText}>{t("add_track_input.loading")}</p>
    </>
  );
};

export default LoaderLine;
