import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import MyAdjustableTextarea from "../../../../../../../components/UI/MyAdjustableTextarea/MyAdjustableTextarea";
import cl from "./SentenceCard.module.css";

const SentenceCard = ({ id, idx, text, onDrag, onChange, onDelete }) => {
  const { t } = useTranslation();

  const dragRef = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "sentence",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!dragRef.current) {
        return;
      }
      const dragIndex = item.idx;
      const hoverIndex = idx;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      onDrag(dragIndex, hoverIndex);
      item.idx = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "sentence",
    item: () => {
      return { id, idx };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(dragRef));

  return (
    <div
      className={cl.sentenceCont}
      ref={dragRef}
      data-handler-id={handlerId}
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      <MyAdjustableTextarea
        placeholder={t("exercises.add_sentence")}
        value={text}
        onChange={(text) => onChange(idx, text)}
        onDelete={() => onDelete(idx)}
        isFocus={!text.length}
        mode={"sentence"}
        style={{ minHeight: 37, height: "auto" }}
      />
    </div>
  );
};

export default SentenceCard;
