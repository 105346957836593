import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useAnimate, useInView } from "framer-motion";
import { PageCont, Slider } from "../../components";
import { slides } from "../../data";

const SliderView = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const [sliderRef, animateSlider] = useAnimate();
  const isInView = useInView(sliderRef);

  useEffect(() => {
    if (isInView) {
      animateSlider(
        sliderRef.current,
        { x: 0, opacity: 1 },
        { duration: 1, delay: 0.5 }
      );
    }
  }, [isInView]);

  return (
    <PageCont color={"white"}>
      <Slider
        ref={sliderRef}
        slides={slides}
        keyName={"largeSlider"}
        initial={{ x: isMobile ? 0 : "100%", opacity: 0 }}
        inView={isInView}
        isCompact={true}
      />
    </PageCont>
  );
};

export default SliderView;
