import React from "react";
import cl from "./MyAddButton.module.css";

const MyAddButton = ({ text, isDisabled, onClick }) => {
  return <p
  onClick={onClick}
  className={`${cl.myAddButton} ${isDisabled ? cl.disabled : ""}`}
>
  {text}
</p>
};

export default MyAddButton;
