import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PodcastService from "../../../../api/PodcastService";
import { PrimaryButton, LabeledInput, LabeledSelect } from "../../../../UI";
import { PodcastCard } from "..";
import MyModal from "../../../../components/UI/MyModal/MyModal";
import LANGUAGE_OPTIONS from "../../../DictionaryAdministration/helpers/languageOptions";
import cl from "./AddPodcast.module.css";

const AddPodcast = () => {
  const { t } = useTranslation();

  const [addModal, setAddModal] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [lang, setLang] = useState(null);
  const [isAdded, setIsAdded] = useState(false);
  const [podcastObj, setPodcastObj] = useState(null);
  const [notification, setNotification] = useState("");

  const handleAddPodcast = async () => {
    try {
      const match = inputVal.match(/\?v=([^&]+)/);
      const videoId = match ? match[1] : inputVal;
      const { data, message } = await PodcastService.pullPodcast({
        videoId,
        lang,
      });
      setIsAdded(true);
      if (data) {
        setPodcastObj(data);
        setNotification(t("podcasts.added_successfully"));
      } else {
        setNotification(`${t("podcasts.error")} (${message})`);
      }
    } catch (e) {
      setNotification(t("podcasts.error"));
    }
  };

  const handleCloseButton = () => {
    if (isAdded) {
      setAddModal(false);
    } else {
      handleAddPodcast();
    }
  };

  useEffect(() => {
    setLang(null);
    setInputVal("");
    setNotification("");
    setIsAdded(false);
    setPodcastObj(null);
  }, [addModal]);

  return (
    <>
      <PrimaryButton
        text={t("podcasts.add_btn")}
        variant={"dark"}
        onClick={() => {
          setAddModal(!addModal);
        }}
      />
      {addModal && (
        <MyModal visible={addModal} setVisible={setAddModal}>
          <div className={cl.modalCont}>
            {!isAdded && (
              <div className={cl.inputsCont}>
                <LabeledInput
                  label={t("podcasts.link")}
                  placeholder={"https://www.youtube.com/watch?v=example_id"}
                  color={"light"}
                  value={inputVal}
                  onChange={(val) => {
                    setInputVal(val);
                  }}
                  style={{ minWidth: 400 }}
                />
                <LabeledSelect
                  label={t("podcasts.language")}
                  value={lang}
                  options={LANGUAGE_OPTIONS}
                  onChange={(selected) => {
                    setLang(selected);
                  }}
                  style={{ minWidth: 200 }}
                  height={150}
                />
              </div>
            )}
            <p className={cl.alert}>{notification}</p>
            {isAdded && podcastObj && (
              <PodcastCard podcastObj={podcastObj} mode="preview" />
            )}
            <PrimaryButton
              variant="dark"
              onClick={handleCloseButton}
              isDisabled={!inputVal || !lang}
              text={isAdded ? t("buttons.close") : t("podcasts.add_btn")}
            />
          </div>
        </MyModal>
      )}
    </>
  );
};

export default AddPodcast;
