import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import exercisesStore from "../../store/exercisesStore";
import { useFetching } from "../../../../hooks/useFetching";
import TrackService from "../../../../api/TrackService";
import MyModal from "../../../../components/UI/MyModal/MyModal";
import { PrimaryButton, PrimaryInput, ToggleButton } from "../../../../UI";
import { MediaTypes } from "../../data/constants";
import { LanguageLevels } from "../../../../data/common";
import { EditMedia } from "..";
import cl from "./SelectMedia.module.css";
import exercisesPlayer from "../../store/exercisesPlayer";

const limit = 99;

const SelectMedia = ({ mediaType }) => {
  const { t } = useTranslation();

  const { lang } = exercisesStore;

  const scrollRef = useRef(0);

  const [page, setPage] = useState(0);
  const [tracks, setTracks] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [difficulty, setDifficulty] = useState();
  const [visible, setVisible] = useState(false);
  const [paginationEnd, setPaginationEnd] = useState(false);

  const [getTracks, isLoading] = useFetching(async () => {
    const params = {
      offset: page * limit,
      limit,
      lang,
      difficulty: difficulty ? LanguageLevels.indexOf(difficulty) : null,
      searchString,
      recognized: true,
    };
    const { data } = await TrackService.getTracks(params);
    handleTracksResponse(data);
  });

  const handleTracksResponse = (data) => {
    const { items } = data;
    if (!items.length) setPaginationEnd(true);
    const newTracks = (page > 0 ? [...tracks, ...items] : items).filter(
      (t, index, self) => !self.slice(0, index).some((item) => item.id === t.id)
    );
    setTracks(newTracks);
  };

  const handleTrackListScroll = (e) => {
    if (paginationEnd) return;
    const list = e.target;
    if (!isLoading && list.scrollTop > scrollRef.current) {
      if (list.scrollTop > list.scrollHeight - list.clientHeight - 100) {
        setPage(page + 1);
      }
    }
    scrollRef.current = list.scrollTop;
  };

  const handleTrackClick = (id) => {
    exercisesStore.setCurrentTrack(id);
    setVisible(false);
  };

  const renderResetRange = () => {
    if (!exercisesPlayer.currentRange) return false;
    const [start, end] = exercisesPlayer.currentRange;
    if (end === 0) return false;

    if (start > 0 || end < exercisesPlayer.trackLength) return true;

    return false;
  };

  useEffect(() => {
    getTracks();
  }, [page]);

  useEffect(() => {
    setPage(0);
    getTracks();
  }, [difficulty, lang, searchString]);

  if (mediaType === MediaTypes.GeneratedText) return <></>;

  return (
    <>
      <MyModal
        visible={visible}
        setVisible={setVisible}
        style={{ width: "80vw", padding: 0, overflow: "hidden" }}
      >
        <div className={cl.modalInner}>
          <div className={cl.searchBar}>
            <PrimaryInput
              placeholder={t("library.search")}
              value={searchString}
              onChange={setSearchString}
            />
            <ToggleButton
              selectedOption={difficulty}
              setOption={setDifficulty}
              options={LanguageLevels}
              nullable
            />
          </div>
          <div className={cl.list} onScroll={handleTrackListScroll}>
            {tracks.map((t) => (
              <div
                className={cl.trackCard}
                key={t.id}
                onClick={() => handleTrackClick(t.id)}
              >
                <div className={cl.imageContainer}>
                  <img src={t.image?.md} alt={t.title} />
                </div>
                <p>{t.title}</p>
              </div>
            ))}
          </div>
        </div>
      </MyModal>
      <div className={cl.buttons}>
        <PrimaryButton
          text={t(
            exercisesStore.currentTrack
              ? "exercises.media_change"
              : "exercises.media_type"
          )}
          style={{
            marginRight: exercisesStore.currentTrack ? "auto" : "unset",
          }}
          variant={exercisesStore.currentTrack ? "light" : "dark"}
          onClick={() => setVisible(true)}
        />
        {renderResetRange() && (
          <PrimaryButton
            text={t("exercises.reset_range")}
            variant={"red"}
            onClick={() => {
              exercisesPlayer.resetRange();
            }}
          />
        )}
        {exercisesStore.currentTrack && (
          <PrimaryButton
            text={t("exercises.reset_media")}
            variant={"red"}
            onClick={() => {
              exercisesStore.setCurrentTrack();
            }}
          />
        )}
      </div>
      {exercisesStore.currentTrack && <EditMedia mediaType={mediaType} />}
    </>
  );
};

export default observer(SelectMedia);
