import React from "react";
import { LanguageLevels } from "../../../../data/common";
import cl from "./UserItem.module.css";

function UserItem({ user }) {
  const { email, name, lang, nativeLang, level } = user;
  return (
    <div className={cl.userItem}>
      <div className={cl.userCell}>{email}</div>
      <div className={cl.userCell}>{name || "-"}</div>
      <div className={cl.userCell}>{lang || "-"}</div>
      <div className={cl.userCell}>{nativeLang || "-"}</div>
      <div className={cl.userCell}>
        {typeof level === "number" ? LanguageLevels[level] : "-"}
      </div>
    </div>
  );
}

export default UserItem;
