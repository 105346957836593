import React from "react";
import cl from "./MyCloseButton.module.css";

const MyCloseButton = ({ onClick, style, variant }) => {
  return (
    <div
      style={style}
      className={`${cl.MyCloseButton} ${cl[variant] || ""}`}
      onMouseDown={onClick}
    ></div>
  );
};

export default MyCloseButton;
