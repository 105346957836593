import React from "react";
import cl from "./Checkbox.module.css";

const Checkbox = ({ label, value, onChange, style }) => {
  const handleOnChange = () => {
    if (onChange) onChange(!value);
  };

  return (
    <label className={cl.label} style={style}>
      <input
        type={"checkbox"}
        checked={value ? value : false}
        className={cl.checkbox}
        onChange={handleOnChange}
      />
      <span>{label}</span>
    </label>
  );
};

export default Checkbox;
