import { dictionaryLangs } from "../../../data/dictionaryLangs";
import { interfaceLangs } from "../../../data/interfaceLangs";

const LANGUAGE_OPTIONS = dictionaryLangs
  .map((code) => {
    const interfaceLang = interfaceLangs.find((lang) => lang.value === code);
    if (interfaceLang) {
      return { name: interfaceLang.name, value: code };
    } else {
      return null;
    }
  })
  .filter((l) => l);

export default LANGUAGE_OPTIONS;
