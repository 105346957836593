import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import exercisesStore from "../../store/exercisesStore";
import ExerciseService from "../../../../api/ExerciseService";
import { useFetching } from "../../../../hooks/useFetching";
import { Chip, Editor, Preview, SearchBar } from "../../components";
import List from "../../../../components/UI/List/List";
import cl from "./ListView.module.css";
import { ExercisesPaginationCount } from "../../data/constants";
import { LanguageLevels } from "../../../../data/common";
import { PrimaryButton } from "../../../../UI";

const ARROW_KEYS = {
  arrowDown: "ArrowDown",
  arrowUp: "ArrowUp",
};

const ListView = () => {
  const { t } = useTranslation();

  const lastScroll = useRef(0);
  const activeChipRef = useRef();

  const {
    page,
    exercises,
    currentExercise,
    searchString,
    paginationEnd,
    disableAutoScroll,
    lang,
    type,
    difficulty,
    editMode,
  } = exercisesStore;

  const [getExercises, isLoading] = useFetching(async () => {
    const { data } = await ExerciseService.getExercises({
      limit: ExercisesPaginationCount,
      offset: page * ExercisesPaginationCount,
      searchString,
      lang,
      type,
      difficulty: difficulty ? LanguageLevels.indexOf(difficulty) : null,
    });
    exercisesStore.setExercisesData(data);
  });

  const handleListScroll = (e) => {
    if (paginationEnd) return;
    const list = e.target;
    if (!isLoading && list.scrollTop > lastScroll.current) {
      if (list.scrollTop > list.scrollHeight - list.clientHeight - 100) {
        exercisesStore.setNextPage();
      }
    }
    lastScroll.current = list.scrollLeft;
  };

  const scrollChipIntoView = () => {
    if (disableAutoScroll || !activeChipRef.current) return;
    activeChipRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleExerciseChipClick = (exerciseObj, index) => {
    if (currentExercise && currentExercise.id === exerciseObj.id) return;
    exercisesStore.setCurrentExercise({ ...exerciseObj, index });
  };

  useEffect(() => {
    getExercises();
  }, []);

  useEffect(() => {
    getExercises();
  }, [page]);

  useEffect(() => {
    exercisesStore.resetSearch();
    getExercises();
  }, [searchString]);

  useEffect(() => {
    exercisesStore.resetSearch();
    getExercises();
  }, [searchString, lang, type, difficulty]);

  useEffect(() => {
    scrollChipIntoView();
  }, [disableAutoScroll]);

  useEffect(() => {
    scrollChipIntoView();

    const handleKeyPress = (e) => {
      if (!currentExercise || !currentExercise.id || editMode) return;
      if (e.key === ARROW_KEYS.arrowDown) {
        e.preventDefault();
        const next = currentExercise.index + 1;
        if (!exercises[next]) return;
        exercisesStore.setCurrentExercise({ ...exercises[next], index: next });
      }
      if (e.key === ARROW_KEYS.arrowUp) {
        e.preventDefault();
        const prev = currentExercise.index - 1;
        if (prev < 0) return;
        exercisesStore.setCurrentExercise({ ...exercises[prev], index: prev });
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [currentExercise]);

  return (
    <>
      <SearchBar />
      {exercises.length !== 0 || isLoading ? (
        <div className={cl.exercisesContainer}>
          <List
            onScroll={handleListScroll}
            isLoading={isLoading}
            style={{
              padding: 0,
              gap: 6,
              overflow: "hidden visible",
              opacity: editMode ? 0.5 : 1,
              pointerEvents: editMode ? "none" : "all",
            }}
          >
            {exercises.map((e, i) => (
              <Chip
                ref={activeChipRef}
                key={e.id}
                exerciseObj={{ ...e, i }}
                isActive={e.id === currentExercise.id}
                onClick={() => handleExerciseChipClick(e, i)}
              />
            ))}
          </List>
          {currentExercise.id && (
            <div className={cl.exerciseCard}>
              {editMode ? <Editor /> : <Preview />}
            </div>
          )}
        </div>
      ) : (
        <div className={cl.emptyAlertCont}>
          <p className={cl.emptyAlert}>{t("exercises.empty_list")}</p>
          <PrimaryButton
            variant={"red"}
            text={t("exercises.reset_search")}
            onClick={exercisesStore.resetFilter}
          />
        </div>
      )}
    </>
  );
};

export default observer(ListView);
